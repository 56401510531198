import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetOrderDetails } from "hooks/orders";
import { useGetMeasurements } from "hooks/measurements";
import { SVG_TYPE, ROUTE_ENUM, ORDER_ENUM } from "enums";
import { Button, SVG } from "components";
import { useNavigate } from "react-router";
import {
  CloseMeasurement,
  ActiveOrderDetails,
  Measurements,
  Verifications,
  AppointmentCannotHappen,
  ReceiptProtocolGenerator,
  InvoiceAndPaymentMethods,
} from "features/orders";
import { UsedAssemblyMaterials } from "features/usedAssemblyMaterials";
import { Photos } from "features/Photos";
import "./active-order.scss";
import { ActivityTime } from "features/activity";

const ActiveOrder = () => {
  const navigate = useNavigate();
  const [isCloseMeasurementOpen, setIsCloseMeasurementOpen] = useState(false);
  const [errorEndAppointment, setErrorEndAppointment] = useState({
    isError: false,
    errorMessage: "",
  });
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid") || "";
  const page = searchParams.get("page");
  const orderDetailsUrl = `?page=${page}&orderUuid=${orderUuid}`;
  const { data: orderDetails } = useGetOrderDetails(orderUuid, true);
  const { data: measurements, isLoading } = useGetMeasurements(orderUuid);
  const verifications = orderDetails?.status === ORDER_ENUM.FIXED_VERIFICATION;

  const fixedInstallation =
    orderDetails?.status === ORDER_ENUM.FIXED_INSTALLATION;
  const fixedMeasurement =
    orderDetails?.status === ORDER_ENUM.FIXED_MEASUREMENTS;
  const handleGoToOrdersList = () => {
    navigate(`${ROUTE_ENUM.ORDERS_LIST}${orderDetailsUrl}`);
  };

  const handleCloseOrder = () => {
    const isAnyMeasurementIncomplete =
      measurements &&
      measurements.length > 0 &&
      measurements.some((item) => !item.isFinished);
    const isAnyMeasurementAvailable = measurements && measurements.length > 0;
    if (!isAnyMeasurementAvailable && !isAnyMeasurementIncomplete) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Aby zakończyć zlecenie musisz dodać przynajmniej jeden pomiar.",
      });
    } else if (isAnyMeasurementAvailable && isAnyMeasurementIncomplete) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Masz niedokończone pomiary. Uzupełnij brakujące dane, aby móc zakończyć zlecenie.",
      });
    } else if (isAnyMeasurementAvailable && !isAnyMeasurementIncomplete) {
      setErrorEndAppointment({ isError: false, errorMessage: "" });
      setIsCloseMeasurementOpen(true);
    }
  };

  const handleCloseInstallation = () => {
    console.log("zobacz");
  };

  return (
    <>
      <CloseMeasurement
        data={{ label: orderDetails?.name, uuid: orderUuid }}
        isOpen={isCloseMeasurementOpen}
        onClose={() => setIsCloseMeasurementOpen(false)}
      />
      <div className="dashboard-tab-name dashboard-tab-name-with-back">
        <div
          className="dashboard-tab-name-with-back__icon"
          onClick={() => handleGoToOrdersList()}
        >
          <SVG type={SVG_TYPE.ARROW_BACK} />
        </div>
        {orderDetails?.name}
      </div>
      <div className="active-order__content">
        <div className="active-order__box active-order__box--left">
          <ActivityTime />
          {fixedInstallation && (
            <>
              <UsedAssemblyMaterials orderDetails={orderDetails} />
              <InvoiceAndPaymentMethods orderDetails={orderDetails} />
              <Photos label="Zdjęcia z montażu" />
              <AppointmentCannotHappen
                uuid={orderUuid}
                type="installation"
                handleCloseOrder={handleCloseInstallation}
              />
            </>
          )}
          {fixedMeasurement && (
            <>
              <Measurements
                isLoading={isLoading}
                errorEndMeasurement={errorEndAppointment}
                measurements={measurements}
              />
              <Photos label="Zdjęcia" />
              <AppointmentCannotHappen
                uuid={orderUuid}
                type="measurement"
                handleCloseOrder={handleCloseOrder}
              />
            </>
          )}
          {verifications && (
            <Verifications
              uuid={orderUuid}
              verifications={orderDetails?.verificationParams}
            />
          )}
        </div>
        <div className="active-order--right">
          <ActiveOrderDetails
            isLoading={isLoading}
            orderDetails={orderDetails}
          />
          {fixedInstallation && (
            <ReceiptProtocolGenerator orderDetails={orderDetails} />
          )}
          <Button
            label="Zakończ zlecenie"
            className="active-order__details-button"
            onClick={() => handleCloseOrder()}
          />
        </div>
      </div>
    </>
  );
};

export default ActiveOrder;
