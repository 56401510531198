import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  SingleSectionElement,
  BuildingMaterialComponent,
} from "features/addingMeasurement";
import { Button, Input, Textarea, Switch } from "components";
import { VALIDATION_MESSAGES } from "enums";
import { usePutExternalDoorsInstallationInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";

interface ExternalDoorInRowInstallationInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const ExternalDoorInRowInstallationInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: ExternalDoorInRowInstallationInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialMeasurementInstallationInfo =
    measurementInfo?.measurementInstallationInfo;

  const { mutate: editExternalDoorInRowInstallationForm } =
    usePutExternalDoorsInstallationInfo({
      onSuccess: () => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("measurementPage", "4");
        setSearchParams(newSearchParams, { replace: true });
        setStep(step + 1);
      },
      onError: () => {
        console.error("Błąd podczas aktualizacji danych klienta.");
      },
    });

  const validationSchema = Yup.object().shape({
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    theBuildingIsMadeOf: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test("is-other-check", VALIDATION_MESSAGES.REQUIRED, function (value) {
        const { theBuildingIsMadeOfComment } = this.parent;
        if (value === "other" && !theBuildingIsMadeOfComment) {
          return false;
        }
        return true;
      }),
    theBuildingIsMadeOfComment: Yup.string(),
  });

  const handleOnSubmit = async (values: any) => {
    delete values.constructionSiteComments;
    await editExternalDoorInRowInstallationForm(values);
  };

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          numberOfAssemblyTeams:
            initialMeasurementInstallationInfo?.numberOfAssemblyTeams || "",
          plannedAssemblyTime:
            initialMeasurementInstallationInfo?.plannedAssemblyTime || "",
          theBuildingIsMadeOf:
            initialMeasurementInstallationInfo?.theBuildingIsMadeOf || "",
          theBuildingIsMadeOfComment:
            initialMeasurementInstallationInfo?.theBuildingIsMadeOfComment ||
            "",
          isThereAPlanForARoofAboveTheDoor:
            initialMeasurementInstallationInfo?.isThereAPlanForARoofAboveTheDoor ||
            false,
          comments: initialMeasurementInstallationInfo?.comments || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                errors={errors}
                name="theBuildingIsMadeOf"
                component={BuildingMaterialComponent}
              />
              <SingleSectionElement
                className="padding-bottom-separator"
                touched={touched}
                name="isThereAPlanForARoofAboveTheDoor"
                errors={errors}
              >
                <Field
                  checked={values.isThereAPlanForARoofAboveTheDoor}
                  label={`Czy jest przewidziane zadaszenie nad drzwiami?`}
                  name="isThereAPlanForARoofAboveTheDoor"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="plannedAssemblyTime"
                errors={errors}
              >
                <Field
                  label="Planowany czas montażu (w godzinach)"
                  name="plannedAssemblyTime"
                  maxLength={6}
                  type="number"
                  rightPlaceholder="h"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="numberOfAssemblyTeams"
                errors={errors}
              >
                <Field
                  label="Ilość osób potrzebnych do montażu"
                  name="numberOfAssemblyTeams"
                  type="number"
                  component={Input}
                  maxLength={3}
                />
              </SingleSectionElement>
              <Field
                optional
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ExternalDoorInRowInstallationInformation;
