import { Button } from "components";
import { SVG_TYPE } from "enums";

interface PhotosSectionType {
  label: string;
  setIsModalOpen: (value: boolean) => void;
}

const PhotosSection = ({ label, setIsModalOpen }: PhotosSectionType) => {
  const handleAddPhotos = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="active-order__bar">
        <div className="active-order__bar-heading photos-active-order__bar-heading">
          <div className="active-order__heading">{label}</div>
          <Button
            stroke
            svgType={SVG_TYPE.PLUS}
            size="medium"
            label={`Dodaj zdięcie `}
            onClick={() => handleAddPhotos()}
          />
        </div>
        <div className="photos-active-order__list">
          <div className="photos-active-order__item">
            <img
              src="https://ocdn.eu/pulscms-transforms/1/r-Nk9kpTURBXy8wZWY0Zjc3NGQyYzYyMTZmNzYyYzI5YjAwNGQxYTlkMC5qcGeTlQMAMs0DIM0BwpUCzQSwAMPDkwmmNjk5OTcwBt4AAaEwAQ/szczescie-czyli-najslynniejsze-zdjecie-swiata.jpeg"
              alt=""
            />
          </div>
          <div className="photos-active-order__item">
            <img
              src="https://ocdn.eu/pulscms-transforms/1/r-Nk9kpTURBXy8wZWY0Zjc3NGQyYzYyMTZmNzYyYzI5YjAwNGQxYTlkMC5qcGeTlQMAMs0DIM0BwpUCzQSwAMPDkwmmNjk5OTcwBt4AAaEwAQ/szczescie-czyli-najslynniejsze-zdjecie-swiata.jpeg"
              alt=""
            />
          </div>
          <div className="photos-active-order__item">
            <img
              src="https://ocdn.eu/pulscms-transforms/1/r-Nk9kpTURBXy8wZWY0Zjc3NGQyYzYyMTZmNzYyYzI5YjAwNGQxYTlkMC5qcGeTlQMAMs0DIM0BwpUCzQSwAMPDkwmmNjk5OTcwBt4AAaEwAQ/szczescie-czyli-najslynniejsze-zdjecie-swiata.jpeg"
              alt=""
            />
          </div>
          <div className="photos-active-order__item">
            <img
              src="https://ocdn.eu/pulscms-transforms/1/r-Nk9kpTURBXy8wZWY0Zjc3NGQyYzYyMTZmNzYyYzI5YjAwNGQxYTlkMC5qcGeTlQMAMs0DIM0BwpUCzQSwAMPDkwmmNjk5OTcwBt4AAaEwAQ/szczescie-czyli-najslynniejsze-zdjecie-swiata.jpeg"
              alt=""
            />
          </div>
          <div className="photos-active-order__item">
            <img
              src="https://ocdn.eu/pulscms-transforms/1/r-Nk9kpTURBXy8wZWY0Zjc3NGQyYzYyMTZmNzYyYzI5YjAwNGQxYTlkMC5qcGeTlQMAMs0DIM0BwpUCzQSwAMPDkwmmNjk5OTcwBt4AAaEwAQ/szczescie-czyli-najslynniejsze-zdjecie-swiata.jpeg"
              alt=""
            />
          </div>
          <div className="photos-active-order__item">
            <img
              src="https://ocdn.eu/pulscms-transforms/1/r-Nk9kpTURBXy8wZWY0Zjc3NGQyYzYyMTZmNzYyYzI5YjAwNGQxYTlkMC5qcGeTlQMAMs0DIM0BwpUCzQSwAMPDkwmmNjk5OTcwBt4AAaEwAQ/szczescie-czyli-najslynniejsze-zdjecie-swiata.jpeg"
              alt=""
            />
          </div>
          <div className="photos-active-order__item">+ 13</div>
        </div>
      </div>
    </>
  );
};

export default PhotosSection;
