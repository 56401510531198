import { MEASUREMENTS_TYPE_TRANSLATIONS, SVG_TYPE } from "enums";
import { SVG } from "components";
import MEASUREMENTS_TYPE from "enums/measurementsTypes";
import "./installation-arranging.scss";
import moment from "moment";

interface InstallationArrangingTypes {
  installation: {
    typeOfMeasurement: MEASUREMENTS_TYPE;
    dates: Array<{
      date: string;
      team: Array<{
        end: string;
        start: string;
        employeeUuids: Array<{
          firstName: string;
          id: number;
          lastName: string;
          status: string;
          uuid: string;
        }>;
      }>;
    }>;
  };
}

const InstallationArranging = ({
  installation,
}: InstallationArrangingTypes) => {
  const type = installation.typeOfMeasurement;
  const formatTime = (time: string) => {
    return moment(time, "HH:mm").format("H:mm");
  };
  return (
    <div className="installation-planning">
      <div className="installation-planning__top">
        <div className="installation-planning__left">
          <div className="installation-planning__label">
            {MEASUREMENTS_TYPE_TRANSLATIONS[type]}
          </div>
          <div className="installation-planning__numbers">
            <div className="installation-planning__number-item">
              <SVG type={SVG_TYPE.CLOCK} /> 2h
            </div>
            <div className="installation-planning__number-item">
              <SVG type={SVG_TYPE.USER} /> 3
            </div>
          </div>
        </div>
      </div>
      <div className="installation-planning__bottom">
        {installation.dates &&
          installation.dates.length > 0 &&
          installation.dates.map((item) => {
            return (
              <>
                <div className="installation-arranging__item installation-arranging__item-separator">
                  <div className="installation-arranging__label">Termin</div>
                  <div className="installation-arranging__value">
                    {item.date}
                  </div>
                </div>
                {item.team &&
                  item.team.length > 0 &&
                  item.team.map((element: any) => (
                    <>
                      <div
                        className={`installation-arranging__item installation-arranging__item--middle ${
                          item.team.length === 1
                            ? "installation-arranging__item--only-one"
                            : ""
                        }`}
                      >
                        <div className="installation-arranging__label">
                          Pracownik/cy
                        </div>
                        <div className="installation-arranging__value">
                          {element.employeeUuids &&
                            element.employeeUuids.length > 0 &&
                            element.employeeUuids.map((employee: any) => {
                              return (
                                <div>
                                  {employee.firstName} {employee.lastName}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="installation-arranging__item">
                        <div className="installation-arranging__label">
                          Przedział godzinowy
                        </div>
                        <div className="installation-arranging__value">
                          {formatTime(element.start)} -{" "}
                          {formatTime(element.end)}
                        </div>
                      </div>
                    </>
                  ))}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default InstallationArranging;
