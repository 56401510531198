import { useSearchParams } from "react-router-dom";
import {
  BlindsAndShuttersInstallationInformation,
  BlindsAndShuttersMeasurementValues,
} from "./components";

interface BlindsAndShuttersType {
  setStep: (page: number) => void;
  step: number;
  measurementInfo: any;
  prevStep: () => void;
}

const BlindsAndShutters = ({
  step,
  setStep,
  measurementInfo,
  prevStep,
}: BlindsAndShuttersType) => {
  const [searchParams] = useSearchParams();
  const measurementUuid = searchParams.get("measurementUuid");

  const handleShowForm = (step: number, measurementUuid: string | null) => {
    switch (step) {
      case 2:
        return (
          <BlindsAndShuttersInstallationInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <BlindsAndShuttersMeasurementValues
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      default:
        return <p>default</p>;
    }
  };

  return handleShowForm(step, measurementUuid);
};

export default BlindsAndShutters;
