import { Formik, Field } from "formik";
import { Input, Switch, Button, FourState } from "components";
import "./invoice-and-payment-methods.scss";
import { SVG_TYPE } from "enums";

interface InvoiceAndPaymentMethodsProps {
  orderDetails: any;
}
const InvoiceAndPaymentMethods = ({
  orderDetails,
}: InvoiceAndPaymentMethodsProps) => {
  return (
    <div className="invoice-and-payment-methods">
      <Formik
        initialValues={{
          invoiceMail: false,
          email: orderDetails.email || "",
          paymentMethod: "none",
        }}
        enableReinitialize
        validationSchema={null}
        onSubmit={async (values: any) => console.log(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue }) => {
          return (
            <form className="invoice-and-payment-methods__form">
              <Field
                checked={values.invoiceMail}
                label="Faktura na maila"
                secondary
                name="invoiceMail"
                component={Switch}
              />
              {values.invoiceMail && (
                <Field
                  label="Adres email do wysyłki "
                  secondary
                  name="email"
                  component={Input}
                />
              )}
              <div className="invoice-and-payment-methods__form__button">
                <Button
                  label="Pobierz Fakturę"
                  svgType={SVG_TYPE.DOWNLOAD_FILE}
                  stroke
                  size="medium"
                />
              </div>
              <FourState
                secondary
                label="Metoda płatności"
                values={values}
                setFieldValue={setFieldValue}
                name="paymentMethod"
                first={{ name: "Gotówka", value: "investor" }}
                second={{ name: "Przelew", value: "martdom" }}
                third={{ name: "Salon", value: "salon" }}
                fourth={{ name: "Brak", value: "none" }}
              />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InvoiceAndPaymentMethods;
