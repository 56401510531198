
export enum ORDER_ENUM {
  MEASUREMENTS_TO_BE_RELEASED = 'measurementsToBeReleased', // Pomiar do ustalenia
  FIXED_MEASUREMENTS = 'fixedMeasurements', // Pomiar ustalony
  MEASUREMENTS_DONE = 'measurementsDone', //  Pomiar wykonany
  VERIFICATION_TO_BE_RELEASED = 'verificationToBeReleased', // Weryfikacja do ustalenia
  FIXED_VERIFICATION = 'fixedVerification', // Weryfikacja ustalona
  INSTALLATION_TO_BE_RELEASED = 'installationToBeReleased', // Montaż do ustalenia
  FIXED_INSTALLATION = 'fixedInstallation', // Montaż ustalony
  INSTALLATION_IN_PROGESS = 'installationInProgress', // W trakcie montażu
  INSTALLATION_DONE = 'installationDone', // Montaż zakończony
}



