import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

export default function useGetChecklistOrder(orderUuid: string | null) {
  return useQuery({
    queryKey: ["checklistOrder", orderUuid],
    queryFn: async () => {
      return axiosInstance.get("/check-list/all", {
        params: { orderUuid },
      }).then((res) => res.data);
    },
    enabled: Boolean(orderUuid),
    select: (data) => data?.checkLists,
    retry: false,
  });
}
