import { PortalWithState } from "react-portal";
import { useState, useRef } from "react";
import Slider from "react-slick";
import { Button, SVG } from "components";
import { SVG_TYPE } from "enums";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface PhotosModalType {
  isOpen: boolean;
  onClose: () => void;
}

const PhotosModal = ({ isOpen, onClose }: PhotosModalType) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [filePreviews, setFilePreviews] = useState<string[]>([]);
  const sliderRef = useRef<Slider | null>(null);

  console.log(selectedFiles);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFilesArray = Array.from(files);

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFilesArray]);

      const newFilePreviews = newFilesArray.map((file) =>
        URL.createObjectURL(file)
      );
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newFilePreviews]);
    }
  };

  const handleDelete = (indexToDelete: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
    setFilePreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToDelete)
    );
  };
  const handlePreviewClick = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const CustomNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{ ...style }}
        onClick={onClick}
      >
        <SVG type={SVG_TYPE.ARROW_BACK} />
      </div>
    );
  };

  const CustomPrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{ ...style }}
        onClick={onClick}
      >
        <SVG type={SVG_TYPE.ARROW_BACK} />
      </div>
    );
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
      <PortalWithState
        closeOnEsc={false}
        closeOnOutsideClick={false}
        defaultOpen={true}
      >
        {({ portal }) => (
          <>
            {portal(
              <>
                {isOpen && (
                  <div className="full-page am">
                    <div className="am__content">
                      <div className="am-top">
                        <Button
                          label="Zamknij"
                          stroke
                          size="medium"
                          svgType={SVG_TYPE.CLOSE}
                          onClick={() => onClose()}
                        />
                      </div>
                      <div className="photos-modal">
                        <div className="photos-modal__top">
                          <div className="photos-modal__label">
                            Zdjęcia z montażu
                          </div>
                          <Button
                            label="Dodaj zdjęcia"
                            size="medium"
                            onClick={() =>
                              document.getElementById("fileInput")?.click()
                            }
                          />
                          <input
                            id="fileInput"
                            multiple
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </div>
                        <div className="photos-modal__carousel">
                          {filePreviews.length > 0 && (
                            <Slider {...sliderSettings} ref={sliderRef}>
                              {filePreviews.map((preview, index) => (
                                <div className="slick-item" key={index}>
                                  <div
                                    className="slick-item--delete"
                                    onClick={() => handleDelete(index)}
                                  >
                                    <SVG type={SVG_TYPE.TRASH_SECOND} />
                                  </div>
                                  <img
                                    src={preview}
                                    alt={`preview-${index}`}
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                </div>
                              ))}
                            </Slider>
                          )}
                        </div>
                        {filePreviews.length > 0 && (
                          <div className="photos-modal__previews">
                            {filePreviews.map((preview, index) => (
                              <div
                                className="photos-modal__previews-item"
                                key={index}
                                onClick={() => handlePreviewClick(index)}
                              >
                                <img src={preview} alt={`preview-${index}`} />
                                <div
                                  className="photos-modal__previews-item--delete"
                                  onClick={() => handleDelete(index)}
                                >
                                  <SVG type={SVG_TYPE.TRASH_SECOND} />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </PortalWithState>
    </>
  );
};

export default PhotosModal;
