import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface AddUsedAssemblyMaterialType {
  assemblyUuid: string;
  count: number;
  orderUuid: string;
}

const useAddUsedAssemblyMaterial = (
  options?: UseMutationOptions<any, Error, AddUsedAssemblyMaterialType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['addUsedAssemblyMaterial'],
    mutationFn: async ({ assemblyUuid, count, orderUuid }) => {
      return axiosInstance.post('/assembly-materials/used', {
        assemblyUuid,
        count,
        orderUuid,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie dodawania materiału do zlecenia...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'add-used-assembly-material',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Materiał został pomyślnie dodany do zlecenia',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'add-used-assembly-material',
      });
      queryClient.invalidateQueries({ queryKey: [ 'orderDetails'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas dodawania materiału do zlecenia',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'add-used-assembly-material',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAddUsedAssemblyMaterial;
