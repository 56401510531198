import { Button, FileItem } from "components";
import { USER_ROLE } from "enums";

interface MeasurementArrangedInstallationProps {
  orderDetails: any;
  handleClickAppointment: (type: string) => void;
  userRole?: string;
}

const MeasurementArrangedInstallation = ({
  orderDetails,
  handleClickAppointment,
  userRole,
}: MeasurementArrangedInstallationProps) => {
  const measurementArrangedInstallation =
    orderDetails?.measurementArrangedInstallation;
  const isAdmin = userRole === USER_ROLE.admin;

  return (
    <>
      {measurementArrangedInstallation &&
        measurementArrangedInstallation.length > 0 && (
          <>
            <div className="am-separator" />
            <div className="modal__information-header">
              Montaże ustalone
              {!isAdmin && (
                <Button
                  size="small"
                  label="Edytuj"
                  onClick={() => handleClickAppointment("edit")}
                />
              )}
            </div>
          </>
        )}
      {measurementArrangedInstallation &&
        measurementArrangedInstallation.length > 0 &&
        measurementArrangedInstallation.map((measurement: any) => {
          return (
            <div className="order-preview__installations">
              <FileItem name={measurement.typeOfMeasurement} />
              <div className="order-preview__installation order-preview__installation-arranged">
                {measurement.dates.map((arranged: any) => {
                  return (
                    <>
                      {arranged.team.map((team: any, index: number) => {
                        return (
                          <>
                            <div className="order-preview__installation">
                              <div className="order-preview__installation-label">
                                Termin:
                              </div>
                              <div className="order-preview__installation-value">
                                {arranged.date}, {team.start} - {team.end}
                              </div>
                            </div>
                            <div className="order-preview__installation">
                              <div className="order-preview__installation-label">
                                Przypisani pracownicy
                              </div>
                              <div className="order-preview__installation-value">
                                {team.employeeUuids.length > 0 &&
                                  team.employeeUuids.map(
                                    (employee: any, index: number) => {
                                      const lastElement =
                                        team.employeeUuids.length === index + 1;

                                      return (
                                        <>
                                          {`${employee.firstName} ${
                                            employee.lastName
                                          }${lastElement ? "" : ","} `}
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="am-separator" />
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default MeasurementArrangedInstallation;
