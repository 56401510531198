import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type GarageDoorInfoData = {
  typeOfGate: 'swingGate' | 'tiltingGate' | 'sectionalGate' | 'rollerGate';
  holeSizeWidth: string;
  holeSizeHeight: string;
  gateSizeWidth: string;
  gateSizeHeight: string;
  methodOfOpening?: 'rightSideIsActive' | 'leftSideIsActive' | 'manual' | 'automatic';
  gateDivision?: 'symmetrical' | 'asymmetrical';
  activeWing?: string;
  passiveWing?: string;
  gateType?: 'insulated' | 'uninsulated';
  color: string;
  warningComment?: string;
  comments?: string;
  plasterDuringTheMeasurement?: boolean;
  styrofoamPlannedForTheCeiling?: boolean;
  styrofoamThickness?: string;
  installationDifficult?: boolean;
  installationDifficultComment?: string;
  lintelHeight?: string;
  widthOfTheRightLateralSpace?: string;
  widthOfTheLeftLateralSpace?: string;
  garageDepth?: string;
  typeOfRail?: 'chain' | 'strap';
  guidanceType?: 'front' | 'back' | 'side';
  unlockingTheDriveFromTheOutside?: boolean;
  doorInTheGate?: boolean;
  doorPosition?: 'right' | 'left' | 'middle';
  sideOfTheDoor?: 'right' | 'left';
  additionalHangers?: boolean;
  additionalHangersComments?: string;
  sectionalGateType?: string;
  typeOfEmbossing?: string;
  glazingInTheGate?: boolean;
  assemblyType?: 'inTheAlcove' | 'onTheWallOutside' | 'onTheWallInside';
  cableExitSide?: 'side' | 'back' | 'front';
  typeOfDrive?: 'radioPlusKey' | 'radio' | 'key' | 'wifi';
  sideOfThePowerCable?: 'right' | 'left';
};

const usePutGarageDoorsInfo = (
  options?: UseMutationOptions<any, Error, GarageDoorInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editGarageDoorsInfo'],
    mutationFn: async (data) => {
      return axiosInstance
        .put('/measurement/garage-doors/info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych bramy garażowej...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-garage-doors-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane bramy garażowej zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-garage-doors-info',
      });
      queryClient.invalidateQueries({ queryKey: ['garageDoorsInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych bramy garażowej.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-garage-doors-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutGarageDoorsInfo;
