import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

interface MeasurerOrdersResponse {
  orders: Array<{
    id: number;
    uuid: string;
    title: string;
    start: string;
    end: string;
    date: string;
  }>;
}

export default function useGetMeasurerSchedule(month: number | null, year: number | null) {
  return useQuery({
    queryKey: ["measurerSchedule", month],
    queryFn: async () => {
      if (month === null) {
        throw new Error("Missing required parameter: month");
      }
      const params = { month, year };

      return axiosInstance
        .get("/orders/date/measurer", { params })
        .then((res) => res.data);
    },
    select: (data: MeasurerOrdersResponse) => data.orders,
    retry: false,
    enabled: month !== null,
  });
}
