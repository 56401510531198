import { useState, useEffect } from "react";
import {
  useGetChecklistOrder,
  useToggleCheckListStatus,
} from "hooks/checklist";
import { CheckboxPart, SVG, StaticAlert } from "components";
import "./checklists.scss";
import { SVG_TYPE } from "enums";
import { CheckListsType, CheckListType } from "types/CheckListTypes";
import { useSearchParams } from "react-router-dom";

interface CheckListsProps {
  onRequiredStatusChange: (hasUncheckedRequired: boolean) => void;
  errorEndAppointment: { isError: boolean; errorMessage: string };
}

const CheckLists = ({
  onRequiredStatusChange,
  errorEndAppointment,
}: CheckListsProps) => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const { data } = useGetChecklistOrder(orderUuid);
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const toggleChecklist = (id: number) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };
  const { mutate: toggleCheckListStatus } = useToggleCheckListStatus();
  const isOnlyOne = data && data.length === 1;

  const handleCheckboxChange = (uuid: string, status: boolean) => {
    orderUuid &&
      toggleCheckListStatus({
        orderUuid,
        checkListUuid: uuid,
        status: status,
      });
  };

  const hasUncheckedRequiredItems = (checkLists: CheckListsType[]): boolean => {
    return checkLists.some((item) =>
      item.checkList.some((element) => element.required && !element.checked)
    );
  };

  useEffect(() => {
    const hasRequiredUnchecked = data && hasUncheckedRequiredItems(data);
    onRequiredStatusChange(hasRequiredUnchecked ?? false);
  }, [data, onRequiredStatusChange]);

  return (
    <>
      <div className="checklist__bar ">
        <div>
          {isOnlyOne ? (
            <>
              <div className="active-order__bar-heading checklist__bar-heading">
                <div className="active-order__heading checklist__heading">
                  {data && data[0].measurementName}
                </div>
              </div>

              {data &&
                data[0].checkList.length > 0 &&
                data[0].checkList.map((item: CheckListType) => {
                  return (
                    <CheckboxPart
                      checked={item.checked}
                      className="checklist__item"
                      key={item.value}
                      required={item.required}
                      label={item.label}
                      uuid={item.value}
                      onChange={(uuid) =>
                        handleCheckboxChange(uuid, !item.checked)
                      }
                    />
                  );
                })}
            </>
          ) : (
            <>
              <div className="active-order__bar-heading checklist__bar-heading">
                <div className="active-order__heading checklist__heading">
                  Checklisty
                </div>
              </div>
              {data &&
                data.length > 0 &&
                data.map((item: CheckListsType) => {
                  const isExpanded = expandedId === item.typeOfMeasurementId;
                  return (
                    <div
                      className="checklist__content-wrapper"
                      key={item.typeOfMeasurementId}
                    >
                      <div
                        className="checklist__label-wrapper"
                        onClick={() =>
                          toggleChecklist(item.typeOfMeasurementId)
                        }
                      >
                        <div className="checklist__label">
                          {item.measurementName}
                        </div>
                        <div
                          className={`checklist__chevron ${
                            isExpanded ? "rotated" : ""
                          }`}
                        >
                          <SVG type={SVG_TYPE.CHEVRON_DOWN} />
                        </div>
                      </div>
                      <div
                        className={`checklist__content ${
                          isExpanded ? "checklist__content--expanded" : ""
                        }`}
                      >
                        {item.checkList.length > 0 &&
                          item.checkList.map((element: CheckListType) => {
                            return (
                              <CheckboxPart
                                className="checklist__item"
                                checked={element.checked}
                                key={element.value}
                                required={element.required}
                                label={element.label}
                                uuid={element.value}
                                onChange={(uuid) =>
                                  handleCheckboxChange(uuid, !element.checked)
                                }
                              />
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
        <StaticAlert
          show={errorEndAppointment.isError}
          label={errorEndAppointment.errorMessage}
        />
      </div>
    </>
  );
};

export default CheckLists;
