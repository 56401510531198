import {
  Button,
  Modal,
  Input,
  PhoneWithPrefix,
  Select,
  RateInput,
  ColorInput,
} from "components";
import { useGetLocations, usePhonePrefixes } from "hooks/utils";
import { useAddEmployee, useAddAdmin } from "hooks/users";
import { addAdminType, addEmployeeType } from "types/UsersTypes";
import { Field, Formik } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import { employeeRoleOptions } from "utils";
import * as Yup from "yup";
import "./add-user.scss";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
interface AddUserType {
  isAdminForm: boolean;
  isOpen: boolean;
  onClose: () => void;
  handleChangeTab: (item: { value: string }) => void;
}

const AddUser = ({
  isOpen,
  onClose,
  isAdminForm,
  handleChangeTab,
}: AddUserType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = usePhonePrefixes();
  const { data: locations } = useGetLocations();
  const navigate = useNavigate();

  const addEmployeeMutation = useAddEmployee({
    onSuccess: (data, variables) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("page", data.user.page);
      newSearchParams.set("location", data.user.location.value);
      setSearchParams(newSearchParams);
      navigate(`?${newSearchParams.toString()}`);
      window.location.reload();
    },
  });

  const addAdminMutation = useAddAdmin({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("page", data.user.page);
      newSearchParams.set("location", "0");
      setSearchParams(newSearchParams);
      navigate(`?${newSearchParams.toString()}`);
      window.location.reload();
    },
  });

  const phonePrefixOptions =
    data &&
    data?.phonePrefixSerialize &&
    data?.phonePrefixSerialize.length > 0 &&
    data?.phonePrefixSerialize.map((item: any) => ({
      label: item.phonePrefix,
      value: item.value,
    }));

  type FormValues = addAdminType | addEmployeeType;
  let validationSchema;
  let initialValues;
  if (isAdminForm) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      lastName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      email: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .email(VALIDATION_MESSAGES.EMAIL),
    });
  } else {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      lastName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      phoneNumber: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(9, VALIDATION_MESSAGES.MIN9DIGIT),
      role: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      rate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      locationUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      userColor: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    });
  }

  if (isAdminForm) {
    initialValues = {
      firstName: "",
      lastName: "",
      email: "",
    };
  } else {
    initialValues = {
      firstName: "",
      lastName: "",
      phonePrefixUuid: phonePrefixOptions ? phonePrefixOptions[0].value : +48,
      phoneNumber: "",
      role: "",
      locationUuid: "",
      rate: "",
      userColor: "",
    };
  }

  const handleOnSubmit = (values: FormValues) => {
    console.log(values);
    if (isAdminForm) {
      addAdminMutation.mutate(values as addAdminType);
    } else {
      addEmployeeMutation.mutate(values as addEmployeeType);
    }
  };

  return (
    <>
      <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Dodawanie użytkownika</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <form className="add-user__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Imię"
                  name="firstName"
                  maxLength={20}
                  component={Input}
                />
                <Field
                  errors={errors}
                  label="Nazwisko"
                  maxLength={20}
                  name="lastName"
                  component={Input}
                />
                {isAdminForm ? (
                  <Field
                    errors={errors}
                    label="Email"
                    maxLength={30}
                    name="email"
                    component={Input}
                  />
                ) : (
                  <>
                    <PhoneWithPrefix>
                      <Field
                        label="Telefon"
                        name="phonePrefixUuid"
                        required
                        component={Select}
                        errors={errors}
                        options={phonePrefixOptions}
                        size="small"
                      />
                      <Field
                        name="phoneNumber"
                        type="number"
                        maxLength={9}
                        errors={errors}
                        component={Input}
                      />
                    </PhoneWithPrefix>

                    <Field
                      label="Stanowisko"
                      name="role"
                      required
                      component={Select}
                      errors={errors}
                      options={employeeRoleOptions}
                    />
                    <Field
                      maxLength={4}
                      name="rate"
                      errors={errors}
                      component={RateInput}
                      rightPlaceholder={"zł/h netto"}
                    />
                    <Field
                      label="Kolor użytkownika"
                      name="userColor"
                      errors={errors}
                      component={ColorInput}
                    />
                    <Field
                      label="Lokalizacja"
                      name="locationUuid"
                      required
                      component={Select}
                      errors={errors}
                      options={locations}
                    />
                  </>
                )}
                <div className="modal-buttons add-user__actions">
                  <Button stroke onClick={() => onClose()} label="Anuluj" />
                  <Button type="submit" label="Potwierdź" />
                </div>
              </form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default AddUser;
