/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Field, Formik } from "formik";
import { Input, Button, Switch, Select, TriState } from "components";
import {
  SingleSectionElement,
  HoleDimension,
  AdditionalWarning,
} from "features/addingMeasurement";
import { ScrollToError } from "utils";
import * as Yup from "yup";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import {
  usePutInteriorDoorsInfo,
  useStartMeasurement,
} from "hooks/measurements";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ScrollToTopForm } from "features/addingMeasurement/utils";

interface InteriorDoorMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const InteriorDoorMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: InteriorDoorMeasurementValuesType) => {
  const initialMeasurementInfo = measurementInfo?.measurementInfo;
  const [isErrorDimension, setIsErrorDimension] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);

  const initialValues = {
    measurementUuid: measurementUuid,
    roomName: initialMeasurementInfo?.roomName || "",
    durability: initialMeasurementInfo?.durability || "right",
    openingDirection: initialMeasurementInfo?.openingDirection || "outward",
    holeSizeWidth: initialMeasurementInfo?.holeSizeWidth || "",
    holeSizeHeight: initialMeasurementInfo?.holeSizeHeight || "",
    customDoors: initialMeasurementInfo?.customDoors || false,
    doorWidth: initialMeasurementInfo?.doorWidth || "",
    doorHeight: initialMeasurementInfo?.doorHeight || "",
    doorSize: initialMeasurementInfo?.doorSize || "",
    wallThickness: initialMeasurementInfo?.wallThickness || "",
    verticalDeviation: initialMeasurementInfo?.verticalDeviation || "",
    wereTheWallsFullyFinishedDuringTheMeasurement:
      initialMeasurementInfo?.wereTheWallsFullyFinishedDuringTheMeasurement ||
      false,
    whatIsThePlannedWallThickness:
      initialMeasurementInfo?.whatIsThePlannedWallThickness || "",
    doorFrameDimensions: initialMeasurementInfo?.doorFrameDimensions || "",
    widthOfStrips: initialMeasurementInfo?.widthOfStrips || "80",
    wasTheFloorFinishedDuringTheMeasurement:
      initialMeasurementInfo?.wasTheFloorFinishedDuringTheMeasurement || false,
    whatIsThePlannedCladdingThickness:
      initialMeasurementInfo?.whatIsThePlannedCladdingThickness || "",
    isTheOpeningCorrect: initialMeasurementInfo?.isTheOpeningCorrect || false,
    whatShouldBeTheCorrectWidth:
      initialMeasurementInfo?.whatShouldBeTheCorrectWidth || "",
    whatShouldBeTheCorrectHeight:
      initialMeasurementInfo?.whatShouldBeTheCorrectHeight || "",
    whoIsToPrepareTheOpening:
      initialMeasurementInfo?.whoIsToPrepareTheOpening || "investor",
    cuttingOfStrips: initialMeasurementInfo?.cuttingOfStrips || false,
    numberOfCuts: initialMeasurementInfo?.numberOfCuts || "",
    manufacturer: initialMeasurementInfo?.manufacturer || "",
    model: initialMeasurementInfo?.model || "",
    color: initialMeasurementInfo?.color || "",
    lockType: initialMeasurementInfo?.lockType || "none",
    lock: initialMeasurementInfo?.lock || "",
    ventilation: initialMeasurementInfo?.ventilation || "",
    edgeType: initialMeasurementInfo?.edgeType || "",
    frameType: initialMeasurementInfo?.frameType || "",
    warningComment: initialMeasurementInfo?.warningComment || "",
  };

  const [isValidated, setIsValidated] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}`);
    sessionStorage.removeItem("canvasState");
  };

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementUuid", data.measurement.uuid);
      newSearchParams.set(
        "measurementPage",
        data.measurement.lastFinishedPage + 1 || "2"
      );
      setSearchParams(newSearchParams, { replace: true });
      setStep(data.measurement.lastFinishedPage + 1 || 2);
    },
  });

  const mutation = usePutInteriorDoorsInfo({
    onSuccess: (data) => {
      if (isAddNew) {
        setIsAddNew(false);
        startMeasurement.mutate({
          typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
          orderUuid,
        });
        ScrollToTopForm("edit-interior-doors-info");
      } else {
        goToList();
      }
    },
  });

  const handleOnSubmit = async (values: any) => {
    mutation.mutate({ ...values });
  };

  const validationSchema = Yup.object().shape({
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    holeSizeWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeSizeHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),

    customDoors: Yup.boolean().required(VALIDATION_MESSAGES.REQUIRED),
    doorSize: Yup.number().test(
      "doorSize-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { customDoors } = this.parent;
        return customDoors ? true : value !== undefined;
      }
    ),
    doorWidth: Yup.number().test(
      "doorWidth-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { customDoors } = this.parent;
        return customDoors ? value !== undefined : true;
      }
    ),
    doorHeight: Yup.number().test(
      "doorHeight-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { customDoors } = this.parent;
        return customDoors ? value !== undefined : true;
      }
    ),
    wallThickness: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    verticalDeviation: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    whatIsThePlannedWallThickness: Yup.number().test(
      "whatIsThePlannedWallThickness-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { wereTheWallsFullyFinishedDuringTheMeasurement } = this.parent;
        return wereTheWallsFullyFinishedDuringTheMeasurement
          ? true
          : value !== undefined;
      }
    ),
    doorFrameDimensions: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    whatIsThePlannedCladdingThickness: Yup.number().test(
      "whatIsThePlannedCladdingThickness-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { wasTheFloorFinishedDuringTheMeasurement } = this.parent;
        return wasTheFloorFinishedDuringTheMeasurement
          ? true
          : value !== undefined;
      }
    ),
    whatShouldBeTheCorrectWidth: Yup.number().test(
      "whatShouldBeTheCorrectWidth-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { isTheOpeningCorrect } = this.parent;
        return isTheOpeningCorrect ? true : value !== undefined;
      }
    ),
    whatShouldBeTheCorrectHeight: Yup.number().test(
      "whatShouldBeTheCorrectHeight-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { isTheOpeningCorrect } = this.parent;
        return isTheOpeningCorrect ? true : value !== undefined;
      }
    ),
    numberOfCuts: Yup.number().test(
      "numberOfCuts-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { cuttingOfStrips } = this.parent;
        return cuttingOfStrips ? value !== undefined : true;
      }
    ),
  });

  const handleCheckValidateDimensions = (
    values: any,
    setFieldError: any,
    errors: any
  ) => {
    const {
      doorWidth,
      holeSizeWidth,
      doorHeight,
      warningComment,
      customDoors,
      doorSize,
    } = values;

    if ((doorWidth || doorSize) && holeSizeWidth) {
      const doorWidthParse = parseInt(customDoors ? doorWidth : doorSize, 10);
      const holeWidthParse = parseInt(holeSizeWidth, 10);
      const doorHeightParse = parseInt(doorHeight, 10);
      const widthDifference = holeWidthParse - doorWidthParse;
      let isError = false;
      if (widthDifference < 80 || widthDifference > 120) {
        isError = true;
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
      }
      if (doorHeightParse < 2060 || doorHeightParse > 2100) {
        isError = true;
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
      }
      if (!isError) {
        setIsErrorDimension(false);
      } else {
        setIsErrorDimension(true);
      }
    }

    return false;
  };

  const handleChangeCustomDoor = (checked: boolean, setFieldValue: any) => {
    if (!checked) {
      setIsErrorDimension(false);
      setFieldValue("warningComment", "");
    }
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          handleCheckValidateDimensions(values, setFieldError, errors);
          return (
            <form
              className="amfs__form form amfs--with-warning-comment"
              onSubmit={handleSubmit}
            >
              <ScrollToError errorDimension={isErrorDimension} />
              <SingleSectionElement
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="durability"
                errors={errors}
              >
                <Field
                  checked={values.durability === "left"}
                  first="Lewe"
                  second="Prawe"
                  stringMode
                  label="Stronność"
                  name="durability"
                  valueFirst="left"
                  valueSecond="right"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="openingDirection"
                errors={errors}
              >
                <Field
                  checked={values.openingDirection === "inward"}
                  label="Kierunek otwierania"
                  first="Do wewnątrz"
                  second="Na zewnątrz"
                  valueFirst="inward"
                  valueSecond="outward"
                  stringMode
                  name="openingDirection"
                  component={Switch}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                validateField={validateField}
                label="Wymiar otworu"
                component={HoleDimension}
              />
              <SingleSectionElement
                touched={touched}
                name="customDoors"
                errors={errors}
              >
                <Field
                  checked={values.customDoors}
                  label="Drzwi na wymiar"
                  name="customDoors"
                  component={Switch}
                  onChange={(e: any) =>
                    handleChangeCustomDoor(e.target.checked, setFieldValue)
                  }
                />
              </SingleSectionElement>
              {values.customDoors ? (
                <>
                  <Field
                    errors={errors}
                    validateField={validateField}
                    label="Rozmiar drzwi"
                    valueFirst="doorWidth"
                    valueSecond="doorHeight"
                    component={HoleDimension}
                  />
                </>
              ) : (
                <>
                  <SingleSectionElement
                    touched={touched}
                    name="doorSize"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Rozmiar drzwi"
                      type="number"
                      rightPlaceholder="mm"
                      name="doorSize"
                      component={Input}
                    />
                  </SingleSectionElement>
                </>
              )}
              <AdditionalWarning
                isErrorDimension={isErrorDimension}
                errors={errors}
                values={values}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
              />
              <SingleSectionElement
                touched={touched}
                name="wallThickness"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Grubość muru"
                  type="number"
                  rightPlaceholder="mm"
                  name="wallThickness"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="verticalDeviation"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Odchyłka od pionu"
                  type="number"
                  rightPlaceholder="mm"
                  name="verticalDeviation"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="wereTheWallsFullyFinishedDuringTheMeasurement"
                errors={errors}
              >
                <Field
                  checked={values.wereTheWallsFullyFinishedDuringTheMeasurement}
                  label={`Czy podczas pomiaru ściany były wykończone na gotowo?`}
                  name="wereTheWallsFullyFinishedDuringTheMeasurement"
                  component={Switch}
                />
              </SingleSectionElement>
              {!values.wereTheWallsFullyFinishedDuringTheMeasurement && (
                <>
                  <SingleSectionElement
                    touched={touched}
                    name="whatIsThePlannedWallThickness"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Planowana grubość ściany"
                      type="number"
                      rightPlaceholder="mm"
                      name="whatIsThePlannedWallThickness"
                      component={Input}
                    />
                  </SingleSectionElement>
                </>
              )}
              <SingleSectionElement
                touched={touched}
                name="doorFrameDimensions"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Zakres ościeżnicy"
                  maxLength={40}
                  name="doorFrameDimensions"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="widthOfStrips"
                errors={errors}
              >
                <Field
                  checked={values.widthOfStrips === "60"}
                  first="60"
                  second="80"
                  valueFirst="60"
                  valueSecond="80"
                  stringMode
                  label="Szerokość opasek"
                  name="widthOfStrips"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="wasTheFloorFinishedDuringTheMeasurement"
                errors={errors}
              >
                <Field
                  checked={values.wasTheFloorFinishedDuringTheMeasurement}
                  label={`Czy podczas pomiaru podłoga była wykończone na gotowo?`}
                  name="wasTheFloorFinishedDuringTheMeasurement"
                  component={Switch}
                />
              </SingleSectionElement>
              {!values.wasTheFloorFinishedDuringTheMeasurement && (
                <>
                  <SingleSectionElement
                    touched={touched}
                    name="whatIsThePlannedCladdingThickness"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Planowana grubość wykładziny"
                      name="whatIsThePlannedCladdingThickness"
                      type="number"
                      rightPlaceholder="mm"
                      component={Input}
                    />
                  </SingleSectionElement>
                </>
              )}
              <SingleSectionElement
                touched={touched}
                name="isTheOpeningCorrect"
                errors={errors}
              >
                <Field
                  checked={values.isTheOpeningCorrect}
                  label={`Czy otwór jest prawidłowy?`}
                  name="isTheOpeningCorrect"
                  component={Switch}
                />
              </SingleSectionElement>
              {!values.isTheOpeningCorrect && (
                <>
                  <SingleSectionElement
                    touched={touched}
                    name="whatShouldBeTheCorrectWidth"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Prawidłowa szerokość otworu"
                      name="whatShouldBeTheCorrectWidth"
                      type="number"
                      rightPlaceholder="mm"
                      component={Input}
                    />
                  </SingleSectionElement>
                  <SingleSectionElement
                    touched={touched}
                    name="whatShouldBeTheCorrectHeight"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Prawidłowa wysokość otworu"
                      name="whatShouldBeTheCorrectHeight"
                      type="number"
                      rightPlaceholder="mm"
                      component={Input}
                    />
                  </SingleSectionElement>
                  <SingleSectionElement
                    touched={touched}
                    name="whoIsToPrepareTheOpening"
                    errors={errors}
                  >
                    <Field
                      checked={values.whoIsToPrepareTheOpening === "investor"}
                      label={`Kto ma przygotować otwór?`}
                      name="whoIsToPrepareTheOpening"
                      first="Inwestor"
                      second="MartDom"
                      stringMode
                      valueFirst="investor"
                      valueSecond="martdom"
                      component={Switch}
                    />
                  </SingleSectionElement>
                </>
              )}
              <SingleSectionElement
                touched={touched}
                name="cuttingOfStrips"
                errors={errors}
              >
                <Field
                  checked={values.cuttingOfStrips}
                  label={`Cięcie opasek`}
                  name="cuttingOfStrips"
                  component={Switch}
                />
              </SingleSectionElement>
              {values.cuttingOfStrips && (
                <>
                  <SingleSectionElement
                    touched={touched}
                    name="numberOfCuts"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Ilość cięć"
                      name="numberOfCuts"
                      type="number"
                      component={Input}
                    />
                  </SingleSectionElement>
                </>
              )}
              <SingleSectionElement
                touched={touched}
                name="manufacturer"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Producent"
                  maxLength={40}
                  name="manufacturer"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="model"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  maxLength={40}
                  label="Model"
                  name="model"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="color"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  maxLength={30}
                  label="Kolor"
                  name="color"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="lockType"
                errors={errors}
              >
                <Field
                  className="tristate__field tristate__field--margin-top"
                  label={`Typ zamka`}
                  name="lockType"
                  first={{ name: "Standardowy", value: "standard" }}
                  second={{ name: "Magnetyczny", value: "magnetic" }}
                  third={{ name: "Brak", value: "none" }}
                  component={TriState}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="am-margin-top"
                touched={touched}
                name="lock"
                errors={errors}
              >
                <Field
                  optional
                  white
                  label="Zamek"
                  name="lock"
                  component={Select}
                  options={[
                    { value: "economy", label: "Oszczędnościowy" },
                    { value: "key", label: "Klucz" },
                    { value: "wc", label: "WC" },
                    { value: "patented", label: "Patentowy" },
                  ]}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="am-margin-top"
                touched={touched}
                name="ventilation"
                errors={errors}
              >
                <Field
                  optional
                  label="Wentylacja"
                  white
                  name="ventilation"
                  component={Select}
                  options={[
                    { value: "lack", label: "Brak" },
                    {
                      value: "ventilationUndercut",
                      label: "Podcięcie wentylacyjne",
                    },
                    {
                      value: "ventilationAbbreviation",
                      label: "Skrót wentylacyjny",
                    },
                    {
                      value: "ventilationSleeves",
                      label: "Tuleje wentylacyjne",
                    },
                  ]}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="am-margin-top"
                touched={touched}
                name="edgeType"
                errors={errors}
              >
                <Field
                  label="Typ krawędzi"
                  name="edgeType"
                  component={Select}
                  white
                  optional
                  options={[
                    { value: "rebated", label: "Przylgowe" },
                    { value: "flush", label: "Bezprzylgowe" },
                    { value: "reversible", label: "Rewersyjne" },
                    { value: "sliding", label: "Przesuwne" },
                  ]}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="am-margin-top"
                touched={touched}
                name="frameType"
                errors={errors}
              >
                <Field
                  label="Typ ościeżnicy"
                  name="frameType"
                  component={Select}
                  white
                  optional
                  options={[
                    { value: "adjustable", label: "Regulowana" },
                    { value: "fixed", label: "Stała" },
                    { value: "tunnel", label: "Tunel" },
                    { value: "none", label: "Brak" },
                  ]}
                />
              </SingleSectionElement>
              <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    setIsAddNew(true);
                    handleSubmit();
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default InteriorDoorMeasurementValues;
