/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./four-state.scss";

interface FourStateType {
  name?: any;
  first?: { name: string; value: string };
  second?: { name: string; value: string };
  third?: { name: string; value: string };
  fourth?: { name: string; value: string };
  value?: boolean | null;
  secondary?: boolean;
  label?: string;
  className?: string;
  field?: any;
  onChange?: (event: any) => void;
  setFieldValue: any;
  values: any;
}

const FourState = ({
  name,
  first,
  second,
  third,
  fourth,
  secondary = false,
  label,
  className = "",
  field,
  onChange,
  values,
  setFieldValue,
}: FourStateType) => {
  const [currentValue, setCurrentValue] = useState<any>(values[name]);

  useEffect(() => {
    setCurrentValue(values[name || field.name]);
  }, [values[name]]);

  useEffect(() => {
    setFieldValue(field?.name || name, currentValue);
  }, [currentValue]);

  const handleChange = (newValue: any) => {
    setCurrentValue(newValue);
    setFieldValue(field?.name || name, newValue);
    if (onChange) {
      onChange({ target: { name, value: newValue } });
    }
  };

  return (
    <div className={`fourstate__wrapper ${className}`}>
      {label && <div className="fourstate__name">{label}</div>}
      <div className={`fourstate ${secondary ? "fourstate__secondary" : ""}`}>
        <button
          type="button"
          className={`fourstate__option ${
            currentValue === first?.value ? "active" : ""
          }`}
          onClick={() => handleChange(first?.value)}
        >
          {first?.name}
        </button>
        <button
          type="button"
          className={`fourstate__option ${
            currentValue === second?.value ? "active" : ""
          }`}
          onClick={() => handleChange(second?.value)}
        >
          {second?.name}
        </button>
        <button
          type="button"
          className={`fourstate__option ${
            currentValue === third?.value ? "active" : ""
          }`}
          onClick={() => handleChange(third?.value)}
        >
          {third?.name}
        </button>
        <button
          type="button"
          className={`fourstate__option ${
            currentValue === fourth?.value ? "active" : ""
          }`}
          onClick={() => handleChange(fourth?.value)}
        >
          {fourth?.name}
        </button>
      </div>
    </div>
  );
};

export default FourState;
