import { Toast } from "components";
import { TOAST_TYPES_ENUM } from "enums";

const ScrollToTopForm = (toastId: string) => {
  const topElements = document.getElementsByClassName("am__content");
  if (topElements.length > 0) {
    topElements[0].scrollIntoView({ behavior: "smooth", block: "end" });
  }
  setTimeout(() => {
    Toast({
      message: "Utworzono nowy pomiar tego samego typu.",
      type: TOAST_TYPES_ENUM.SUCCESS,
      id: toastId,
    });
  }, 100);
};

export default ScrollToTopForm;
