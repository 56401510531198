import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

export default function useGetAdminChecklist(typeOfMeasurementUuid: string, isAvailable: boolean) {
  return useQuery({
    queryKey: ["checklist", typeOfMeasurementUuid],
    queryFn: async () => {
      return axiosInstance
        .get("/check-list", {
          params: {
            typeOfMeasurementUuid: typeOfMeasurementUuid,
          },
        })
        .then((res) => res.data);
    },
    select: (data) => data?.checkList,
    retry: false,
    enabled: !!typeOfMeasurementUuid && !!isAvailable,
  });
}
