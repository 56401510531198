import { Field, Formik } from "formik";
import { Button, Modal, Input } from "components";
import { VALIDATION_MESSAGES } from "enums";
import * as Yup from "yup";
import { useEditUsedAssemblyMaterial } from "hooks/assembly-materials";

interface EditingUsedAssemblyMaterialType {
  isOpen: boolean;
  onClose: () => void;
  usedAssemblyMaterialLabel: { label: string; value: string }[] | undefined;
  usedAssemblyMaterial: any;
}

const EditingUsedAssemblyMaterial = ({
  isOpen,
  onClose,
  usedAssemblyMaterialLabel,
  usedAssemblyMaterial,
}: EditingUsedAssemblyMaterialType) => {
  const { mutate: editUsedAssemblyMaterial } = useEditUsedAssemblyMaterial({
    onSuccess: () => {
      onClose();
    },
  });

  const initialValues = {
    name: usedAssemblyMaterial?.assemblyName,
    count: usedAssemblyMaterial?.count,
    uuid: usedAssemblyMaterial?.uuid,
  };

  const validationSchema = Yup.object().shape({
    count: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .matches(
        /^[^\s].*[^\\s]?$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
  });

  const handleOnSubmit = async (values: any) => {
    await editUsedAssemblyMaterial(values);
  };

  return (
    <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
      <>
        <div className="modal-heading">Edycja zużytego materiału</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values) => handleOnSubmit(values)}
        >
          {({ handleSubmit, errors }) => {
            return (
              <form className="add-user__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Materiał"
                  name="name"
                  maxLength={20}
                  component={Input}
                  disabled
                  options={usedAssemblyMaterialLabel}
                />
                <Field
                  errors={errors}
                  label="Ilość"
                  name="count"
                  component={Input}
                  maxLength={6}
                  withDot
                />

                <div className="modal-buttons">
                  <Button stroke onClick={() => onClose()} label="Anuluj" />
                  <Button onClick={() => handleSubmit()} label="Potwierdź" />
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    </Modal>
  );
};

export default EditingUsedAssemblyMaterial;
