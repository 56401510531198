import { useState, useRef } from "react";
import { Button, StaticAlert, Textarea } from "components";
import { Formik, Field } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import { useBackOrder } from "hooks/orders";
import * as Yup from "yup";

interface AppointmentCannotHappenProps {
  handleCloseOrder: () => void;
  uuid: string | undefined;
  type: string;
}

const AppointmentCannotHappen = ({
  handleCloseOrder,
  type,
  uuid,
}: AppointmentCannotHappenProps) => {
  const [isAppointmentCannotHappen, setIsAppointmentCannotHappen] =
    useState(false);
  const isMeasurement = type === "measurement";
  const leftButtonLabel = isMeasurement
    ? "Pomiar nie może się odbyć"
    : "Montaż nie może się odbyć";
  const rightButtonLabel = isMeasurement
    ? "Zamknij zlecenie"
    : "Zakończ zlecenie";

  const { mutate: backOrder } = useBackOrder({
    onSuccess: () => {
      handleCloseOrder();
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });

  const handleSubmit = (values: any) => {
    backOrder(values);
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const commentRef = useRef<HTMLDivElement>(null);

  const handleLeftButtonClick = () => {
    setIsAppointmentCannotHappen(true);
    setTimeout(() => {
      commentRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <>
      <div className="active-order__buttons">
        <Button
          stroke
          label={leftButtonLabel}
          onClick={handleLeftButtonClick}
        />
        <Button
          label={rightButtonLabel}
          className="active-order__measurement-button"
          onClick={() => handleCloseOrder()}
        />
      </div>
      {isAppointmentCannotHappen && (
        <div ref={commentRef}>
          <div className="active-order__cant-happen">
            <StaticAlert
              className="checklist__alert"
              show={true}
              label={`Uzupełnij komentarz poniżej, wpisując dlaczego ${
                isMeasurement ? "pomiar" : "montaż"
              } nie może się odbyć`}
            />
          </div>
          <Formik
            initialValues={{ comment: "", uuid: uuid }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values: any) => handleSubmit(values)}
          >
            {({ errors, handleSubmit }) => (
              <form
                className="active-order__cant-happen__form"
                onSubmit={handleSubmit}
              >
                <Field
                  name="comment"
                  label="Komentarz"
                  component={Textarea}
                  errors={errors}
                />

                <div className="active-order__cant-happen__form-button">
                  <Button
                    label="Zatwierdź"
                    size="small"
                    onClick={() => handleSubmit()}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default AppointmentCannotHappen;
