import "./rate-input.scss";
import { useState, ChangeEvent } from "react";
import { FieldProps } from "formik";
import classNames from "classnames";

interface RateInputProps {
  label?: string;
  type: string;
  inputOnFocus?: any;
  maxLength?: number;

  // formik;
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (data: string) => void;
  handleOnChange?: any;
  rightPlaceholder?: string;
  isOnBlurAction: boolean;
  onFocus: any;
  onBlur?: any;
}

const RateInput = ({
  label = "Stawka",
  errors = {},
  form,
  field,
  type,
  inputOnFocus,
  onBlur,
  maxLength,
  onChange,
  rightPlaceholder,
}: RateInputProps & FieldProps) => {
  const [inputFirstPart, setInputFirstPart] = useState<number | "">(
    field?.value?.inputFirstPart || ""
  );

  const handleFirstPartChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let numberValue: number | "" = "";

    const isNumeric = /^[0-9]*$/.test(value);
    if (!isNumeric) {
      return;
    }

    if (value === "") {
      numberValue = "";
    } else {
      if (maxLength && value.length > maxLength) {
        return;
      }
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        numberValue = parsedValue;
      } else {
        return;
      }
    }
    setInputFirstPart(numberValue);
    form.setFieldValue(field.name, numberValue);
  };

  const inputClass = classNames("rate-input");

  return (
    <div className="rate-input__wrapper">
      {label && <label className="rate-input__label">{label}</label>}
      <div className="rate-input__content">
        <input
          {...field}
          className={inputClass}
          name={field.name}
          minLength={0}
          maxLength={maxLength}
          onFocus={() => inputOnFocus && inputOnFocus()}
          onChange={(e) => {
            handleFirstPartChange(e);
            onChange && onChange(e.target.value);
          }}
          id={field.name}
          onBlur={(e) => onBlur && onBlur(e)}
          value={inputFirstPart || field.value}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
        />
        <div className="rate-input__constant">{rightPlaceholder}</div>
      </div>
      {errors && form.touched && (
        <div className="rate-input__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
};

export default RateInput;
