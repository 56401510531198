import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type SkirtingBoardInfoData = {
  measurementUuid: string;
  skirtingBoards: Array<{
    type: string;
    color: string;
    length: string;
    amount: string;
  }>;
  comments?: string;
};

const usePutSkirtingBoardInfo = (
  options?: UseMutationOptions<any, Error, SkirtingBoardInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editSkirtingBoardInfo'],
    mutationFn: async (data: SkirtingBoardInfoData) => {
      return axiosInstance
        .put('/measurement/skirting-board/info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych listew przypodłogowych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-skirting-board-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane listew przypodłogowych zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-skirting-board-info',
      });
      queryClient.invalidateQueries({ queryKey: ['skirtingBoardInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych listew przypodłogowych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-skirting-board-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutSkirtingBoardInfo;
