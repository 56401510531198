import React, { useState } from "react";
import { Button, SVG, ContextMenu, StaticAlert, SkeletonRow } from "components";
import { SVG_TYPE, ROUTE_ENUM } from "enums";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { DeleteMeasurement } from "features/orders";

interface MeasurementsProps {
  isLoading: boolean;
  measurements: any;
  errorEndMeasurement: any;
}

const Measurements = ({
  isLoading,
  measurements,
  errorEndMeasurement,
}: MeasurementsProps) => {
  const [isOpenDelete, setIsOpenDelete] = useState({
    label: "",
    isOpen: false,
    uuid: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const orderDetailsUrl = `?page=${page}&orderUuid=${orderUuid}`;

  const handleAddMeasurement = () => {
    sessionStorage.removeItem("canvasState");
    navigate(`${ROUTE_ENUM.ADDING_MEASUREMENT}${orderDetailsUrl}`);
  };

  const measurementMenu = ({ status, label, uuid, lastFinishedPage }: any) => {
    return [
      {
        name: status ? "Edytuj" : "Dokończ",
        action: () => {
          if (status) {
            navigate(
              `${ROUTE_ENUM.ADDING_MEASUREMENT}${orderDetailsUrl}&measurementUuid=${uuid}&measurementPage=${lastFinishedPage}`
            );
          } else {
            navigate(
              `${
                ROUTE_ENUM.ADDING_MEASUREMENT
              }${orderDetailsUrl}&measurementUuid=${uuid}&measurementPage=${
                lastFinishedPage + 1
              }`
            );
          }
        },
      },
      {
        name: "Usuń",
        action: () => {
          setIsOpenDelete({ label: label, isOpen: true, uuid });
        },
      },
    ];
  };

  return (
    <>
      <DeleteMeasurement
        data={isOpenDelete}
        isOpen={isOpenDelete.isOpen}
        onClose={() =>
          setIsOpenDelete({
            isOpen: false,
            label: isOpenDelete.label,
            uuid: isOpenDelete.uuid,
          })
        }
      />
      <div className="active-order__bar">
        <div className="active-order__bar-heading">
          <div className="active-order__heading">Pomiary</div>
          <Button
            stroke
            svgType={SVG_TYPE.PLUS}
            size="medium"
            label={`Dodaj pomiar `}
            onClick={() => handleAddMeasurement()}
          />
        </div>
        <div className="active-order__measurement-list">
          {isLoading ? (
            <SkeletonRow
              className="active-order__measurement-list--skeleton"
              count={2}
              height={40}
            />
          ) : (
            <>
              {measurements &&
                measurements.length > 0 &&
                measurements.map((item: any) => {
                  return (
                    <div
                      key={item.uuid}
                      className="active-order__measurement-item"
                    >
                      <div className="active-order__measurement-item__name">
                        {item.measurementLabel}
                        {item.roomName ? ` - ${item.roomName}` : ""}
                      </div>
                      <div className="active-order__measurement-item__menu">
                        {!item.isFinished && (
                          <div className="active-order__measurement-badge">
                            Niedokończone
                          </div>
                        )}
                        <ContextMenu
                          className="dtb__user-submenu"
                          items={measurementMenu({
                            status: item.isFinished,
                            label: item.measurementLabel,
                            uuid: item.uuid,
                            lastFinishedPage: item.lastFinishedPage,
                          })}
                        >
                          <SVG type={SVG_TYPE.ELLIPSIS} />
                        </ContextMenu>
                      </div>
                    </div>
                  );
                })}
            </>
          )}
          <StaticAlert
            show={errorEndMeasurement.isError}
            label={errorEndMeasurement.errorMessage}
          />
        </div>
      </div>
    </>
  );
};

export default Measurements;
