/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { SVG } from "components";
import { SVG_TYPE, USER_ROLE } from "enums";
import { Calendar as CalendarComponent } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.scss";
import "moment/locale/pl";
import classNames from "classnames";
import { isMobile } from "utils";
import { useCalendarState, View } from "../utils";
import {
  MobileCalendarSheet,
  MobileWeekCalendar,
  CalendarPagePreview,
} from "components/Calendars";
import { useGetMe } from "hooks/auth";

moment.locale("pl");

interface CalendarType {
  setDate: (date: string) => void;
  size?: string;
  main?: boolean;
  date: string | undefined;
  events?: any;
  arrangingMeasurement?: (isOpen: boolean) => void;
  user?: any;
  assembly?: boolean;
}

const Calendar = ({
  setDate,
  date = moment().format("YYYY-MM-DD"),
  size = "100%",
  arrangingMeasurement,
  main = false,
  assembly = false,
  events,
  user,
}: CalendarType) => {
  const {
    view,
    setView,
    currentToolbar,
    setCurrentToolbar,
    activeDay,
    setActiveDay,
    minTime,
    maxTime,
    isMonthView,
    isDayView,
    isWeekView,
    dateSplit,
    timePart,
    formats,
    localizer,
    DayColumnWrapper,
  } = useCalendarState(date, setDate, main, arrangingMeasurement);

  const [isActiveBack, setIsActiveBack] = useState(false);
  const calendarClass = classNames("calendar", {
    "calendar-main": main,
    "calendar-arranging-measurement": arrangingMeasurement,
    "calendar-month": view === "month",
    "calendar-week": view === "week",
    "calendar-week__mobile": view === "week" && isMobile(),
    "calendar-week__mobile--arranging-measurement":
      view === "week" && isMobile() && arrangingMeasurement,
    "calendar-day": isDayView,
  });
  const { refetch } = useGetMe();

  useEffect(() => {
    if (view === "week") {
      document.querySelectorAll(".rbc-button-link span").forEach((button) => {
        const parts = button?.textContent?.split(" ");
        if (parts && parts.length > 1) {
          button.textContent = "";
          button.appendChild(document.createTextNode(parts[0] + " "));
          const span = document.createElement("span");
          span.textContent = parts[1];
          button.appendChild(span);
        }
      });
    }
  }, [view, currentToolbar]);

  useEffect(() => {
    setActiveDay(moment(dateSplit[0]).format("YYYY-MM-DD"));

    if (timePart) {
      const timeParts = timePart.split("-");
      if (timeParts.length === 2) {
        const [startTime, endTime] = timeParts;
        const startMoment = moment(
          `${dateSplit[0]} ${startTime}`,
          "YYYY-MM-DD HH:mm"
        );
        let endMoment = moment(
          `${dateSplit[0]} ${endTime}`,
          "YYYY-MM-DD HH:mm"
        );
        if (endTime.endsWith(":00")) {
          endMoment = endMoment.subtract(1, "hours");
        }

        const formatDateToId = (time: string): string =>
          `art-${moment(`${dateSplit[0]} ${time}`, "YYYY-MM-DD HH:mm").format(
            "DDMMYYYY-HH"
          )}`;

        const activateElement = (
          elementId: string,
          type: "first" | "middle" | "last" | "single"
        ): void => {
          const element = document.getElementById(elementId);
          if (element) {
            if (
              startMoment.format("HH") === endMoment.format("HH") &&
              !endTime.endsWith(":00") &&
              !startTime.endsWith(":00")
            ) {
              const startMinutes = parseInt(startMoment.format("mm"), 10);
              const endMinutes = parseInt(endMoment.format("mm"), 10);
              const minutesDifference = Math.abs(endMinutes - startMinutes);

              let classSuffix = "";
              if (minutesDifference === 15) {
                classSuffix = "quarter";
              } else if (minutesDifference === 30) {
                classSuffix = "half";
              } else if (minutesDifference === 45) {
                classSuffix = "three-quarters";
              }

              const sameHourClass = `rbc-timeslot-group--this-same-hour-top-${classSuffix}`;
              element.classList.add(sameHourClass);
            } else {
              if (type === "first") {
                const minutes = moment(startTime, "HH:mm").format("mm");
                let className = "rbc-timeslot-group--active-top";
                if (minutes === "15") {
                  className = "rbc-timeslot-group--active-top-quarter";
                } else if (minutes === "30") {
                  className = "rbc-timeslot-group--active-top-half";
                } else if (minutes === "45") {
                  className = "rbc-timeslot-group--active-top-three-quarters";
                }
                element.classList.add(className);
              } else if (type === "middle") {
                element.classList.add("rbc-timeslot-group--active-middle");
              } else if (type === "last") {
                const minutes = moment(endTime, "HH:mm").format("mm");
                let className = "rbc-timeslot-group--active-bottom";
                if (minutes === "15") {
                  className = "rbc-timeslot-group--active-bottom-quarter";
                } else if (minutes === "30") {
                  className = "rbc-timeslot-group--active-bottom-half";
                } else if (minutes === "45") {
                  className =
                    "rbc-timeslot-group--active-bottom-three-quarters";
                }
                element.classList.add(className);
              }
            }
          }
        };

        const elements: string[] = [];
        let currentMoment = moment(
          `${dateSplit[0]} ${startTime}`,
          "YYYY-MM-DD HH:mm"
        );

        while (currentMoment.add(1, "hours").isBefore(endMoment)) {
          elements.push(formatDateToId(currentMoment.format("HH:mm")));
        }
        elements.unshift(formatDateToId(startTime));
        elements.push(formatDateToId(endMoment.format("HH:mm")));

        elements.forEach((elementId, index) => {
          const type =
            elements.length === 1
              ? "single"
              : index === 0
              ? "first"
              : index === elements.length - 1
              ? "last"
              : "middle";

          setTimeout(() => {
            activateElement(elementId, type);
          }, 200);
        });
      }
    }
  }, [date, view]);

  const CustomToolbar = (toolbar: any) => {
    let dateFormatted;
    let day, month, year, dayName;

    const isMobileArrangingMeasurement = arrangingMeasurement && isMobile();
    const addOneDay = moment(dateSplit[0]).add(1, "day").format("YYYY-MM-DD");
    const addOneWeek = moment(dateSplit[0]).add(1, "week").format("YYYY-MM-DD");
    const addOneMonth = moment(dateSplit[0])
      .add(1, "month")
      .format("YYYY-MM-DD");
    const subtractOneDay = moment(dateSplit[0])
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    const subtractOneWeek = moment(dateSplit[0])
      .subtract(1, "week")
      .format("YYYY-MM-DD");
    const subtractOneMonth = moment(dateSplit[0])
      .subtract(1, "month")
      .format("YYYY-MM-DD");
    if (view === "day") {
      dateFormatted = moment(toolbar.date).format("D MMMM YYYY");
      [day, month, year] = dateFormatted.split(" ");
    } else {
      dateFormatted = moment(toolbar.date).format("MMMM YYYY");
      [month, year] = dateFormatted.split(" ");
    }

    const currentMoment = moment();
    const toolbarMoment = moment(toolbar.date);

    if (view === "month") {
      setIsActiveBack(
        !toolbarMoment.isSame(currentMoment, "month") &&
          !toolbarMoment.isBefore(currentMoment, "month")
      );
    } else if (view === "day") {
      dateFormatted = moment(toolbar.date).format("D MMMM YYYY");
      [day, month, year] = dateFormatted.split(" ");
      setIsActiveBack(toolbarMoment.isAfter(currentMoment, "day"));
      dayName = moment(toolbar.date).format("dddd");
      setActiveDay(moment(toolbar.date).format("YYYY-MM-DD"));
    } else {
      dateFormatted = moment(toolbar.date).format("MMMM YYYY");
      [month, year] = dateFormatted.split(" ");
      setIsActiveBack(toolbarMoment.isAfter(currentMoment, "week"));
      setActiveDay(moment(date.split(" ")[0]).format("YYYY-MM-DD"));
    }

    const handleViewChange = (newView: View) => {
      refetch();
      setView(newView);
      if (newView === "day" && activeDay) {
        const date = moment(activeDay, "YYYY-MM-DD").toDate();
        toolbar.onNavigate("DATE", date);
      } else {
        toolbar.onNavigate("DATE", activeDay);
        toolbar.onView(newView);
      }
    };

    const handleClickPrevNavigate = () => {
      toolbar.onNavigate("PREV");
      refetch();
      setCurrentToolbar(toolbar);
      if (setDate) {
        if (isMonthView) {
          if (timePart[1]) {
            setDate(`${subtractOneMonth} ${timePart}`);
          } else {
            setDate(subtractOneMonth);
          }
        } else if (isWeekView) {
          if (timePart[1]) {
            setDate(`${subtractOneWeek} ${timePart}`);
          } else {
            setDate(subtractOneWeek);
          }
        } else if (isDayView) {
          if (timePart[1]) {
            setDate(`${subtractOneDay} ${timePart}`);
          } else {
            setDate(subtractOneDay);
          }
        }
        // }
      }
    };

    const handleClickNextNavigate = () => {
      toolbar.onNavigate("NEXT");
      refetch();
      setCurrentToolbar(toolbar);
      if (setDate) {
        if (isMonthView) {
          if (timePart[1]) {
            setDate(`${addOneMonth} ${timePart}`);
          } else {
            setDate(addOneMonth);
          }
        } else if (isWeekView) {
          if (timePart[1]) {
            setDate(`${addOneWeek} ${timePart}`);
          } else {
            setDate(addOneWeek);
          }
        } else if (isDayView) {
          if (timePart[1]) {
            setDate(`${addOneDay} ${timePart}`);
          } else {
            setDate(addOneDay);
          }
        }
      }
    };

    useEffect(() => {
      const isLastDayOfMonth = () => {
        return moment(dateSplit[0]).isSame(
          moment(dateSplit[0]).endOf("month"),
          "day"
        );
      };
      const isLastDayOfWeek = () => {
        return moment(dateSplit[0]).day() === 0;
      };

      if (isLastDayOfMonth()) {
        toolbar.onNavigate("DATE", date.split(" ")[0]);
      }

      if (isWeekView && isLastDayOfWeek()) {
        return;
      }
      toolbar.onNavigate("DATE", date.split(" ")[0]);
    }, [date]);

    return (
      <div className="calendar-toolbar">
        <div className="calendar-toolbar__left">
          {!isMobile() && (
            <div className="calendar-toolbar__navigates">
              <div
                className={`calendar-toolbar__navigate-item ${
                  !isActiveBack && !main
                    ? "calendar-toolbar__navigate-item--disabled"
                    : ""
                }`}
                onClick={() => {
                  handleClickPrevNavigate();
                }}
              >
                <SVG
                  className="calendar-toolbar__navigate-item--left"
                  type={SVG_TYPE.CHEVRON_RIGHT}
                />
              </div>
              <div
                className="calendar-toolbar__navigate-item"
                onClick={() => {
                  handleClickNextNavigate();
                }}
              >
                <SVG type={SVG_TYPE.CHEVRON_RIGHT} />
              </div>
            </div>
          )}
          {(!isMobile() || (isMobile() && !isWeekView)) && (
            <div
              className={`calendar-toolbar__month-and-year ${
                day ? "calendar-toolbar__month-and-year--small" : ""
              }`}
            >
              {isMobile() && (
                <div
                  className={`calendar-toolbar__navigate-item ${
                    !isActiveBack && !main
                      ? "calendar-toolbar__navigate-item--disabled"
                      : ""
                  }`}
                  onClick={() => {
                    handleClickPrevNavigate();
                  }}
                >
                  <SVG
                    className="calendar-toolbar__navigate-item--left"
                    type={SVG_TYPE.CHEVRON_RIGHT}
                  />
                </div>
              )}
              <div className="calendar-toolbar__day-and-month">
                <span className="calendar-toolbar__month">
                  {isMobile() && view === "day" && `${dayName}, `}
                  {day ? `${day} ` : ""}
                  {month}
                </span>
                <span className="calendar-toolbar__year">{year}</span>
              </div>
              {isMobile() && (
                <div
                  className="calendar-toolbar__navigate-item"
                  onClick={() => {
                    handleClickNextNavigate();
                  }}
                >
                  <SVG type={SVG_TYPE.CHEVRON_RIGHT} />
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={`calendar-toolbar__center ${
            isMobileArrangingMeasurement
              ? "calendar-toolbar__center--arranging-measurement"
              : ""
          }`}
        >
          <div
            onClick={() => handleViewChange("month")}
            className={`calendar-toolbar__calendar-view ${
              view === "month" ? "calendar-toolbar__calendar-view--active" : ""
            }`}
          >
            Miesiąc
          </div>
          <div
            onClick={() => handleViewChange("week")}
            className={`calendar-toolbar__calendar-view ${
              view === "week" ? "calendar-toolbar__calendar-view--active" : ""
            }`}
          >
            Tydzień
          </div>
          <div
            onClick={() => handleViewChange("day")}
            className={`calendar-toolbar__calendar-view ${
              view === "day" ? "calendar-toolbar__calendar-view--active" : ""
            }`}
          >
            Dzień
          </div>
        </div>
        {arrangingMeasurement && <div className="calendar-toolbar__right" />}
        {isMobileArrangingMeasurement && (
          <div
            className="calendar-toolbar__add-appointment"
            onClick={() => arrangingMeasurement(true)}
          >
            <SVG type={SVG_TYPE.PLUS} />
          </div>
        )}
      </div>
    );
  };

  const handleDayClick = (day: string) => {
    if (dateSplit[1]) {
      setDate(`${day} ${dateSplit[1]}`);
    } else {
      setDate(`${day}`);
    }
  };

  const DateCellWrapper = ({ children, value }: any) => {
    const dayKey = moment(value).format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    const isActive = dayKey === activeDay;
    const isToday = dayKey === today;
    const isOldMonth = moment(value).month() < moment().month();
    const isBeforeToday = moment(value).isBefore(moment(), "day");
    const isOutOfViewMonth =
      moment(value).month() !== moment(dateSplit[0]).month() ||
      moment(value).year() !== moment(dateSplit[0]).year();

    const rbcDayClass = classNames("rbc-day-bg", {
      "rbc-day-bg--active": !assembly && !main && isActive,
      "rbc-day-bg__old-month": !main && isOldMonth,
      "rbc-day-bg__before-today": (isBeforeToday && !main) || isOutOfViewMonth,
    });

    const rbcNumberClass = classNames("rbc-day-bg__number", {
      "rbc-day-bg__number--today": isToday && !isMobile(),
      "rbc-day-bg__number--active":
        ((!main && isActive) || (isMobile() && isActive)) && !assembly,
      "rbc-day-bg__number--disabled":
        (isBeforeToday && !main && !arrangingMeasurement) ||
        (isOutOfViewMonth && !main && !arrangingMeasurement),
    });

    const rbcDayInsideClass = classNames("rbc-day-bg__inside", {
      "rbc-day-bg__inside--hover": !main || (main && isMobile()),
      "rbc-day-bg__inside--active": isActive,
      "rbc-day-bg__inside--active-main": isActive && main,
      // "rbc-day-bg__inside--hover": (!main || (main && isMobile())) && !assembly,
      // "rbc-day-bg__inside--active": isActive && !assembly,
    });

    const dayNumber = moment(value).format("D");

    const handleClick = () => {
      if (!isOutOfViewMonth) {
        handleDayClick(dayKey);
      }
    };
    return (
      <div className={`${rbcDayClass}`} onClick={() => handleClick()}>
        <span className={rbcNumberClass}>{dayNumber}</span>
        <div className={rbcDayInsideClass}>{children}</div>
      </div>
    );
  };

  const CustomEvent = ({ event, allEvents }: any) => {
    const duration = moment.duration(
      moment(event.end).diff(moment(event.start))
    );

    const isLessThanOneHour = duration.asHours() <= 1 && view === "day";
    const isBeforeToday = moment(event.end).isBefore(moment(), "day");
    // const eventsOnSameDay = allEvents.filter((e: any) =>
    //   moment(e.start).isSame(event.start, "day")
    // );

    // const moreThanTwoEvents = eventsOnSameDay.length > 2;
    const eventClass = classNames("rbc-event-content__item", {
      "rbc-event-content__item--disabled": isBeforeToday && !main,
      "rbc-event-content__item--one-line": isLessThanOneHour,
      "rbc-event--new-event": event.isNew,
    });

    const handleClickEvent = (e: any) => {
      if (isMonthView) {
        const activeRow = document.getElementsByClassName(
          "rbc-row-content--active"
        );
        if (activeRow[0]) {
          activeRow[0].classList.remove("rbc-row-content--active");
        }
        const month = e.target.closest(".rbc-month-row");
        const rowContent = month.getElementsByClassName("rbc-row-content");
        rowContent[0]?.classList.add("rbc-row-content--active");
      }
    };

    return (
      <>
        <div
          onClick={(e) => handleClickEvent(e)}
          id={`cpp-afera--${event.index}`}
          className={eventClass}
        >
          <strong>{event.title}</strong>
          {(!main || (main && isWeekView)) && (
            <p>
              {moment(event.start).format("HH:mm")} -{" "}
              {moment(event.end).format("HH:mm")}
            </p>
          )}
        </div>
        {main && !isMobile() && (
          <CalendarPagePreview user={user} date={date} event={event} />
        )}
      </>
    );
  };

  const eventStyleGetter = (event: any, date: any) => {
    const eventColor = event.original.userColor;
    const userRoleColor =
      user?.role === USER_ROLE.measurer
        ? "rgb(165, 236, 203)"
        : USER_ROLE.fitter
        ? "rgb(255, 204, 204)"
        : null;

    if (isWeekView) {
      const selectedDate: string = moment(date).format("DD/MM/YYYY");
      const filteredEvents: any[] = events.filter(
        (event: any) => event.original.date === selectedDate
      );
      const startDateTimes: string[] = filteredEvents.map((event: any) =>
        moment(event.start).format("DD/MM/YYYY HH:mm")
      );
      const uniqueStartDateTimes: string[] = Array.from(
        new Set(startDateTimes)
      );

      const uniqueStartHours: string[] = Array.from(
        new Set(
          filteredEvents.map((event: any) => moment(event.start).format("HH"))
        )
      );

      if (uniqueStartHours.length > 0) {
        const eventHour: string = moment(event.start).format("HH");
        const eventHourIndex: number = uniqueStartHours.indexOf(eventHour);
        const eventsWithSameStartDateTime: any[] = filteredEvents.filter(
          (event: any) =>
            moment(event.start).format("DD/MM/YYYY HH:mm") ===
            uniqueStartDateTimes[eventHourIndex]
        );
        const eventIndex: number = eventsWithSameStartDateTime.findIndex(
          (ev: any) => ev.index === event.index
        );

        return {
          className: `rbc-event--main rbc-event--main--${eventHourIndex}--${eventsWithSameStartDateTime.length} rbc-event--main--${eventHourIndex}--${eventsWithSameStartDateTime.length}--${eventIndex}`,
          style: {
            backgroundColor: eventColor || userRoleColor,
          },
        };
      }
    }

    const eventsOnSameDay = events.filter((e: any) =>
      moment(e.start).isSame(event.start, "day")
    );

    const isSecondElementArranging =
      arrangingMeasurement &&
      eventsOnSameDay.length === 2 &&
      eventsOnSameDay[1].start === event.start;

    function findElementIndex(arr: any, el: any) {
      return arr.findIndex((item: any) => item.index === el.index);
    }
    const index = findElementIndex(eventsOnSameDay, event);
    function calculateStyles() {
      const offset = index % 4;
      const marginLeft = `${isMobile() && offset * 8}px`;
      const setNumber = Math.floor(index / 4);
      const marginBottom = `${isMobile() && setNumber * -8}px`;

      return {
        marginLeft,
        marginBottom,
      };
    }
    const margins = calculateStyles();
    return {
      className:
        arrangingMeasurement && !isMobile()
          ? isSecondElementArranging
            ? "rbc-event--arranging-measurement rbc-event-content--second-element-arranging"
            : "rbc-event--arranging-measurement"
          : "rbc-event--main",
      style: {
        marginLeft: margins.marginLeft,
        marginBottom: margins.marginBottom,
        backgroundColor: eventColor || userRoleColor,
      },
    };
  };

  const messages = {
    showMore: (total: any) => `+ ${total} więcej`,
  };

  const handleViewChange = (newView: any) => {
    setView(newView);
  };

  return (
    <>
      <CalendarComponent
        className={calendarClass}
        localizer={localizer}
        events={events}
        onView={handleViewChange}
        startAccessor="start"
        endAccessor="end"
        style={{ height: isMobile() ? (main ? 650 : 500) : 820, width: size }}
        view={view}
        min={minTime}
        step={60}
        timeslots={1}
        max={maxTime}
        formats={formats}
        eventPropGetter={eventStyleGetter}
        components={{
          toolbar: CustomToolbar,
          dateCellWrapper: DateCellWrapper,
          event: (props) => <CustomEvent {...props} allEvents={events} />,
          dayColumnWrapper: DayColumnWrapper,
        }}
        messages={messages}
      />
      {isWeekView && isMobile() && (
        <MobileWeekCalendar
          isActiveBack={isActiveBack}
          main={main}
          arrangingMeasurement={arrangingMeasurement}
          calendarView={view}
          date={date}
          setDate={setDate}
        />
      )}
      <MobileCalendarSheet
        user={user}
        calendarView={view}
        arrangingMeasurement={arrangingMeasurement}
        date={date}
        events={events}
        setDate={setDate}
        main={main}
      />
    </>
  );
};

export default Calendar;
