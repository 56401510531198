/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import "./employees-time-stats.scss";
import { DateRangePicker, SkeletonRow, MultipleSelect } from "components";
import { useGetUsersTime, useGetUserTimes } from "hooks/dashboard";
import { useGetOrderLabels } from "hooks/orders";
import moment from "moment";
import "moment/locale/pl";
import classnames from "classnames";
import { EmployeeTimeByDate } from "features/statistics";
import { Field, Formik } from "formik";
import { UserWorkTimeType, DayTimeType } from "types/StatisticTypes";

const EmployeesTimeStats = () => {
  const [periodTime, setPeriodTime] = useState("currentMonth");
  const [selectedUser, setSelectedUser] = useState({ userId: 0, date: "" });
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const { data: orderLabels } = useGetOrderLabels({
    period: periodTime,
  });

  const period = periodTime;
  const {
    data: userTimes,
    isLoading,
    refetch,
  } = useGetUsersTime(period, selectedOrder);
  const tableData = userTimes?.length > 0 && userTimes;
  const { data: workTime } = useGetUserTimes(
    selectedUser.userId,
    selectedUser.date,
    selectedOrder?.length ? selectedOrder : "all"
  );

  const handleSetUser = (userId: any, date: string) => {
    setSelectedUser({ userId: userId, date: date });
  };

  const handleOnCloseEmployeeTime = () => {
    setSelectedUser({ userId: 0, date: "" });
  };
  const isOpenEmployeeTime = selectedUser.userId !== 0;

  const orderSelectOptions = orderLabels && [
    { label: "Wszystkie zlecenia", value: "all" },
    ...orderLabels,
  ];

  useEffect(() => {
    !selectedOrder &&
      orderSelectOptions &&
      setSelectedOrder([
        { value: orderSelectOptions && orderSelectOptions[0].value },
      ]);
  }, [orderSelectOptions]);

  useEffect(() => {
    refetch();
  }, [selectedOrder, periodTime]);

  return (
    <>
      <EmployeeTimeByDate
        times={workTime}
        isOpen={isOpenEmployeeTime}
        onClose={handleOnCloseEmployeeTime}
      />
      <div className="employees-time-stats">
        <div className="employees-time-stats__selects">
          <div className="employees-time-stats__jobs">
            <Formik
              initialValues={{
                orders: orderSelectOptions && orderSelectOptions[0].value,
              }}
              enableReinitialize
              onSubmit={() => console.log(null)}
            >
              <>
                {isLoading && (
                  <SkeletonRow className="skeleton" count={1} height={42} />
                )}
                <Field
                  name="orders"
                  onChange={(test: any) => setSelectedOrder(test)}
                  component={MultipleSelect}
                  options={orderSelectOptions}
                  size="medium"
                  selectAll
                  required
                  white
                  multiple
                />
              </>
            </Formik>
          </div>
          <DateRangePicker
            label="Obecny miesiąc"
            onChangeDate={setPeriodTime}
          />
        </div>
        {isLoading ? (
          <SkeletonRow count={12} height={58} />
        ) : (
          <div className="ets__table-wrapper">
            <div className="ets__table ets__table--employee-time">
              {tableData && (
                <>
                  <div className="ets__table-row">
                    <div className="ets__table-heading ets__table-heading--employee">
                      Pracownik
                    </div>
                    <div className="ets__table-heading ets__table-heading--time">
                      Czas
                    </div>
                  </div>
                  {tableData.map(
                    (
                      {
                        user: { firstName, lastName, userColor },
                        totalTime,
                      }: UserWorkTimeType,
                      index: number
                    ) => {
                      const isLastElement = index === tableData.length - 1;
                      return (
                        <div key={index} className="ets__table-row">
                          <div className="ets__table-body ets__table-body--employee">
                            <div
                              className={`user ${
                                isLastElement ? "totaled" : ""
                              }`}
                            >
                              <div
                                className="users-table__color user-color"
                                style={{ backgroundColor: userColor }}
                              ></div>
                              {`${firstName} ${lastName}`}
                            </div>
                          </div>

                          <div className="ets__table-body ets__table-body--time">
                            {totalTime}
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </div>

            <div className="ets__table ets__table--days">
              <div className="ets__table-row">
                <div className="ets__table-heading ets__table-heading--empty"></div>
                {tableData?.[0]?.days?.length > 0 &&
                  tableData[0].days.map(
                    ({ day, date }: DayTimeType, index: number) => {
                      const now = moment().format("DD/MM/YYYY");
                      const isToday = now === date;
                      const lastElement =
                        index === tableData[0].days.length - 1;
                      const dayNumber = day.toString().padStart(2, "0");
                      const dayName = moment(date, "DD/MM/YYYY").format("dd");
                      const isWeekend = dayName === "So" || dayName === "Nd";
                      const dayClass = classnames("ets__table-heading", {
                        "ets__table-heading--day": true,
                        "ets__table-body--today": isToday,
                        "ets__table-heading--weekend": isWeekend,
                        "ets__table-heading--last-element": lastElement,
                      });
                      return (
                        <div key={index} className={dayClass}>
                          <span className="day-number">{dayNumber}</span>
                          <span className="day-name">{dayName}</span>
                        </div>
                      );
                    }
                  )}
              </div>

              {tableData &&
                tableData.map(({ days }: UserWorkTimeType, index: number) => {
                  const isLastElement = index === tableData.length - 1;
                  return (
                    <div key={index} className="ets__table-row">
                      <div className="ets__table-body ets__table-body--empty"></div>
                      {days?.length > 0 &&
                        days.map(
                          (
                            { countedTime, date, day, userId }: DayTimeType,
                            dayIndex: number
                          ) => {
                            const dayName = moment(date, "DD/MM/YYYY").format(
                              "dd"
                            );
                            const now = moment().format("DD/MM/YYYY");
                            const isToday = now === date;
                            const isWeekend =
                              dayName === "So" || dayName === "Nd";
                            const isEnd = dayIndex === days.length - 1;
                            const dayClass = classnames("ets__table-body", {
                              "ets__table-body--day": true,
                              "ets__table-body--weekend": isWeekend,
                              "ets__table-body--today": isToday,
                              "ets__table-body--last-element":
                                isLastElement && dayIndex === 0,
                              "ets__table-body--last-elements": isLastElement,
                              "ets__table-body--ended": isEnd,
                            });
                            const hasTime = countedTime !== "00:00";

                            return (
                              <div key={dayIndex} className={dayClass}>
                                <span
                                  className={`day-number ${
                                    hasTime ? "day-number--with-time" : ""
                                  }`}
                                  onClick={() =>
                                    hasTime ? handleSetUser(userId, date) : null
                                  }
                                >
                                  {countedTime}
                                </span>
                              </div>
                            );
                          }
                        )}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeesTimeStats;
