import { Button } from "components";
import { SVG_TYPE } from "enums";
import "./items-left-at-client.scss";

const ItemsLeftAtClient = () => {
  const handleAddItem = () => {
    console.log("odpalaj dodawanie");
  };

  return (
    <div className="active-order__bar">
      <div className="active-order__bar-heading ">
        <div className="active-order__heading items-left-at-client__heading">
          Rzeczy pozostawione u klienta
          <span>(opcjonalne)</span>
        </div>
        <Button
          className="items-left-at-client__button"
          stroke
          svgType={SVG_TYPE.PLUS}
          size="medium"
          label={`Dodaj `}
          onClick={() => handleAddItem()}
        />
      </div>
    </div>
  );
};

export default ItemsLeftAtClient;
