import { Input, Select, Textarea } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
  AdditionalWarning,
} from "features/addingMeasurement";

interface SwingGateFormType {
  touched: any;
  errors: any;
  validateField: any;
  values: any;
  isErrorDimension: boolean;
  setFieldValue: any;
  setFieldError: any;
}

const SwingGateForm = ({
  touched,
  errors,
  validateField,
  values,
  isErrorDimension,
  setFieldValue,
  setFieldError,
}: SwingGateFormType) => {
  return (
    <>
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        className="padding-top-separator gate-size"
        touched={touched}
        name={["gateSizeWidth", "gateSizeHeight"]}
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="gateSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="gateSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <AdditionalWarning
          isErrorDimension={isErrorDimension}
          errors={errors}
          values={values}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="methodOfOpening"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          label="Sposób otwierania"
          name="methodOfOpening"
          type="number"
          options={[
            {
              value: "leftSideIsActive",
              label: "Lewe skrzydło czynne",
            },
            {
              value: "rightSideIsActive",
              label: "Prawe skrzydło czynne",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name={["gateDivision", "activeWing", "passiveWing"]}
        errors={errors}
        className="padding-top-separator single-section-element--strips gateDivision"
      >
        <Field
          label="Podział bramy"
          name="gateDivision"
          type="number"
          options={[
            {
              value: "symmetrical",
              label: "Symetryczny",
            },
            {
              value: "asymmetrical",
              label: "Niesymetryczny",
            },
          ]}
          component={Select}
          white
        />
        {values.gateDivision === "asymmetrical" && (
          <>
            <Field
              maxLength={30}
              label="Skrzydło czynne"
              name="activeWing"
              type="number"
              component={Input}
            />
            <Field
              maxLength={30}
              label="Skrzydło bierne"
              type="number"
              name="passiveWing"
              component={Input}
            />
          </>
        )}
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="gateType"
        errors={errors}
        className="padding-top-separator single-section-element--strips"
      >
        <Field
          label="Typ bramy"
          name="gateType"
          type="number"
          options={[
            {
              value: "insulated",
              label: "ocieplona",
            },
            {
              value: "uninsulated",
              label: "nieocieplona",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="color"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label={`Kolor`}
          name="color"
          optional
          component={Input}
        />
      </SingleSectionElement>
      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        optional
        name="comments"
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default SwingGateForm;
