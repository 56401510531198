/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Select, { components, ActionMeta, MultiValue } from 'react-select';
import { SVG } from 'components';
import { SVG_TYPE } from 'enums';
import { SelectOption } from 'types/SelectTypes';
import './multiple-select.scss';
import classNames from 'classnames';

interface MultipleSelectProps {
	label?: string;
	options?: SelectOption[];
	placeholder: string;
	name: string;
	checker?: boolean;
	filterOption?: any;
	field?: any;
	errors?: any;
	form?: any;
	touched?: any;
	onChange?: (data: any) => void;
	handleOnChange?: any;
	isOnBlurAction?: boolean;
	editUser?: () => void;
	onFocus?: any;
	disabled?: boolean;
	white?: boolean;
	selectAll?: boolean;
	employee?: boolean;
	size?: 'large' | 'small' | 'medium';
	value?: string;
}

const CustomDropdownIndicator = (props: any) => {
	const { selectProps } = props;
	return (
		<components.DropdownIndicator {...props}>
			{selectProps.menuIsOpen ? (
				<SVG type={SVG_TYPE.CHEVRON_TOP} />
			) : (
				<SVG type={SVG_TYPE.CHEVRON_DOWN} />
			)}
		</components.DropdownIndicator>
	);
};

const MultipleSelect = ({
	label,
	options,
	name,
	filterOption,
	placeholder,
	field,
	form,
	errors,
	onChange,
	disabled = false,
	white,
	selectAll = false,
	employee,
	size = 'large',
	value,
}: MultipleSelectProps) => {
	const [selectedOption, setSelectedOption] = useState<SelectOption[]>([]);
	const firstElementIsAllSelected =
		options && options.length > 0 && options[0].value === 'all';
	const isAllSelected = selectedOption.length === (options && options.length);
	const selectClass = classNames('select multiple-select', {
		'multiple-select--white': white,
		'multiple-select-disabled': disabled,
		'multiple-select--large': size === 'large',
		'multiple-select--medium': size === 'medium',
		'multiple-select--small': size === 'small',
		'multiple-select__only-first': firstElementIsAllSelected && isAllSelected,
	});

	const selectPrefixClass = classNames('multiple-select', {
		'multiple-select--large': size === 'large',
		'multiple-select--medium': size === 'medium',
		'multiple-select--small': size === 'small',
	});

	useEffect(() => {
		if (selectAll && options && options.length > 0 && field.value === 'all') {
			setSelectedOption(options);
			form.setFieldValue(field.name, options);
		}
	}, [options]);

	useEffect(() => {
		if (field.value && !selectAll) {
			const initialValues =
				options?.filter((option) => field.value.includes(option.value)) || [];
			setSelectedOption([...initialValues] as SelectOption[]);
		}
	}, [options, selectAll]);

	const handleChange = (
		newValue: MultiValue<SelectOption>,
		actionMeta?: ActionMeta<SelectOption>
	) => {
		let updatedValues = [...newValue];
		const firstOption = options && options[0];
		const clickedFirstOption = actionMeta?.option?.value === firstOption?.value;

		if (selectAll) {
			if (clickedFirstOption) {
				const allSelected = selectedOption.length !== options?.length;
				updatedValues = allSelected ? (options ? [...options] : []) : [];
			} else {
				const remainingOptions = options?.slice(1) || [];
				updatedValues = updatedValues.filter((item: any) => item.value !== 'all');
				const remainingSelected = remainingOptions.every((option) =>
					updatedValues.some((selected) => selected.value === option.value)
				);
				if (remainingOptions.length + 1 === field.value.length) {
					updatedValues = updatedValues.filter((item: any) => item.value !== 'all');
				}
				if (remainingSelected && firstOption) {
					updatedValues = [firstOption, ...remainingOptions];
				}
			}
		}

		if (selectAll && !isAllSelected) {
			setSelectedOption(
				selectedOption.filter((item: any) => item.value !== 'all')
			);
		}
		setSelectedOption([...updatedValues] as SelectOption[]);
		const valueToStore = updatedValues.map((option) => option.value);
		form.setFieldValue(field.name, valueToStore);
		onChange && onChange(updatedValues);
	};

	const NoOptionsMessage = () => {
		return <div>Nie znaleziono</div>;
	};

	const Option = (props: any) => {
		return (
			<components.Option {...props} className="multiple-select__item">
				<label>
					{props.data.value !== 'all' && employee && (
						<div
							style={{ backgroundColor: props.data.userColor }}
							className="multiple-select__item-color"
						/>
					)}
					{props.label}
				</label>
				<input type="checkbox" checked={props.isSelected} onChange={() => null} />
			</components.Option>
		);
	};

	const MultiValue = (props: any) => {
		return (
			<components.MultiValue {...props}>
				<span className="custom-multi-value">
					{props.data.value !== 'all' && employee && (
						<div
							style={{ backgroundColor: props.data.userColor }}
							className="multiple-select__multi-value__color"
						/>
					)}
					{props.children}
				</span>
			</components.MultiValue>
		);
	};

	return (
		<div className="multiple-select__wrapper">
			<div className="multiple-select__label">{label}</div>
			<Select
				value={selectedOption}
				name={name}
				components={{
					Option: Option,
					DropdownIndicator: CustomDropdownIndicator,
					MultiValue: MultiValue,
				}}
				onChange={handleChange}
				placeholder={placeholder ? placeholder : ''}
				options={options}
				isMulti={true}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				className={selectClass}
				classNamePrefix={selectPrefixClass}
				blurInputOnSelect={false}
				noOptionsMessage={() => <NoOptionsMessage />}
				filterOption={filterOption}
			/>
			{errors && form.touched && (
				<div className="multiple-select__error">
					{form.touched[field.name] && <span>{errors[field.name]}</span>}
				</div>
			)}
		</div>
	);
};

export default MultipleSelect;
