import "./edit-checklist.scss";
import React, { useRef } from "react";
import { PortalWithState } from "react-portal";
import { SVG_TYPE } from "enums";
import { Button, Input, Switch } from "components";
import { Field, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { VALIDATION_MESSAGES } from "enums";
import { useGetAdminChecklist, useUpdateCheckList } from "hooks/checklist";

interface EditChecklistType {
  isOpen: boolean;
  data: any;
  onClose: () => void;
}

const EditChecklist = ({ isOpen, data, onClose }: EditChecklistType) => {
  const { data: checklist } = useGetAdminChecklist(data.value, isOpen);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const mutation = useUpdateCheckList({
    onSuccess: () => {
      onClose();
    },
  });

  const validationSchema = Yup.object().shape({
    checkLists: Yup.array()
      .of(
        Yup.object().shape({
          text: Yup.string()
            .required(VALIDATION_MESSAGES.REQUIRED)
            .min(3, VALIDATION_MESSAGES.MIN3)
            .matches(
              /^\S.*\S$/,
              VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
            ),
        })
      )
      .required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleAddItem = (arrayHelpers: any) => {
    arrayHelpers.push({
      text: "",
      required: 1,
    });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <PortalWithState
        closeOnEsc={false}
        closeOnOutsideClick={false}
        defaultOpen={true}
      >
        {({ portal }) => (
          <>
            {portal(
              <>
                {isOpen && (
                  <div className="full-page am">
                    <div className="am__content">
                      <div className="am-top">
                        <Button
                          label="Zamknij"
                          stroke
                          size="medium"
                          svgType={SVG_TYPE.CLOSE}
                          onClick={() => onClose()}
                        />
                      </div>
                      <div className="edit-checklist">
                        <div className="edit-checklist__heading">
                          Edycja checklisty
                        </div>
                        <div className="edit-checklist__sub-heading">
                          {data.label}
                        </div>

                        <Formik
                          initialValues={{
                            checkLists: checklist || [],
                            typeOfMeasurementUuid: data.value,
                          }}
                          validationSchema={validationSchema}
                          enableReinitialize
                          onSubmit={async (values) => mutation.mutate(values)}
                        >
                          {({ handleSubmit, errors, values }) => {
                            return (
                              <form
                                className="edit-checklist__form form"
                                onSubmit={handleSubmit}
                              >
                                <FieldArray
                                  name="checkLists"
                                  render={(arrayHelpers) => (
                                    <div className="mal__form-activities">
                                      {values?.checkLists.map(
                                        (_: any, index: any) => {
                                          return (
                                            <div
                                              key={`checkListItem_${index + 1}`}
                                              className="edit-checklist__form-item"
                                            >
                                              <Field
                                                errors={errors}
                                                name={`checkLists.${index}.text`}
                                                component={Input}
                                                binNumber={0}
                                                bin
                                                secondary
                                                inputRef={inputRef}
                                              />
                                              <Field
                                                checked={_.required}
                                                string
                                                first="Wymagane"
                                                second="opcjonalne"
                                                name={`checkLists.${index}.required`}
                                                component={Switch}
                                                className="switch__wrapper--form"
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                      <div className="edit-checklist__form-add-item">
                                        <Button
                                          stroke
                                          label="Dodaj kolejny punkt"
                                          size="small"
                                          onClick={() =>
                                            handleAddItem(arrayHelpers)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                />
                                <div className="edit-checklist__buttons">
                                  <Button
                                    stroke
                                    label="Anuluj"
                                    onClick={() => onClose()}
                                  />
                                  <Button
                                    label="Zapisz zmiany"
                                    type="submit"
                                    onClick={handleSubmit}
                                  />
                                </div>
                              </form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </PortalWithState>
    </>
  );
};

export default EditChecklist;
