import { Sketch, StaticAlert } from "components";
import "./drawing.scss";

interface DrawingType {
  field: any;
  errors?: any;
  form: any;
  label: string;
}

const Drawing = ({ errors, form, field, label }: DrawingType) => {
  return (
    <div className="drawing">
      <Sketch form={form} label={label} />

      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      {/* <Button onClick={() => handleSaveClick()} label="zapisujemy" /> */}
      <div className="am-separator" />
    </div>
  );
};

export default Drawing;
