import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { MetaType } from "types/UtilsTypes";
import { OrderListType } from "types/OrderTypes";
import { ScheduleOrderStatus } from "types/OrderTypes";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));


export default function useGetScheduledOrders(
  status: ScheduleOrderStatus,
  page: number = 1,
  options = {}
) {
  return useQuery({
    queryKey: ["scheduledOrders", status, page],
    queryFn: async () => {
      await delay(1000); 
      return axiosInstance
        // .get(`/orders/schedule-manager/list?status=${status}&page=${page}`)
        .get(`/orders/schedule-manager/list?${status ? `status=${status}&` : ""}page=${page}`)

        .then((res) => res.data);
    },
    select: (data: { orders: { data: OrderListType[], meta: MetaType }}) => data?.orders,
    retry: false,
    enabled: status && status.length > 0 && status !== null,
    ...options,
    staleTime: 0, 
  });
}