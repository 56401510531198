import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type DateType = string;
type OrdersType = any;

export default function useGetUserTimes(userId: number, date: DateType, orders?: OrdersType) {
  return useQuery({
    queryKey: ["user-times", userId, date, orders],
    queryFn: async () => {
      if (!userId || !date) {
        throw new Error("userId i date są wymagane");
      }

      const params: Record<string, any> = { date };
      if (orders) {
        if (Array.isArray(orders)) {
          const orderValues = orders.map((order) => (typeof order === "object" ? order.value : order)).join(',');
          params.orders = orderValues;
        } else {
          params.orders = typeof orders === "object" ? orders.value : orders;
        }
      }
      const response = await axiosInstance.get(`/user-times/${userId}`, { params });
      return response.data;
    },
    select: (data: { data: any }) => data?.data,
    enabled: Boolean(userId) && Boolean(date),
    retry: false,
    staleTime: 0,
  });
}
