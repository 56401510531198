import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type DateType = string;

export default function useGetOrderTimes(orderId: number, date: DateType) {
  return useQuery({
    queryKey: ["order-times", orderId, date],
    queryFn: async () => {
      if (!orderId || !date) {
        throw new Error("orderId i date są wymagane");
      }

      const params: Record<string, any> = { date };

      const response = await axiosInstance.get(`/order-times/${orderId}`, { params });
      return response.data;
    },
    select: (data: { data: any }) => data?.data,
    enabled: Boolean(orderId) && Boolean(date),
    retry: false,
    staleTime: 0,
  });
}
