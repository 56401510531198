import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

export type RawConditionType = {
  type: string;
  label: string;
  value: string;
};

export default function useGetRawConditions(options = {}) {
  return useQuery({
    queryKey: ["rawConditions"],
    queryFn: async () => {
      return axiosInstance
        .get("/raw-condition")
        .then((res) => res.data);
    },
    select: (data: { rawCondition	: RawConditionType[] }) => data.rawCondition,
    retry: false,
    ...options,
  });
}
