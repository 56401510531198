import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonRow = ({
	width = '100%',
	height = 20,
	count = 1,
	className = '',
}) => {
	return (
		<>
			{Array(count)
				.fill(0)
				.map((_, index) => (
					<div className={className} key={index} style={{ margin: '10px 0' }}>
						<Skeleton width={width} height={height} />
					</div>
				))}
		</>
	);
};

export default SkeletonRow;
