import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { SingleSectionElement } from "features/addingMeasurement";
import { Button, Input, Textarea, Switch, Select } from "components";
import { VALIDATION_MESSAGES } from "enums";
import { usePutWindowSillsInstallationInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";

interface WindowSillsInstallationInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const WindowSillsInstallationInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: WindowSillsInstallationInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialMeasurementInstallationInfo =
    measurementInfo?.measurementInstallationInfo;

  const { mutate: editInstallationInfo } = usePutWindowSillsInstallationInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "3");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  const validationSchema = Yup.object().shape({
    typeOfInstallation: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = (values: any) => {
    editInstallationInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          numberOfAssemblyTeams:
            initialMeasurementInstallationInfo?.numberOfAssemblyTeams || "",
          plannedAssemblyTime:
            initialMeasurementInstallationInfo?.plannedAssemblyTime || "",
          comments: initialMeasurementInstallationInfo?.comments || "",
          plasterType: initialMeasurementInstallationInfo?.plasterType || "",
          plasterProcessing:
            initialMeasurementInstallationInfo?.plasterProcessing || "",
          oldWindowDismantlingInvestor:
            initialMeasurementInstallationInfo?.oldWindowDismantlingInvestor ||
            false,
          typeOfInstallation:
            initialMeasurementInstallationInfo?.typeOfInstallation || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <SingleSectionElement
                touched={touched}
                name="typeOfInstallation"
                errors={errors}
                className="static-alert-top"
              >
                <Field
                  label="Rodzaj montażu"
                  name="typeOfInstallation"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "thumbAssembly", label: "Z kuciem" },
                    { value: "assemblyWithoutThumb", label: "Bez kucia" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>

              <SingleSectionElement
                touched={touched}
                name="oldWindowDismantlingInvestor"
                errors={errors}
              >
                <Field
                  label={`Demontaż starych parapetów`}
                  name="oldWindowDismantlingInvestor"
                  checked={values.oldWindowDismantlingInvestor}
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="plasterProcessing"
                errors={errors}
              >
                <Field
                  checked={values.plasterProcessing}
                  label={`Obróbka tynkarska`}
                  name="plasterProcessing"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="plasterType"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Typ tynku"
                  optional
                  name="plasterType"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "wallFullFinishied", label: "Ściany na gotowo" },
                    { value: "gypsumPlaster", label: "Tynk gipsowy" },
                    { value: "traditionalPlaster", label: "Tynk tradycyjny" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>

              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="plannedAssemblyTime"
                errors={errors}
              >
                <Field
                  label="Planowany czas montażu (w godzinach)"
                  name="plannedAssemblyTime"
                  maxLength={6}
                  type="number"
                  rightPlaceholder="h"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="numberOfAssemblyTeams"
                errors={errors}
              >
                <Field
                  label="Ilość osób potrzebnych do montażu"
                  name="numberOfAssemblyTeams"
                  maxLength={3}
                  type="number"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WindowSillsInstallationInformation;
