import { useState, useEffect } from "react";
import {
  SVG,
  Select,
  CalendarInput,
  MultipleSelect,
  Button,
  Modal,
} from "components";
import { SVG_TYPE } from "enums";
import { measurementAllInstallationType } from "types/OrderTypes";
import { MEASUREMENTS_TYPE_TRANSLATIONS } from "enums";
import { useGetFitterAndMeasurer } from "hooks/users";
import { Field } from "formik";
import { isMobile } from "utils";
import "./installation-planing.scss";
import { useFormikContext } from "formik";
function generateTimeOptions() {
  const timeOptions = [];
  for (let minutes = 6 * 60; minutes <= 22 * 60; minutes += 15) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const label = `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
    timeOptions.push({ value: label, label });
  }

  return timeOptions;
}

interface InstallationPlanningProps {
  details: measurementAllInstallationType;
  errors: any;
  initialValues: any;
  setInitialValues: any;
  installationIndex: number;
  isEdit: boolean;
  validateField: any;
}

const InstallationPlanning = ({
  details,
  errors,
  initialValues,
  setInitialValues,
  installationIndex,
  isEdit,
  validateField,
}: InstallationPlanningProps) => {
  const { setErrors } = useFormikContext();
  const currentDate = new Date();
  const [isPlanningOpen, setIsPlanningOpen] = useState(false);
  const [modalManage, setModalManage] = useState<any>({
    isOpen: "",
    modal: "",
    data: { whichOne: 0 },
  });
  const isDeadlineModal = modalManage.modal === "deadline";
  const { data: fitter } = useGetFitterAndMeasurer(true);
  const newDates = {
    date: "",
    team: [{ employeeUuids: [], start: "", end: "" }],
  };

  useEffect(() => {
    if (isEdit) {
      setIsPlanningOpen(true);
    }
  }, [isEdit]);

  const handlePlanningOpen = () => {
    const oldErrors = errors;
    setIsPlanningOpen(!isPlanningOpen);
    const newInstallations = [...initialValues.installations];
    if (installationIndex >= newInstallations.length) {
      for (let i = newInstallations.length; i <= installationIndex; i++) {
        newInstallations.push({ measurementName: "", dates: [] });
      }
    }
    newInstallations[installationIndex] = {
      measurementName: details.typeOfMeasurement,
      dates: [newDates],
    };
    setInitialValues({
      installations: newInstallations,
    });
    setTimeout(() => {
      setErrors(oldErrors);
    }, 10);
  };

  const handleAddTimeSlotter = (whichOne: number) => {
    const oldErrors = errors;
    const updatedInstallations = initialValues.installations.map(
      (installation: any) => {
        if (installation.measurementName === details.typeOfMeasurement) {
          return {
            ...installation,
            dates: installation.dates.map((date: any, index: number) => {
              if (index === whichOne) {
                return {
                  ...date,
                  team: [
                    ...date.team,
                    { employeeUuids: [], start: "", end: "" },
                  ],
                };
              }
              return date;
            }),
          };
        }
        return installation;
      }
    );

    setInitialValues({ installations: updatedInstallations });
    setTimeout(() => {
      setErrors(oldErrors);
    }, 10);
  };

  const usersSelect = fitter && [...fitter];

  const initialMeasurement = initialValues.installations.find(
    ({ measurementName }: any) => measurementName === details.typeOfMeasurement
  );

  const handleAddDates = () => {
    const oldErrors = errors;
    const newInitial = {
      ...initialMeasurement,
      dates: [...initialMeasurement.dates, newDates],
    };
    const newInitialValues = initialValues.installations.map((item: any) => {
      if (item.measurementName === details.typeOfMeasurement) {
        return newInitial;
      }
      return item;
    });
    setInitialValues({ installations: [...newInitialValues] });
    setTimeout(() => {
      setErrors(oldErrors);
    }, 10);
  };

  const handleDeleteInstallation = (
    whichOne: number,
    isSlotter: boolean = false
  ) => {
    const oldErrors = errors;

    if (isSlotter) {
      const updatedInstallations = initialValues.installations.map(
        (installation: any) => {
          if (installation.measurementName === details.typeOfMeasurement) {
            return {
              ...installation,
              dates: installation.dates.map((date: any, dateIndex: number) => {
                if (dateIndex === modalManage.data.whichOne) {
                  return {
                    ...date,
                    team: date.team.filter(
                      (_: any, teamIndex: number) =>
                        teamIndex !== modalManage.data.whichOneTeam
                    ),
                  };
                }
                return date;
              }),
            };
          }
          return installation;
        }
      );

      setInitialValues({ installations: updatedInstallations });
    } else {
      const newInitialMeasurement = initialMeasurement.dates.filter(
        (_: any, index: number) => index !== whichOne
      );

      const newInitial = {
        ...initialMeasurement,
        dates: newInitialMeasurement.length > 0 ? newInitialMeasurement : [{}],
      };

      let newInitialValues = initialValues.installations.map((item: any) => {
        if (item.measurementName === details.typeOfMeasurement) {
          return newInitial;
        }
        return item;
      });

      if (newInitialMeasurement.length === 0) {
        newInitialValues = initialValues.installations.map((item: any) => {
          if (item.measurementName === details.typeOfMeasurement) {
            return { measurementName: "", dates: [] };
          }
          return item;
        });
        setIsPlanningOpen(false);
      }

      setInitialValues({ installations: [...newInitialValues] });
    }

    setModalManage({ isOpen: false });
    setTimeout(() => {
      setErrors(oldErrors);
    }, 10);
  };

  function getRandomKey() {
    return Math.random().toString(36).substr(2, 9);
  }

  return (
    <>
      <Modal
        className="delete-activity"
        isOpened={modalManage.isOpen}
        onClose={() => setModalManage({ isOpen: false })}
      >
        <>
          <div className="modal-heading">
            Usuwanie {isDeadlineModal ? "terminu" : "przedziału czasowego"}
          </div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć{" "}
            {isDeadlineModal ? "termin?" : "przedział czasowy?"}{" "}
            {isDeadlineModal ? (
              <span>{modalManage?.data?.date}</span>
            ) : (
              <>
                <span>
                  {modalManage?.data?.date
                    ? `${modalManage?.data?.timeSlotter} ${
                        modalManage?.data?.date
                          ? `z dnia ${modalManage?.data?.date}`
                          : ""
                      }`
                    : modalManage?.data?.timeSlotter}
                </span>
              </>
            )}
          </div>
          <div className="modal-buttons">
            <Button
              stroke
              onClick={() => setModalManage({ isOpen: false })}
              label="Anuluj"
            />
            <Button
              onClick={() =>
                handleDeleteInstallation(
                  modalManage.data.whichOne,
                  modalManage.modal === "slotter"
                )
              }
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
      <div className="installation-planning">
        <div className="installation-planning__top">
          <div className="installation-planning__left">
            <div className="installation-planning__label">
              {MEASUREMENTS_TYPE_TRANSLATIONS[details.typeOfMeasurement]}
            </div>
            <div className="installation-planning__numbers">
              <div className="installation-planning__number-item">
                <SVG type={SVG_TYPE.CLOCK} /> {details.plannedAssemblyTime}h
              </div>
              <div className="installation-planning__number-item">
                <SVG type={SVG_TYPE.USER} /> {details.numberOfAssemblyTeams}
              </div>
            </div>
          </div>
          <div className="installation-planning__right">
            {!isPlanningOpen && (
              <div
                onClick={() => handlePlanningOpen()}
                className="installation-planning__button"
              >
                <SVG type={SVG_TYPE.PLUS} />
              </div>
            )}
          </div>
        </div>
        {isPlanningOpen && (
          <div className="installation-planning__bottom">
            <div className="installation-planning__separator" />
            {initialMeasurement?.dates?.map((item: any, index: number) => {
              const isLastElement =
                initialMeasurement?.dates?.length === index + 1;
              const randomKey = getRandomKey();
              return (
                <>
                  <Field
                    key={randomKey}
                    errors={errors}
                    label="Termin"
                    size="medium"
                    name={`installations[${installationIndex}].dates[${index}].date`}
                    component={CalendarInput}
                    minDate={currentDate}
                    onChange={() => {
                      setTimeout(() => {
                        validateField(
                          `installations[${installationIndex}].dates[${index}].date`
                        );
                      }, 10);
                    }}
                  />
                  {errors?.installations?.[installationIndex]?.dates?.[index]
                    ?.date && (
                    <div className="error">To pole jest wymagane</div>
                  )}
                  {item.team.map((team: any, ind: number) => {
                    const isStartError =
                      errors?.installations?.[installationIndex]?.dates?.[index]
                        ?.team?.[ind]?.start;
                    const isEndError =
                      errors?.installations?.[installationIndex]?.dates?.[index]
                        ?.team?.[ind]?.end;
                    const isTimeError = isStartError || isEndError;
                    const isEmployeeError =
                      errors?.installations?.[installationIndex]?.dates?.[index]
                        ?.team?.[ind]?.employeeUuids;
                    const nonFirstElement = ind !== 0;
                    const moreThanOne = item.team.length > 1;
                    return (
                      <div
                        className={`installation-planning__team ${
                          nonFirstElement
                            ? "installation-planning__team--non-first-element"
                            : ""
                        }`}
                      >
                        <div
                          className={`amp__hours ${
                            isTimeError ? "amp__hours--error" : ""
                          }`}
                        >
                          <Field
                            label="Początek"
                            name={`installations[${installationIndex}].dates[${index}].team[${ind}].start`}
                            required
                            component={Select}
                            disableMobileKeyboard
                            menuPlacement={isMobile() ? "top" : "bottom"}
                            errors={errors}
                            size="medium"
                            options={generateTimeOptions()}
                            onChange={() => {
                              setTimeout(() => {
                                validateField(
                                  `installations[${installationIndex}].dates[${index}].team[${ind}].start`
                                );
                              }, 10);
                            }}
                          />
                          {isStartError && (
                            <div className="error">Wymagane</div>
                          )}
                          <div className="amp__hours-separate">-</div>
                          <Field
                            label="Koniec"
                            name={`installations[${installationIndex}].dates[${index}].team[${ind}].end`}
                            disableMobileKeyboard
                            menuPlacement={isMobile() ? "top" : "bottom"}
                            required
                            size="medium"
                            component={Select}
                            errors={errors}
                            options={generateTimeOptions()}
                            onChange={() => {
                              setTimeout(() => {
                                validateField(
                                  `installations[${installationIndex}].dates[${index}].team[${ind}].end`
                                );
                              }, 10);
                            }}
                          />
                          {isEndError && (
                            <div className="error error--end">Wymagane</div>
                          )}
                        </div>
                        <Field
                          label="Pracownik/cy"
                          name={`installations[${installationIndex}].dates[${index}].team[${ind}].employeeUuids`}
                          component={MultipleSelect}
                          errors={errors}
                          options={usersSelect}
                          size="medium"
                          employee
                          required
                          multiple
                          onChange={() => {
                            setTimeout(() => {
                              validateField(
                                `installations[${installationIndex}].dates[${index}].team[${ind}].employeeUuids`
                              );
                            }, 10);
                          }}
                        />
                        {isEmployeeError && (
                          <div className="error">To pole jest wymagane</div>
                        )}
                        {moreThanOne && (
                          <div
                            className="installation-planning__delete-wrapper installation-planning__delete-wrapper--time-slotter "
                            onClick={() =>
                              setModalManage({
                                isOpen: true,
                                modal: "slotter",
                                data: {
                                  whichOne: index,
                                  whichOneTeam: ind,
                                  date: initialMeasurement.dates[index].date,
                                  timeSlotter: `${initialMeasurement.dates[index].team[ind].start} - ${initialMeasurement.dates[index].team[ind].end}`,
                                },
                              })
                            }
                          >
                            <SVG
                              className="installation-planning__delete-icon"
                              type={SVG_TYPE.BIN}
                            />
                            <div className="installation-planning__delete-label">
                              Usuń ten przedział czasowy
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div className="installation-planning__actions">
                    <div
                      className="installation-planning__time-slotter"
                      onClick={() => handleAddTimeSlotter(index)}
                    >
                      <div className="installation-planning__time-slotter-label">
                        + Dodaj kolejny przedział czasowy
                      </div>
                    </div>
                    <div
                      className="installation-planning__delete-wrapper"
                      onClick={() =>
                        setModalManage({
                          isOpen: true,
                          modal: "deadline",
                          data: {
                            whichOne: index,
                            date: initialMeasurement.dates[index].date,
                          },
                        })
                      }
                    >
                      <SVG
                        className="installation-planning__delete-icon"
                        type={SVG_TYPE.BIN}
                      />
                      <div className="installation-planning__delete-label">
                        Usuń ten termin
                      </div>
                    </div>
                    {isLastElement ? (
                      <Button
                        onClick={handleAddDates}
                        className="installation-planning__new-date"
                        stroke
                        size="small"
                        label="Dodaj kolejny dzień"
                      />
                    ) : (
                      <>
                        <div className="am-separator installation-planning__separator installation-planning__separator--time-slotter" />
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default InstallationPlanning;
