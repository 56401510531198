/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Field, Formik } from "formik";
import { Input, Button, Switch, Textarea } from "components";
import { SingleSectionElement, Drawing } from "features/addingMeasurement";
import { ScrollToError } from "utils";
import * as Yup from "yup";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import { DoorSills, MeasurementArea } from "features/addingMeasurement/Form";
import { usePutFloorInfo, useStartMeasurement } from "hooks/measurements";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useUploadFile } from "hooks/utils";
import { handleMeasurementSuccess } from "features/addingMeasurement/utils";

interface FloorMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const FloorMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: FloorMeasurementValuesType) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialMeasurementInfo = measurementInfo?.measurementInfo;
  const orderUuid = searchParams.get("orderUuid");
  const [formDraw, setFormDraw] = useState<any>();
  const [isAddNew, setIsAddNew] = useState(false);

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}`);
  };

  const initialValues = {
    measurementUuid: measurementUuid,
    directionOfLayingThePanels:
      initialMeasurementInfo?.directionOfLayingThePanels || "",
    conditionOfTheSpout: initialMeasurementInfo?.conditionOfTheSpout || "",
    conditionOfTheSpoutWork:
      initialMeasurementInfo?.conditionOfTheSpoutWork || "investor",
    conditionOfTheSpoutComments:
      initialMeasurementInfo?.conditionOfTheSpoutComments || "",
    floorHeating: initialMeasurementInfo?.floorHeating || false,
    connectingPanelsToAnotherFloor:
      initialMeasurementInfo?.connectingPanelsToAnotherFloor || false,
    differentFloorLevel: initialMeasurementInfo?.differentFloorLevel || "",
    typeOfStrips: initialMeasurementInfo?.typeOfStrips || "",
    stripColor: initialMeasurementInfo?.stripColor || "",
    roomName: initialMeasurementInfo?.roomName || "",
    reserve: initialMeasurementInfo?.reserve || "",
    resignationFromExpansion:
      initialMeasurementInfo?.resignationFromExpansion || "",
    file: initialMeasurementInfo?.file || [],
    skirtingBoards: initialMeasurementInfo?.skirtingBoards || [],
    floorSpace: initialMeasurementInfo?.floorSpace || [],
    floorSpaceWithReserve: initialMeasurementInfo?.floorSpace || "",
    floorSpaceToBeTotaled: initialMeasurementInfo?.floorSpaceToBeTotaled || [
      "",
      "",
    ],
    wallStrips: initialMeasurementInfo?.wallStrips || ["", ""],
    wallStripsSpace: initialMeasurementInfo?.wallStripsSpace || [],
    wallStripsReserve: initialMeasurementInfo?.wallStripsReserve || "",
    comments: initialMeasurementInfo?.comments || "",
    internalCorner: initialMeasurementInfo?.internalCorner || "",
    externalCorner: initialMeasurementInfo?.externalCorner || "",
    connector: initialMeasurementInfo?.connector || "",
    rightEnding: initialMeasurementInfo?.rightEnding || "",
    leftEnding: initialMeasurementInfo?.leftEnding || "",
  };

  const [isValidated, setIsValidated] = useState(false);

  const validationSchema = Yup.object().shape({
    drawing: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    wallStripsReserve: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    reserve: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    directionOfLayingThePanels: Yup.string().required(
      VALIDATION_MESSAGES.REQUIRED
    ),
    conditionOfTheSpout: Yup.string().test(
      "is-required-when-toImprove",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { conditionOfTheSpoutComments } = this.parent;
        if (value === "toImprove" && !conditionOfTheSpoutComments) {
          return false;
        }
        return true;
      }
    ),
    connectingPanelsToAnotherFloor: Yup.string().test(
      "is-required-when-toImprove",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { differentFloorLevel } = this.parent;
        if (value === "true" && !differentFloorLevel) {
          return false;
        }
        return true;
      }
    ),
    typeOfStrips: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    skirtingBoards: Yup.array()
      .of(
        Yup.object()
          .shape({
            type: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
            color: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
            length: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
            amount: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
          })
          .test(
            "all-or-none",
            "Wszystkie pola muszą być wypełnione lub pozostawione puste",
            function (value) {
              const { type, color, length, amount } = value || {};
              const isAnyFilled = !!type || !!color || !!length || !!amount;
              const isAllFilled = !!type && !!color && !!length && !!amount;
              return !isAnyFilled || isAllFilled;
            }
          )
      )
      .nullable(),
    wallStrips: Yup.array()
      .of(Yup.string().trim().min(1, VALIDATION_MESSAGES.REQUIRED))
      .compact()
      .test(
        "at-least-2-elements",
        VALIDATION_MESSAGES.REQUIRED,
        function (value) {
          if (!value) return false;
          return value.length >= 2;
        }
      )
      .required(VALIDATION_MESSAGES.REQUIRED),
    floorSpaceToBeTotaled: Yup.array()
      .of(Yup.string().trim().min(1, VALIDATION_MESSAGES.REQUIRED))
      .compact()
      .test(
        "at-least-2elements",
        VALIDATION_MESSAGES.REQUIRED,
        function (value) {
          if (!value) return false;
          return value.length >= 2;
        }
      )
      .required(VALIDATION_MESSAGES.REQUIRED),
  });

  const { mutate: editMeasurementInfo } = usePutFloorInfo({
    onSuccess: (data) => {
      if (formDraw !== "oldDraw") {
        uploadFileMutation.mutate({
          parentUuid: data.measurement.measurementInfo.uuid,
          type: "drawingOfWindowDivision",
          files: [formDraw],
        });
      } else {
        goToList();
      }
    },
  });

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      handleMeasurementSuccess(data, searchParams, setSearchParams, setStep);
    },
  });

  const uploadFileMutation = useUploadFile({
    onSuccess: (data) => {
      sessionStorage.removeItem("canvasState");
      if (isAddNew) {
        setIsAddNew(false);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      } else {
        goToList();
      }
    },
  });

  const handleOnSubmit = async (values: any) => {
    setFormDraw(values.drawing);
    editMeasurementInfo(values);
    if (isAddNew) {
      startMeasurement.mutate({
        typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
        orderUuid,
      });
    }
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <SingleSectionElement
                className="padding-bottom-separator"
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                label="Rysunek pomieszczenia"
                name="drawing"
                component={Drawing}
              />
              <SingleSectionElement
                touched={touched}
                name="directionOfLayingThePanels"
                errors={errors}
              >
                <Field
                  label="Kierunek układania paneli"
                  name="directionOfLayingThePanels"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name={["conditionOfTheSpout", "conditionOfTheSpoutComments"]}
                errors={errors}
              >
                <Field
                  checked={values.conditionOfTheSpout}
                  first="W normie"
                  second="Do poprawy"
                  stringMode
                  label="Stan wylewki"
                  name="conditionOfTheSpout"
                  valueFirst="normal"
                  valueSecond="toImprove"
                  component={Switch}
                />
                {values.conditionOfTheSpout === "toImprove" && (
                  <>
                    <Field
                      checked={values.conditionOfTheSpoutWork === "investor"}
                      label={`Kto ma przygotować otwór?`}
                      name="conditionOfTheSpoutWork"
                      first="Inwestor"
                      second="MartDom"
                      stringMode
                      valueFirst="investor"
                      valueSecond="martdom"
                      component={Switch}
                    />
                    <Field
                      className="am-margin-top"
                      errors={errors}
                      label="Dodatkowe uwagi"
                      name="conditionOfTheSpoutComments"
                      component={Textarea}
                      maxLength={250}
                    />
                  </>
                )}
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="floorHeating"
                errors={errors}
              >
                <Field
                  checked={values.floorHeating}
                  label="Ogrzewanie podłogowe"
                  name="floorHeating"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name={["connectingPanelsToAnotherFloor", "differentFloorLevel"]}
                errors={errors}
              >
                <Field
                  checked={values.connectingPanelsToAnotherFloor}
                  label="Łączenie paneli z inną podłogą"
                  name="connectingPanelsToAnotherFloor"
                  component={Switch}
                />
                {values?.connectingPanelsToAnotherFloor && (
                  <>
                    <Field
                      label="Poziom innej podłogi"
                      rightPlaceholder="mm"
                      type="number"
                      maxLength={4}
                      name="differentFloorLevel"
                      component={Input}
                    />
                  </>
                )}
              </SingleSectionElement>

              <SingleSectionElement
                className="single-section-element--strips"
                touched={touched}
                name={["wallStrips", "wallStripsReserve"]}
                errors={errors}
              >
                <Field
                  errors={errors}
                  validateField={validateField}
                  component={MeasurementArea}
                  measurementConfig={{
                    type: "Listwy przyścienne",
                    unit: "m",
                    fieldNames: {
                      values: "wallStrips",
                      total: "wallStripsSpace",
                      reserve: "wallStripsReserve",
                      totalWithReserve: "wallStripsSpaceWithReserve",
                    },
                    labels: {
                      title: "Listwy przyścienne",
                      reserve: "Zapas listew",
                      totalWithReserve: "Powierzchnia listew z zapasem",
                    },
                  }}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator"
                touched={touched}
                name="typeOfStrips"
                errors={errors}
              >
                <Field
                  label="Rodzaj listew"
                  name="typeOfStrips"
                  component={Input}
                  maxLength="30"
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator"
                touched={touched}
                name="stripColor"
                errors={errors}
              >
                <Field
                  optional
                  label="Kolor listwy"
                  name="stripColor"
                  component={Input}
                  maxLength="30"
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator single-section-element--strips"
                touched={touched}
                name="xd"
                errors={errors}
              >
                <div className="am-section-heading">
                  Ilość wykończeń (listwa PCV) <span>(opcjonalne)</span>
                </div>
                <Field
                  optional
                  label="Narożnik wewnętrzny"
                  name="internalCorner"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                  type="number"
                />
                <Field
                  optional
                  label="Narożnik zewnętrzny"
                  name="externalCorner"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                  type="number"
                />
                <Field
                  optional
                  label="Łącznik"
                  name="connector"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                  type="number"
                />

                <Field
                  optional
                  label="Zakończenie prawe"
                  name="rightEnding"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                  type="number"
                />
                <Field
                  optional
                  label="Zakończenie lewe"
                  name="leftEnding"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                  type="number"
                />
                <Field
                  checked={values.resignationFromExpansion}
                  label="Rezygnacja z dylatacji w przejściach"
                  name="resignationFromExpansion"
                  component={Switch}
                />
              </SingleSectionElement>

              <Field
                errors={errors}
                validateField={validateField}
                component={DoorSills}
                touched={touched}
                name="skirtingBoards"
              />
              <SingleSectionElement
                className="single-section-element--strips "
                touched={touched}
                name={["floorSpaceToBeTotaled", "reserve"]}
                errors={errors}
              >
                <Field
                  errors={errors}
                  validateField={validateField}
                  component={MeasurementArea}
                  measurementConfig={{
                    type: "Powierzchnia podłogi",
                    unit: "m2",
                    fieldNames: {
                      values: "floorSpaceToBeTotaled",
                      total: "floorSpace",
                      reserve: "reserve",
                      totalWithReserve: "floorSpaceWithReserve",
                    },
                    labels: {
                      title: "Powierzchnia podłogi",
                      reserve: "Zapas",
                      totalWithReserve: "Powierzchnia podłogi z zapasem",
                    },
                  }}
                />
              </SingleSectionElement>

              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    handleSubmit();
                    setIsAddNew(true);
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FloorMeasurementValues;
