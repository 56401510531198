import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

export default function useGetMeasurementInfo(measurementUuid: string | null) {
  return useQuery({
    queryKey: ["currentMeasurementInfo", measurementUuid],
    queryFn: async () => {
      return axiosInstance
        .get(`/measurement/current-info/?measurementUuid=${measurementUuid}`)
        .then((res) => res.data);
    },
    select: (data: {measurement: any}) => data.measurement,  
    retry: false,
    enabled: !!measurementUuid,
  });
}