/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useGetRawConditions } from "hooks/measurements";
import { CheckboxPart } from "components";

interface BlindsAndShuttersRawConditionTypes {
  field: any;
  form: any;
}

const BlindsAndShuttersRawCondition = ({
  form,
  field,
}: BlindsAndShuttersRawConditionTypes) => {
  const { data: rawCondition } = useGetRawConditions();

  const [typeOfRawConditionUuids, setRawConditionUuids] = useState<any>(
    form.values[field.name] || []
  );
  const handleClickCheckbox = (uuid: string) => {
    const isUuidInArray =
      typeOfRawConditionUuids &&
      typeOfRawConditionUuids.find((item: any) => item === uuid);
    if (!isUuidInArray) {
      const values = [...typeOfRawConditionUuids, uuid];
      setRawConditionUuids(values);
      form.setFieldValue(field.name, values);
    } else {
      const values = typeOfRawConditionUuids.filter(
        (item: any) => item !== uuid
      );
      setRawConditionUuids(values);
      form.setFieldValue(field.name, values);
    }
  };

  useEffect(() => {
    setRawConditionUuids(form.values.rawConditionUuids);
  }, [form.values.rawConditionUuids]);

  return (
    <div className="mounting-accessories">
      <div className="am-section-checkbox-list">
        {rawCondition &&
          rawCondition.length > 0 &&
          rawCondition.map((item: any) => {
            return (
              <CheckboxPart
                checked={typeOfRawConditionUuids.find(
                  (uuid: string) => uuid === item.value
                )}
                key={item.value}
                label={item.label}
                uuid={item.value}
                onChange={(uuid) => handleClickCheckbox(uuid)}
              />
            );
          })}
      </div>
      <div className="am-separator" />
    </div>
  );
};

export default BlindsAndShuttersRawCondition;
