import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type RollerBlindsAndBlindsInfoData = {
  measurementUuid: string;
  productKind: 'rollerBlinds' | 'blinds';
  productType: 'standard' | 'flushMounted';
  color: string;
  width: string;
  height: string;
  assemblyType: 'inTheAlcove' | 'onTheWallOutside' | 'onTheWallInside';
  typeOfDrive: 'radioPlusKey' | 'radio' | 'key' | 'wifi' | 'manual' | 'outsideKey';
  cableExitSide: 'side' | 'back' | 'front';
  sideOfThePowerCable: 'right' | 'left';
  comments?: string;
};

const usePutRollerBlindsAndBlindsInfo = (
  options?: UseMutationOptions<any, Error, RollerBlindsAndBlindsInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editRollerBlindsAndBlindsInfo'],
    mutationFn: async (data) => {
      return axiosInstance
        .put('/measurement/roller-blinds-and-blinds/info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych rolet i żaluzji...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-roller-blinds-and-blinds-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane rolet i żaluzji zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-roller-blinds-and-blinds-info',
      });
      queryClient.invalidateQueries({ queryKey: ['rollerBlindsAndBlindsInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych rolet i żaluzji.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-roller-blinds-and-blinds-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutRollerBlindsAndBlindsInfo;
