import {
  Button,
  Modal,
  Input,
  PhoneWithPrefix,
  Select,
  ColorInput,
} from "components";
import { Field, Formik } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import { useGetLocations, usePhonePrefixes } from "hooks/utils";
import { addAdminType, addEmployeeType } from "types/UsersTypes";
import { employeeRoleOptions } from "utils";
import * as Yup from "yup";
import "./edit-user.scss";
import { useEditEmployee, useEditAdmin } from "hooks/users";

interface EditUserType {
  user: any;
  isOpen: boolean;
  isAdminForm: boolean;
  onClose: () => void;
}

const EditUser = ({ isOpen, onClose, isAdminForm, user }: EditUserType) => {
  const { data } = usePhonePrefixes();
  const { data: locations } = useGetLocations();
  const { mutate: editEmployeee } = useEditEmployee({
    onSuccess: () => {
      onClose();
    },
  });
  const { mutate: editAdmin } = useEditAdmin({
    onSuccess: () => {
      onClose();
    },
  });
  const locationUuid = locations?.find(({ label }) => label === user.location);

  const phonePrefixOptions =
    data &&
    data?.phonePrefixSerialize &&
    data?.phonePrefixSerialize.length > 0 &&
    data?.phonePrefixSerialize.map((item: any) => ({
      label: item.phonePrefix,
      value: item.value,
    }));
  const phoneUuid = phonePrefixOptions?.find(
    (item: any) => item.label === user.phonePrefix
  );

  type FormValues = addAdminType | addEmployeeType;
  let validationSchema;
  let initialValues;
  if (isAdminForm) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*[^\\s]?$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      lastName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*[^\\s]?$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      email: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .email(VALIDATION_MESSAGES.EMAIL),
    });
  } else {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*[^\\s]?$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      lastName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*[^\\s]?$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      phoneNumber: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(9, VALIDATION_MESSAGES.MIN9DIGIT),
      role: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      locationUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    });
  }

  if (isAdminForm) {
    initialValues = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      uuid: user.uuid,
    };
  } else {
    initialValues = {
      firstName: user.firstName,
      lastName: user.lastName,
      phonePrefixUuid: phoneUuid?.value || "",
      phoneNumber: user.phoneNumber,
      role: user.role,
      locationUuid: locationUuid?.value || "",
      uuid: user.uuid,
      userColor: user.userColor,
    };
  }

  const handleOnSubmit = async (values: FormValues) => {
    if (isAdminForm) {
      type EditAddAdminType = addAdminType & {
        uuid: string;
      };
      const adminValues = values as EditAddAdminType;
      await editAdmin(adminValues);
    } else {
      type EditEmployeeType = addEmployeeType & {
        uuid: string;
      };
      const employeeValues = values as EditEmployeeType;
      await editEmployeee(employeeValues);
    }
  };

  return (
    <>
      {user && phonePrefixOptions && locations && (
        <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
          <>
            <div className="modal-heading">Edycja użytkownika</div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={async (values) => handleOnSubmit(values)}
            >
              {({ handleSubmit, errors, isSubmitting }) => (
                <form className="add-user__form form" onSubmit={handleSubmit}>
                  <Field
                    errors={errors}
                    label="Imię"
                    name="firstName"
                    component={Input}
                  />
                  <Field
                    errors={errors}
                    label="Nazwisko"
                    name="lastName"
                    component={Input}
                  />
                  {isAdminForm ? (
                    <Field
                      errors={errors}
                      label="Email"
                      name="email"
                      component={Input}
                    />
                  ) : (
                    <>
                      <PhoneWithPrefix>
                        <Field
                          label="Telefon"
                          name="phonePrefixUuid"
                          component={Select}
                          size="small"
                          errors={errors}
                          options={phonePrefixOptions}
                        />
                        <Field
                          type="number"
                          name="phoneNumber"
                          maxLength={9}
                          required
                          component={Input}
                        />
                      </PhoneWithPrefix>
                      <Field
                        label="Stanowisko"
                        name="role"
                        component={Select}
                        errors={errors}
                        options={employeeRoleOptions}
                      />
                      <Field
                        label="Kolor użytkownika"
                        name="userColor"
                        errors={errors}
                        component={ColorInput}
                      />
                      <Field
                        label="Lokalizacja"
                        name="locationUuid"
                        component={Select}
                        errors={errors}
                        options={locations}
                      />
                    </>
                  )}
                  <div className="modal-buttons edit-user__actions">
                    <Button stroke onClick={() => onClose()} label="Anuluj" />
                    <Button type="submit" label="Potwierdź" />
                  </div>
                </form>
              )}
            </Formik>
          </>
        </Modal>
      )}
    </>
  );
};

export default EditUser;
