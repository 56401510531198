import { Button, Modal } from "components";
import { useFinishOrder } from "hooks/orders"; // Importujemy hook

interface CloseMeasurementType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const CloseMeasurement = ({ isOpen, onClose, data }: CloseMeasurementType) => {
  const finishOrderMutation = useFinishOrder();

  const handleConfirm = () => {
    if (data.uuid) {
      finishOrderMutation.mutate(
        { uuid: data.uuid },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    }
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Zakończ zlecenie</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zakończyć zlecenie
            <span>{data.label}</span>?
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={handleConfirm} label="Potwierdź" />{" "}
          </div>
        </>
      </Modal>
    </>
  );
};

export default CloseMeasurement;
