import { Field, Formik } from "formik";
import { Switch, Button } from "components";
import "./verifications.scss";
import { VerifiedMeasurement } from "types/OrderTypes";
import { useVerifyMeasurements } from "hooks/orders";
import { useNavigate } from "react-router";
import { ROUTE_ENUM } from "enums";

interface VerificationsProps {
  uuid: string;
  verifications: VerifiedMeasurement[] | undefined;
}

const Verifications = ({ verifications, uuid }: VerificationsProps) => {
  const navigate = useNavigate();

  const { mutate: verifyMeasurements } = useVerifyMeasurements({
    onSuccess: () => {
      navigate(ROUTE_ENUM.ORDERS_LIST);
    },
  });
  const initialValues = {
    measurements: verifications?.map((verification) => ({
      id: verification.measurementId,
      ...verification.toFix.reduce((acc: { [key: string]: boolean }, fix) => {
        if (typeof fix === "object" && fix.type && fix.state !== undefined) {
          acc[fix.type] = false;
        }
        return acc;
      }, {}),
    })),
  };

  const handleOnSubmit = async (values: any) => {
    const dataToSend = {
      uuid,
      measurements: values.measurements.map((measurement: any) => ({
        id: measurement.id,
        ...Object.keys(measurement).reduce(
          (acc: { [key: string]: boolean }, key) => {
            if (key !== "id") acc[key] = measurement[key];
            return acc;
          },
          {}
        ),
      })),
    };

    verifyMeasurements(dataToSend);
  };

  return (
    <>
      <div className="active-order__bar">
        <div className="active-order__bar-heading verifications__bar-heading">
          <div className="active-order__heading verifications__heading">
            Weryfikacja
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => handleOnSubmit(values)}
        >
          {({ handleSubmit, errors }) => {
            return (
              <form
                className="add-user__form form verifications__form"
                onSubmit={handleSubmit}
              >
                {verifications &&
                  verifications.length > 0 &&
                  verifications.map((verification: VerifiedMeasurement, i) => (
                    <div key={i} className="verifications__item">
                      <div className="verifications__item-heading">
                        {verification.label}
                      </div>
                      {verification.toFix?.map((fix, j) => {
                        return (
                          <div key={j} className="verifications__form-item">
                            {typeof fix === "object" ? (
                              <Field
                                reverse
                                checked={fix.state}
                                name={`measurements[${i}].${fix.type}`}
                                label={fix.label}
                                component={Switch}
                                first="Wykonano"
                                second="Nie wykonano"
                              />
                            ) : (
                              <span>{fix}</span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                <Button
                  label="Zakończ zlecenie"
                  className="active-order__measurement-button"
                  onClick={handleSubmit}
                />
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default Verifications;
