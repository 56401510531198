import "./used-assembly-materials.scss";
import { Button, SVG, ContextMenu } from "components";
import { SVG_TYPE } from "enums";
import {
  AddingUsedAssemblyMaterial,
  EditingUsedAssemblyMaterial,
  DeleteUsedAssemblyMaterial,
} from "features/usedAssemblyMaterials";
import { useGetAssemblyMaterialSelect } from "hooks/assembly-materials";
import { useState } from "react";

interface UsedAssemblyMaterialsType {
  orderDetails: any;
}

const UsedAssemblyMaterials = ({ orderDetails }: UsedAssemblyMaterialsType) => {
  const { data: usedAssemblyMaterialLabel } = useGetAssemblyMaterialSelect();
  const [modalManage, setModalManage] = useState({
    type: "",
    usedAssemblyMaterial: {},
  });
  const handleAddUsedAssemblyMaterial = () => {
    setModalManage({ type: "add", usedAssemblyMaterial: {} });
  };
  const handleCloseModal = () => {
    setModalManage({ type: "", usedAssemblyMaterial: {} });
  };

  const isModalAddOpened = modalManage.type === "add";
  const isModalEditingOpened = modalManage.type === "edit";
  const isModalDeletingOpened = modalManage.type === "delete";
  const usedAssemblyMaterial = orderDetails?.usedAssemblyMaterials;

  const measurementMenu = (item: any) => {
    return [
      {
        name: "Edytuj",
        action: () => {
          setModalManage({ type: "edit", usedAssemblyMaterial: item });
        },
      },
      {
        name: "Usuń",
        action: () => {
          setModalManage({ type: "delete", usedAssemblyMaterial: item });
        },
      },
    ];
  };

  return (
    <>
      <AddingUsedAssemblyMaterial
        isOpen={isModalAddOpened}
        onClose={() => handleCloseModal()}
        usedAssemblyMaterialLabel={usedAssemblyMaterialLabel}
      />
      <EditingUsedAssemblyMaterial
        isOpen={isModalEditingOpened}
        onClose={() => handleCloseModal()}
        usedAssemblyMaterialLabel={usedAssemblyMaterialLabel}
        usedAssemblyMaterial={modalManage.usedAssemblyMaterial}
      />
      <DeleteUsedAssemblyMaterial
        isOpen={isModalDeletingOpened}
        onClose={() => handleCloseModal()}
        data={modalManage.usedAssemblyMaterial}
      />
      <div className="uam">
        <div className="active-order__bar">
          <div className="active-order__bar-heading">
            <div className="active-order__heading active-order__heading-optional">
              Zużyte materiały<span>(opcjonalne)</span>
            </div>
            <Button
              stroke
              svgType={SVG_TYPE.PLUS}
              size="medium"
              label={`Dodaj materiał `}
              onClick={() => handleAddUsedAssemblyMaterial()}
            />
          </div>
        </div>
        {usedAssemblyMaterial && (
          <>
            <div className="uam__list">
              {usedAssemblyMaterial?.map((item: any, index: number) => {
                return (
                  <div className="uam__item">
                    <div className="uam__item-label">{item?.assemblyName}</div>
                    <div className="uam__item-count">{item?.count}</div>
                    <ContextMenu
                      className="dtb__user-submenu"
                      items={measurementMenu(item)}
                    >
                      <SVG type={SVG_TYPE.ELLIPSIS} />
                    </ContextMenu>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UsedAssemblyMaterials;
