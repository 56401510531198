import React from "react";
import { SkeletonRow } from "components";
import { useGetMe } from "hooks/auth";
import { USER_ROLE } from "enums";
import ArchivalDatesDetails from "../ArchivalDatesDetails";

interface ActiveOrderDetailsProps {
  isLoading: boolean;
  orderDetails: any;
}

const ActiveOrderDetails = ({
  isLoading,
  orderDetails,
}: ActiveOrderDetailsProps) => {
  const { data: authorizedUser } = useGetMe();
  const userRole = authorizedUser?.role;
  const isFitter = userRole === USER_ROLE.fitter;
  const isMeasurer = userRole === USER_ROLE.measurer;
  const isAdmin = userRole === USER_ROLE.admin;
  const labelComments = isFitter || isMeasurer || isAdmin;

  return (
    <>
      <div className="active-order__box active-order__box--measurement">
        <div className="active-order__heading">Szczegóły zlecenia</div>
        <div className="active-order__details"></div>
        <div className="active-order__details-heading">
          Imię i nazwisko klienta
        </div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.clientName
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <div className="active-order__details-heading">Telefon</div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.phoneNumber
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <div className="active-order__details-heading">Adres Email</div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.email
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <div className="active-order__details-heading">Adres pomiaru</div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.address
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <ArchivalDatesDetails hideVerification orderDetails={orderDetails} />
        <div className="active-order__details-heading">Termin pomiaru</div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            `${orderDetails?.date}, godz. ${orderDetails?.start}-
              ${orderDetails?.end}`
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <div className="active-order__details-heading">
          {labelComments ? "Uwagi od grafikowca" : "Uwagi dla pomiarowca"}
        </div>
        {!isLoading ? (
          <>
            {orderDetails?.comments && (
              <div className="active-order__details-paragraph">
                {orderDetails?.comments}
              </div>
            )}
          </>
        ) : (
          <SkeletonRow count={1} width="200px" />
        )}
      </div>
    </>
  );
};

export default ActiveOrderDetails;
