import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type PeriodType = string;
type OrdersType = { value: string }[];

export default function useGetUsersTime(period: PeriodType, orders: OrdersType) {
  return useQuery({
    queryKey: ["users-time", period, orders],
    queryFn: async () => {
      await delay(500);

      const formattedOrders = orders.map((order) => order.value).join(',');

      const params = {
        period,
        orders: formattedOrders,
      };

      return axiosInstance
        .get(`/users-time`, { params })
        .then((res) => res.data);
    },
    select: (data: { data: any }) => data?.data,
    retry: false,
    staleTime: 0,
  });
}
