import { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  InstallationPlanning,
  Button,
  StaticAlert,
  InstallationArranging,
} from "components";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { measurementAllInstallationType } from "types/OrderTypes";
import { VALIDATION_MESSAGES } from "enums";
import {
  useAddInstallationOrder,
  useUpdateInstallationOrder,
} from "hooks/orders";
import { useNavigate } from "react-router";
interface installationsFormType {
  orderDetails: any;
  isEdit: boolean;
}

const InstallationsForm = ({ orderDetails, isEdit }: installationsFormType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showError, setShowError] = useState(false);
  const uuid = searchParams.get("uuid");
  const [initialValues, setInitialValues] = useState<any>({
    installations: [],
    uuid,
  });
  const handleOnSubmit = (values: any) => {
    if (values.installations.length > 0) {
      isEdit
        ? updatedMutation.mutate({ uuid, ...values })
        : mutation.mutate({ uuid, ...values });
    } else {
      setShowError(true);
    }
  };

  const mutation = useAddInstallationOrder({
    onSuccess: () => {
      navigate(-1);
    },
  });

  const updatedMutation = useUpdateInstallationOrder({
    onSuccess: () => {
      navigate(-1);
    },
  });

  useEffect(() => {
    if (
      isEdit &&
      orderDetails &&
      orderDetails.measurementArrangedInstallation
    ) {
      const transformedData = orderDetails.measurementArrangedInstallation.map(
        (item: any) => ({
          ...item,
          dates: item.dates.map((dateItem: any) => ({
            ...dateItem,
            team: dateItem.team.map((teamItem: any) => ({
              ...teamItem,
              employeeUuids: teamItem.employeeUuids.map(
                (employee: any) => employee.uuid
              ),
            })),
          })),
        })
      );
      setInitialValues({
        uuid,
        installations: transformedData,
      });
    }
  }, [orderDetails, isEdit, uuid]);

  const InstallationPlanningSchema = Yup.object().shape({
    installations: Yup.array().of(
      Yup.object().shape({
        dates: Yup.array().of(
          Yup.object().shape({
            date: Yup.string()
              .required(VALIDATION_MESSAGES.REQUIRED)
              .nullable(),
            team: Yup.array().of(
              Yup.object().shape({
                start: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                end: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                employeeUuids: Yup.array()
                  .of(Yup.string().required())
                  .required(VALIDATION_MESSAGES.REQUIRED)
                  .min(1, VALIDATION_MESSAGES.REQUIRED),
              })
            ),
          })
        ),
      })
    ),
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const ordersDetailsMeasurements = isEdit
    ? orderDetails?.measurementArrangedInstallation
    : orderDetails?.measurementAllInstallation;
  const arrangedInstallation = orderDetails.measurementArrangedInstallation;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={InstallationPlanningSchema}
        onSubmit={async (values) => handleOnSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, errors, values, validateField }) => {
          return (
            <form className="installation-planning__form">
              <div className="modal__information-header">
                Montaż do ustalenia
              </div>
              <div className="installation-planning__wrapper">
                {ordersDetailsMeasurements.map(
                  (details: measurementAllInstallationType, index: number) => {
                    return (
                      <>
                        <InstallationPlanning
                          installationIndex={index}
                          key={`${details.plannedAssemblyTime}--${index}`}
                          details={details}
                          errors={errors}
                          initialValues={values}
                          setInitialValues={setInitialValues}
                          isEdit={isEdit}
                          validateField={validateField}
                        />
                      </>
                    );
                  }
                )}
              </div>
              <StaticAlert
                show={showError}
                label="Nie wybrano żadnego montażu do ustalenia"
              />
              <div className="installation-planning__wrapper installation-arranging__wrapper">
                <div className="modal__information-header">
                  Ustalone montaże
                </div>
                {arrangedInstallation.length > 0 &&
                  arrangedInstallation.map((installation: any) => {
                    return (
                      <InstallationArranging installation={installation} />
                    );
                  })}
              </div>
              <div className="modal-buttons aap__buttons">
                <Button stroke onClick={handleGoBack} label="Anuluj" />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  label="Potwierdź"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default InstallationsForm;
