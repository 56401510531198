import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface VerificationParams {
  oldWindowDismantlingInvestor?: boolean;
  floorFurnitureProtection?: boolean;
  preparationOfOpeningsForTripleLayerInstallation?: boolean;
  changeOfOpeningDimensions?: boolean;
  constructionSiteCleanupInvestor?: boolean;
  prepareTheOpening?: boolean;
  conditionOfTheSpout?: boolean;
}

interface VerifiedMeasurement {
  id: number;
  params: VerificationParams;
}

interface VerifyMeasurementsType {
  uuid: string;
  measurements: VerifiedMeasurement[];
}

const useVerifyMeasurements = (
  options?: UseMutationOptions<any, Error, VerifyMeasurementsType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['verifyMeasurements'],
    mutationFn: async ({ uuid, measurements }) => {
      return axiosInstance.put(`/orders/verify-measurements/${uuid}`, {
        measurements,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie weryfikacji pomiarów...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'verify-measurements',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Weryfikacja zakończona sukcesem',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'verify-measurements',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas weryfikacji pomiarów',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'verify-measurements',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useVerifyMeasurements;
