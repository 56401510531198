import { SVG_TYPE, ROUTE_ENUM, USER_ROLE } from "enums";
import { SVG, Button } from "components";
import { Tooltip } from "react-tooltip";
import "./calendar-page-preview.scss";
import moment from "moment";
import "moment/locale/pl";
import { useNavigate } from "react-router";
import { UserType } from "types/UsersTypes";

interface CalendarPagePreviewType {
  event: any;
  user?: UserType;
  date: any;
}
const CalendarPagePreview = ({
  event,
  date,
  user,
}: CalendarPagePreviewType) => {
  const navigate = useNavigate();
  const isMeasurer = user?.role === USER_ROLE.measurer;

  const handleClickEdit = () => {
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${event.original.uuid}&tab=${event.original.status}&edit=true`
    );
  };

  const handleClose = () => {
    const dashboard = document.getElementsByClassName("dashboard");
    if (dashboard.length > 0) {
      const dashboardElement = dashboard[0] as HTMLElement;
      dashboardElement.click();
    }
  };

  const handleAfterHide = () => {
    const elements = document.querySelectorAll(".cpp-day__tooltip-open");
    elements.forEach((element) => {
      element.classList.remove("cpp-day__tooltip-open");
    });
  };

  moment.locale("pl");
  const formattedDate = moment(event.original.date, "DD/MM/YYYY").format(
    "dddd, D MMMM"
  );
  const isMonday = moment(event.original.date, "DD/MM/YYYY").day() === 1;

  const tooltipPlace = isMonday ? "right" : "bottom";

  const handleClickOrder = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${event.original.uuid}`);
  };

  return (
    <Tooltip
      afterHide={handleAfterHide}
      className="cpp"
      anchorSelect={`#cpp-afera--${event.index}`}
      clickable
      openOnClick
      place={tooltipPlace}
    >
      <div className="cpp__wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="cpp__content" onClick={(e) => e.stopPropagation()}>
          <div className="cpp__top" onClick={(e) => e.stopPropagation()}>
            <div className="cpp__actions">
              {!isMeasurer && (
                <div className="cpp__action" onClick={() => handleClickEdit()}>
                  <SVG type={SVG_TYPE.EDIT_PENCIL} />
                </div>
              )}
              <div className="cpp__action" onClick={() => handleClose()}>
                <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
              </div>
            </div>
          </div>
          <div className="cpp__event">
            <div className="cpp__event-name">{event.original.title}</div>
            <div className="cpp__event-date">
              {formattedDate} {event.original.start}-{event.original.end}
            </div>
            <div className="cpp__event-address">{event.original.address}</div>
            {!isMeasurer && (
              <div className="cpp__event-user">
                <div
                  style={{ backgroundColor: event.original.userColor }}
                  className="cpp__event-user-box"
                ></div>
                <div className="cpp__event-user-name">
                  {event.original.userName}
                </div>
              </div>
            )}
            {isMeasurer && (
              <div className="cpp__buttons">
                <Button
                  label="Wykonaj"
                  onClick={() => handleClickOrder()}
                  size="small"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default CalendarPagePreview;
