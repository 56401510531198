import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM, ROUTE_ENUM } from 'enums';
import { queryClient } from 'App';
import { useNavigate } from 'react-router';

interface FinishOrderType {
  uuid: string;
}

const useFinishOrder = (
  options?: UseMutationOptions<any, Error, FinishOrderType>
) => {
  const navigate = useNavigate();
  
  return useMutation({
    ...options,
    mutationKey: ['finishOrder'],
    mutationFn: async ({ uuid }) => {
      return axiosInstance.put(`/orders/finish-order/${uuid}`).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie zakończenia pomiaru...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'finish-order',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Pomiar został zakończony pomyślnie',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'finish-order',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.invalidateQueries({ queryKey: ['measurements'] });
      navigate(ROUTE_ENUM.ORDERS_LIST);
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas zakończenia pomiaru',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'finish-order',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useFinishOrder;
