import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type EditSupplierType = {
  uuid: string;
  name: string;
};

const useEditSupplier = (
  options?: UseMutationOptions<any, Error, EditSupplierType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editSupplier'],
    mutationFn: async ({ uuid, name }) => {
      return axiosInstance.put(`/suppliers/${uuid}`, {
        name,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Edycja dostawcy...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-supplier',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dostawca zaktualizowany pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-supplier',
      });
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych dostawcy.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-supplier',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useEditSupplier;