import { useState, useEffect } from "react";
import {
  CheckLists,
  ItemsLeftAtClient,
  ReceiptProtocol,
} from "features/orders";
import { Button, Textarea } from "components";
import "./receipt-protocol-generator.scss";
import { Formik, Field } from "formik";
import { useGetChecklistOrder } from "hooks/checklist";
import { useSearchParams } from "react-router-dom";

interface ReceiptProtocolGeneratorProps {
  orderDetails: any;
}
const ReceiptProtocolGenerator = ({
  orderDetails,
}: ReceiptProtocolGeneratorProps) => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const { data: checkList } = useGetChecklistOrder(orderUuid);
  const [isOpenProtocol, setIsOpenProtocol] = useState(false);
  const [hasUncheckedRequired, setHasUncheckedRequired] = useState(false);
  const [errorEndAppointment, setErrorEndAppointment] = useState({
    isError: false,
    errorMessage: "",
  });

  const handleRequiredStatusChange = (status: boolean) => {
    setHasUncheckedRequired(status);
  };
  const handleGenerateProtocol = () => {
    if (hasUncheckedRequired) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Masz nieznaczone wymagane checklisty. Uzupełnij brakujące elementy, aby móc wygenerować raport.",
      });
    } else {
      setIsOpenProtocol(true);
      setErrorEndAppointment({ isError: false, errorMessage: "" });
    }
  };

  useEffect(() => {
    if (!hasUncheckedRequired && errorEndAppointment.isError) {
      setErrorEndAppointment({ isError: false, errorMessage: "" });
    }
  }, [hasUncheckedRequired, errorEndAppointment.isError]);

  return (
    <>
      <ReceiptProtocol
        orderDetails={orderDetails}
        checkList={checkList}
        onClose={() => setIsOpenProtocol(false)}
        isOpen={isOpenProtocol}
      />
      <div className="active-order__box receipt-protocol-generator">
        <Formik
          initialValues={{ comment: "" }}
          enableReinitialize
          validationSchema={null}
          onSubmit={async (values: any) => console.log(values)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, errors, values, validateField }) => {
            return (
              <form>
                <CheckLists
                  errorEndAppointment={errorEndAppointment}
                  onRequiredStatusChange={handleRequiredStatusChange}
                />
                <Field
                  className="receipt-protocol-generator__textarea"
                  label="Uwagi od klienta"
                  optional
                  name="comment"
                  placeholder=""
                  component={Textarea}
                  errors={errors}
                />
                <ItemsLeftAtClient />
                <div className="receipt-protocol-generator__button-wrapper">
                  <Button
                    className="receipt-protocol-generator__button"
                    label="Generuj protokół odbioru"
                    onClick={() => handleGenerateProtocol()}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ReceiptProtocolGenerator;
