/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect } from "react";
import { isMobile } from "utils";
import { USER_ROLE } from "enums";
import Table from "rc-table";
import { Pagination, SkeletonRow } from "components";
import { desktopColumns, mobileColumns } from "./utils";
import "./orders-table.scss";
import "../../users/UsersTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetOrders,
  useGetScheduledOrders,
  useGetAllOrders,
} from "hooks/orders";
import { OrderStatus, ScheduleOrderStatus } from "types/OrderTypes";

interface OrderPreviewType {
  setOrderPreview: (order: any) => void;
  activeFloatingMenuItem?: { label: string; value: string } | null;
  userRole: string;
  page: number;
  setPage: (page: number) => void;
}

const OrdersTable = ({
  setOrderPreview,
  activeFloatingMenuItem,
  userRole,
  page,
  setPage,
}: OrderPreviewType) => {
  const isScheduleManager = userRole === USER_ROLE.scheduleManager;
  const isAdmin = userRole === USER_ROLE.admin;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const uuidFromUrl = searchParams.get("orderUuid");
  const tabFromUrl = searchParams.get("tab");
  const isAllOrder = tabFromUrl === "orders";
  const [activeRowId, setActiveRowId] = useState(0);

  const { data: allOrders, isLoading: isLoadingAllOrders } = useGetAllOrders(
    page,
    {
      enabled: isAllOrder,
    }
  );

  const { data: regularOrders, isLoading: isLoadingRegularOrders } =
    useGetOrders(activeFloatingMenuItem?.value as OrderStatus, page, {
      enabled: !isScheduleManager && !isAdmin,
    });

  const isEnable = !!(isScheduleManager && tabFromUrl && tabFromUrl.length > 0);
  const { data: scheduledOrders, isLoading: isLoadingScheduledOrders } =
    useGetScheduledOrders(tabFromUrl as ScheduleOrderStatus, page, {
      enabled: isEnable,
    });

  const orders = isAdmin
    ? allOrders
    : isScheduleManager
    ? scheduledOrders
    : regularOrders;
  const isLoading = isAdmin
    ? isLoadingAllOrders
    : isScheduleManager
    ? isLoadingScheduledOrders
    : isLoadingRegularOrders;

  useEffect(() => {
    if (uuidFromUrl && orders?.data) {
      const record = orders.data.find((r) => r.uuid === uuidFromUrl);
      if (record) {
        setTimeout(() => {
          setActiveRowId(record.id);
          setOrderPreview(record);
        }, 200);
      }
    }
  }, [orders]);

  const handleClickRow = (record: any) => {
    setActiveRowId(record.id);
    setOrderPreview(record);
    activeFloatingMenuItem?.value
      ? setSearchParams({
          page: page.toString(),
          orderUuid: record.uuid,
          tab: activeFloatingMenuItem?.value,
        })
      : setSearchParams({ page: page.toString(), orderUuid: record.uuid });
  };

  return (
    <div className="users-table">
      {orders && orders?.data?.length > 0 && (
        <Table
          rowKey="uuid"
          className="user-table order-table"
          data={orders?.data}
          columns={
            isMobile()
              ? mobileColumns(
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem
                )
              : desktopColumns(
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem
                )
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickRow(record),
              className: activeRowId === record.id ? "active-row" : "",
            };
          }}
        />
      )}
      {isLoading && <SkeletonRow count={10} height={58} />}
      {orders?.meta && orders?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={orders?.meta?.lastPage}
          currentPage={page}
          onPageChange={(e) => {
            setPage(e);
            tabFromUrl
              ? setSearchParams({ page: e.toString(), tab: tabFromUrl })
              : setSearchParams({ page: e.toString() });
          }}
        />
      )}
    </div>
  );
};

export default OrdersTable;
