import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM, ROUTE_ENUM } from 'enums';
import { queryClient } from 'App';
import { useNavigate } from "react-router";

interface ScheduleVerificationUpdateDetailsType {
  uuid: string | null;
  date?: string;
  start?: string;
  end?: string;
  comment: string;
  employeeUuid?: string;
}

const useScheduleVerificationUpdate = (
  options?: UseMutationOptions<any, Error, ScheduleVerificationUpdateDetailsType>
) => {
  const navigate = useNavigate();
  
  return useMutation({
    ...options,
    mutationKey: ['scheduleVerificationUpdate'],
    mutationFn: async ({ uuid, date, start, end, employeeUuid, comment }) => {
      return axiosInstance.put(`/orders/verification-update/${uuid}`, {
        date,
        start,
        end,
        comment,
        employeeUuid,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie edycji weryfikacji...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'schedule-verification-update',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Weryfikacja została zaktualizowana pomyślnie',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'schedule-verification-update',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      navigate(ROUTE_ENUM.ORDERS_LIST);
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas edytowania weryfikacji',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'schedule-verification-update',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useScheduleVerificationUpdate;
