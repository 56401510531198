import { useState } from "react";
import {
  Button,
  Modal,
  RateInput,
  CalendarInput,
  StaticAlert,
} from "components";
import { Field, Formik } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import { useEditEmployeeRate } from "hooks/employee-rates";
import { UserNameAndBadge } from "features/employeeRates";
import * as Yup from "yup";
import "./edit-employee-rate.scss";

interface EditEmployeeRateType {
  isOpen: boolean;
  onClose: () => void;
  user: any;
  userRates: any;
}

const EditEmployeeRate = ({
  isOpen,
  onClose,
  user,
  userRates,
}: EditEmployeeRateType) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { mutate: editEmployeeRate } = useEditEmployeeRate({});
  const currentDate = new Date();
  const tommorow = currentDate.setDate(currentDate.getDate() + 1);
  const userRate = userRates && userRates.find((r: any) => r.toDate === "now");

  const initialValues = {
    date: user.plannedRate,
    rate: userRate?.rate,
    uuid: user.uuid,
  };

  const validationSchema = Yup.object().shape({
    rate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    date: Yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = async (values: any) => {
    const isDifferentRate = user.rate !== values.rate;
    if (user.plannedRate) {
      if (isDifferentRate) {
        await editEmployeeRate({ ...values });
        setIsAlertOpen(false);
        onClose();
      } else {
        setIsAlertOpen(true);
      }
    } else {
      if (isDifferentRate) {
        await editEmployeeRate({ ...values });
        setIsAlertOpen(false);
        onClose();
      } else {
        setIsAlertOpen(true);
      }
    }
  };

  return (
    <>
      <Modal
        side="right"
        isOpened={isOpen}
        onClose={() => {
          onClose();
          setIsAlertOpen(false);
        }}
      >
        <>
          <div className="modal-heading">Edycja stawki</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
          >
            {({ handleSubmit, errors }) => {
              return (
                <form
                  className="add-user__form form edit-employee-rate__form"
                  onSubmit={handleSubmit}
                >
                  <UserNameAndBadge
                    className="edit-employee-rate"
                    user={user}
                  />
                  <Field
                    errors={errors}
                    maxLength={4}
                    name="rate"
                    component={RateInput}
                    rightPlaceholder={"zł/h netto"}
                    onChange={() => setIsAlertOpen(false)}
                  />
                  <Field
                    errors={errors}
                    label="Obowiązująca od dnia"
                    name="date"
                    component={CalendarInput}
                    minDate={tommorow}
                  />
                  <StaticAlert
                    show={isAlertOpen}
                    label="Nie można edytować stawki na tą samą wartość."
                  />
                  <div className="modal-buttons">
                    <Button
                      stroke
                      onClick={() => {
                        onClose();
                        setIsAlertOpen(false);
                      }}
                      label="Anuluj"
                    />
                    <Button type="submit" label="Potwierdź" />
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default EditEmployeeRate;
