import { StaticAlert } from "components";
import "./single-section-element.scss";

interface SingleSectionElementType {
  errors: any;
  children: any;
  touched: any;
  name: string | string[];
  className?: string;
}

const SingleSectionElement = ({
  errors,
  children,
  touched,
  name,
  className = "",
}: SingleSectionElementType) => {
  const hasError = () => {
    if (Array.isArray(name)) {
      return name.some((n) => errors[n]);
    }
    return errors[name];
  };

  return (
    <div className={`single-section-element ${className}`}>
      {children}
      {errors && touched && (
        <>
          <StaticAlert
            size="small"
            show={hasError()}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}

      <div
        className={`am-separator ${
          hasError() ? "" : "am-separator--no-margin-top "
        }`}
      />
    </div>
  );
};

export default SingleSectionElement;
