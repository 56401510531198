import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

interface OrdersResponse {
  orders: Array<{
    userUuid: string;
    userColor: string;
    title: string;
    start: string;
    end: string;
    date: string;
  }>;
}

export default function useGetEmployeeSchedule(employeeUuids: string[] | null, month: number | string, year: number | string | null, role: string | null = null) {
  return useQuery({
    queryKey: ["employeeSchedule", employeeUuids, month, role, year,],
    queryFn: async () => {
      if (month === null) {
        throw new Error("Missing required parameter: month");
      }

      if (!employeeUuids || employeeUuids.length === 0) {
        return []; 
      }

      const params: Record<string, any> = {
        month,
        year,
      };

      if (!employeeUuids.includes('all')) {
        params.employeeUuids = employeeUuids;
      }

      if (role) {
        params.role = role;
      }

      return axiosInstance
        .get("/orders/date/all", { params })
        .then((res) => res.data);
    },
    select: (data: OrdersResponse) => data.orders,
    retry: false,
    enabled: month !== null && employeeUuids !== null && employeeUuids.length > 0,
  });
}
