import "./order-preview.scss";
import { Modal, OrderStatus, SVG, FileItem, Button } from "components";
import { ROUTE_ENUM, SVG_TYPE, USER_ROLE, ORDER_ENUM } from "enums";
import { useNavigate } from "react-router";
import { useGetOrderDetails } from "hooks/orders";
import {
  MeasurementArrangedInstallation,
  ArchivalDatesDetails,
} from "features/orders";
import {
  OrderListType,
  measurementAllInstallationType,
  MEASUREMENTS_TYPE,
} from "types/OrderTypes";

interface OrderPreviewType {
  isOpen: boolean;
  onClose: () => void;
  order: OrderListType;
  page: number;
  activeFloatingMenuItem: any;
  userRole?: string | undefined;
}

const OrderPreview = ({
  isOpen,
  onClose,
  order,
  page,
  activeFloatingMenuItem,
  userRole,
}: OrderPreviewType) => {
  const navigate = useNavigate();
  const isMeasurer = userRole === USER_ROLE.measurer;
  const isFitter = userRole === USER_ROLE.fitter;
  const isAdmin = userRole === USER_ROLE.admin;

  const { data: orderDetails, isLoading } = useGetOrderDetails(
    order.uuid,
    isOpen
  );

  const handleClickAppointment = (edit = "") => {
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${order.uuid}&tab=${
        orderDetails?.status
      }${edit ? `&edit=true` : ""}`
    );
  };

  const handleClickMeasurement = () => {
    navigate(
      `${ROUTE_ENUM.ACTIVE_ORDER}?page=${page}&orderUuid=${order.uuid}&tab=${orderDetails?.status}`
    );
  };

  const isInstallationToBeReleased =
    orderDetails?.status === "installationToBeReleased";
  const isMeasurementDate = orderDetails?.status !== "measurementsToBeReleased";
  const isStatusMeasurementToBeReleased =
    orderDetails?.status === ORDER_ENUM.MEASUREMENTS_TO_BE_RELEASED;
  const isStatusFixedMeasurement =
    orderDetails?.status === ORDER_ENUM.FIXED_MEASUREMENTS;
  const isStatusFixedVerification =
    orderDetails?.status === ORDER_ENUM.FIXED_VERIFICATION;
  const isStatusVerificationToBeReleased =
    orderDetails?.status === ORDER_ENUM.VERIFICATION_TO_BE_RELEASED;
  const isStatusFixedInstallation =
    orderDetails?.status === ORDER_ENUM.FIXED_INSTALLATION;
  const measurementsAllInstallation = orderDetails?.measurementAllInstallation;
  // const isVerified =
  //   orderDetails?.status === ORDER_ENUM.VERIFICATION_TO_BE_RELEASED ||
  //   orderDetails?.status === ORDER_ENUM.FIXED_VERIFICATION;

  const isScheduleManager = userRole === USER_ROLE.scheduleManager;

  const isActiveEditButton =
    (isScheduleManager && isStatusFixedMeasurement) ||
    (isScheduleManager && isStatusFixedVerification) ||
    (isScheduleManager && isStatusFixedInstallation);

  return (
    <>
      {!isLoading && (
        <Modal
          side="right"
          className="order-preview"
          isOpened={isOpen}
          onClose={() => onClose()}
        >
          <>
            {orderDetails && (
              <div className="order-preview">
                <div className="modal-heading__with-close">
                  <div className="modal-heading">Podgląd zlecenia</div>
                  <OrderStatus
                    className="order-preview__status-desktop"
                    status={orderDetails.status}
                  />
                  <div
                    className="modal-heading__close order-preview__heading"
                    onClick={() => onClose()}
                  >
                    <span>Zamknij</span>
                    <SVG type={SVG_TYPE.CLOSE} />
                  </div>
                </div>
                <div className="order-preview__status-mobile">
                  <OrderStatus status={orderDetails.status} />
                </div>
                <div className="order-preview__label">{orderDetails.name}</div>
                <div className="modal__separator" />
                <div className="modal__informations">
                  {isActiveEditButton && (
                    <Button
                      className="order-preview__edit-button"
                      size="small"
                      label="Edycja"
                      onClick={() => handleClickAppointment()}
                    />
                  )}
                  <div className="modal__information-header">
                    Imię i nazwisko klienta
                  </div>
                  <div className="modal__information-descript">
                    {orderDetails.clientName}
                  </div>
                  <div className="modal__information-header">Telefon</div>
                  <div className="modal__information-descript">
                    {orderDetails.phoneNumber}
                  </div>
                  <div className="modal__information-header">Adres Email</div>
                  <div className="modal__information-descript">
                    {orderDetails.email}
                  </div>
                  <div className="modal__information-header">Adres pomiaru</div>
                  <div className="modal__information-descript">
                    {orderDetails.address}
                  </div>

                  <ArchivalDatesDetails orderDetails={orderDetails} />
                  {orderDetails.date && isMeasurementDate && (
                    <>
                      <div className="modal__information-header">
                        Termin pomiaru
                      </div>
                      <div className="modal__information-descript">
                        {orderDetails.date ? (
                          <>
                            {orderDetails.date}, godz. {orderDetails.start}-
                            {orderDetails.end}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </>
                  )}
                  {!isMeasurer && (
                    <>
                      <div className="modal__information-header">
                        Przypisany
                        {isInstallationToBeReleased
                          ? " monter"
                          : " pomiarowiec"}
                        {(isStatusMeasurementToBeReleased ||
                          isStatusVerificationToBeReleased) &&
                          !isAdmin && (
                            <Button
                              size="small"
                              label="Umów"
                              onClick={() => handleClickAppointment()}
                            />
                          )}
                      </div>
                      <div className="modal__information-descript">
                        {(orderDetails.employee && isStatusFixedMeasurement) ||
                        (orderDetails.employee && isStatusFixedVerification) ? (
                          <>
                            {orderDetails.employee?.firstName}{" "}
                            {orderDetails.employee?.lastName}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </>
                  )}
                  <div className="modal__information-header">
                    {isFitter || isMeasurer || isAdmin ? (
                      "Uwagi od grafikowca"
                    ) : (
                      <>
                        {isInstallationToBeReleased
                          ? "Uwagi dla montera"
                          : "Uwagi dla pomiarowca"}
                      </>
                    )}
                  </div>
                  <div className="modal__information-descript">
                    {orderDetails?.comments || "-"}
                  </div>
                  {isInstallationToBeReleased &&
                    measurementsAllInstallation &&
                    measurementsAllInstallation.length > 0 && (
                      <>
                        <div className="am-separator" />
                        <div className="modal__information-header">
                          Montaże do ustalenia
                          {!isAdmin && (
                            <Button
                              size="small"
                              label="Umów"
                              onClick={() => handleClickAppointment()}
                            />
                          )}
                        </div>
                        {measurementsAllInstallation?.length > 0 &&
                          measurementsAllInstallation.map(
                            (measurement: measurementAllInstallationType) => (
                              <div className="order-preview__installations">
                                <FileItem
                                  name={measurement.typeOfMeasurement}
                                />
                                <div className="order-preview__installation">
                                  <div className="order-preview__installation-label">
                                    Planowany czas montażu:
                                  </div>
                                  <div className="order-preview__installation-value">
                                    {measurement.plannedAssemblyTime}h
                                  </div>
                                </div>
                                <div className="order-preview__installation">
                                  <div className="order-preview__installation-label">
                                    Potrzebna ilość pracowników:
                                  </div>
                                  <div className="order-preview__installation-value">
                                    {measurement.numberOfAssemblyTeams}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </>
                    )}
                  {!isMeasurer && (
                    <>
                      {isInstallationToBeReleased && (
                        <MeasurementArrangedInstallation
                          userRole={userRole}
                          orderDetails={orderDetails}
                          handleClickAppointment={handleClickAppointment}
                        />
                      )}
                      <div className="am-separator" />
                      <div className="modal__information-header">Oferta</div>
                      <div className="order-preview__information-file">
                        {orderDetails?.files.length > 0 &&
                          orderDetails?.files.map((item) => {
                            return (
                              <>
                                <FileItem
                                  name={item.name as MEASUREMENTS_TYPE}
                                />
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                  {(isMeasurer || isFitter) && (
                    <Button
                      className="order-preview__measurer-button"
                      label="Wykonaj"
                      onClick={() => handleClickMeasurement()}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default OrderPreview;
