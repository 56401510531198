import { useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { useMemo, useEffect } from "react";

type OrderLabel = {
  label: string;
  value: string;
};

type UseGetOrderLabelsProps = {
  period: string;
};

export default function useGetOrderLabels({ period }: UseGetOrderLabelsProps) {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ["order-labels", period], [period]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey });
  }, [period, queryKey, queryClient]);

  return useQuery<OrderLabel[], Error>({
    queryKey,
    queryFn: async () => {
      const response = await axiosInstance.get(`/order-labels?period=${period}`);
      return response.data.orderLabels;
    },
    retry: false,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
}
