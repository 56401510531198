import { Input, Select, Textarea } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
  TypeOfRadiobox,
} from "features/addingMeasurement";

interface RollerGateFormType {
  touched: any;
  errors: any;
  validateField: any;
  values: any;
}

const RollerGateForm = ({
  touched,
  errors,
  validateField,
  values,
}: RollerGateFormType) => {
  return (
    <>
      <Field
        errors={errors}
        name="assemblyType"
        label="Typ montażu"
        options={[
          {
            label: "We wnęce",
            value: "inTheAlcove",
            uuid: "inTheAlcove",
          },
          {
            label: "Na ścianie na zewnątrz",
            value: "onTheWallOutside",
            uuid: "onTheWallOutside",
          },
          {
            label: "Na ścianie wewnątrz",
            value: "onTheWallInside",
            uuid: "onTheWallInside",
          },
        ]}
        component={TypeOfRadiobox}
      />
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        touched={touched}
        name="lintelHeight"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          maxLength={10}
          label="Wysokość nadproża"
          rightPlaceholder="mm"
          type="number"
          name="lintelHeight"
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        className="padding-top-separator single-section-element--strips"
        touched={touched}
        name={["gateSizeWidth", "gateSizeHeight"]}
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="gateSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="gateSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
      </SingleSectionElement>

      <Field
        errors={errors}
        name="cableExitSide"
        label="Strona wyjścia kabla"
        options={[
          {
            label: "Bok",
            value: "side",
            uuid: "side",
          },
          {
            label: "Tył",
            value: "back",
            uuid: "back",
          },
          {
            label: "Przód",
            value: "front",
            uuid: "front",
          },
        ]}
        component={TypeOfRadiobox}
      />

      <Field
        errors={errors}
        half
        name="typeOfDrive"
        label="Rodzaj napędu"
        options={[
          {
            label: "Radiowy plus klawisz",
            value: "radioPlusKey",
            uuid: "radioPlusKey",
          },
          {
            label: "Klawisz",
            value: "key",
            uuid: "key",
          },
          {
            label: "Ręczny",
            value: "manual",
            uuid: "manual",
          },
          {
            label: "Wifi",
            value: "wifi",
            uuid: "wifi",
          },
          {
            label: "Radiowy",
            value: "radio",
            uuid: "radio",
          },
        ]}
        component={TypeOfRadiobox}
      />

      <SingleSectionElement
        touched={touched}
        name="sideOfThePowerCable"
        errors={errors}
        className="padding-top-separator padding-bottom-separator"
      >
        <Field
          label="Strona kabla zasilającego/linki (widok od wewnątrz)"
          name="sideOfThePowerCable"
          type="number"
          options={[
            {
              value: "right",
              label: "Prawa",
            },
            {
              value: "left",
              label: "Lewa",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>

      <Field
        errors={errors}
        maxLength={30}
        label="Kolor"
        optional
        name={`color`}
        number
        component={Input}
      />

      <div className="am-separator" />

      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        name="comment"
        optional
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default RollerGateForm;
