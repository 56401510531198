import { Button, Modal } from "components";
import { useDeleteUsedAssemblyMaterial } from "hooks/assembly-materials";

interface DeleteUsedAssemblyMaterialType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteUsedAssemblyMaterial = ({
  isOpen,
  onClose,
  data,
}: DeleteUsedAssemblyMaterialType) => {
  const mutation = useDeleteUsedAssemblyMaterial();

  const handleDeleteUsedAssemblyMaterial = async (uuid: string) => {
    await mutation.mutate({ uuid: uuid });
    onClose();
  };

  return (
    <>
      <Modal
        className="delete-activity"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading">Usuwanie zużytego materiału</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć zużyty materiał
            <span>{data.assemblyName}</span>?
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button
              onClick={() => handleDeleteUsedAssemblyMaterial(data.uuid)}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteUsedAssemblyMaterial;
