/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Modal, Button, SVG, StaticAlert, Signature } from "components";
import { SVG_TYPE } from "enums";
import moment from "moment";
import "./receipt-protocol.scss";

interface CheckListItem {
  label: string;
  value: string;
  required: boolean;
  checked: boolean;
}

interface Measurement {
  measurementName: string;
  typeOfMeasurementId: number;
  checkList: CheckListItem[];
}

interface ReceiptProtocolProps {
  isOpen: boolean;
  onClose: () => void;
  checkList: Measurement[];
  orderDetails: any;
}

const ReceiptProtocol = ({
  isOpen,
  onClose,
  checkList,
  orderDetails,
}: ReceiptProtocolProps) => {
  const [wasClientPresent, setWasClientPresent] = useState(false);
  const [isSignatureError, setIsSignatureError] = useState(false);
  const [isClientSignatureEmpty, setIsClientSignatureEmpty] = useState(true);
  const [isEmployeeSignatureEmpty, setIsEmployeeSignatureEmpty] =
    useState(true);
  const filterCheckedItems = (checkLists: Measurement[]): Measurement[] => {
    return checkLists.map((measurement) => {
      const filteredCheckList = measurement.checkList.filter(
        (item) => item.checked
      );
      return {
        ...measurement,
        checkList: filteredCheckList,
      };
    });
  };
  const protocolDate = moment().format("D/MM/YYYY");

  const handleClickCheckbox = () => {
    setWasClientPresent(!wasClientPresent);
    if (!wasClientPresent) {
      setIsSignatureError(false);
      clearClientSignature();
    }
  };

  const clearClientSignature = () => {
    setIsClientSignatureEmpty(true);
  };

  const validateSignatures = () => {
    const isEmployeeSignatureValid = !isEmployeeSignatureEmpty;
    const isClientSignatureValid = wasClientPresent || !isClientSignatureEmpty;
    return isEmployeeSignatureValid && isClientSignatureValid;
  };

  useEffect(() => {
    if (isSignatureError && validateSignatures()) {
      setIsSignatureError(false);
    }
  }, [isClientSignatureEmpty, isEmployeeSignatureEmpty]);

  const handleSaveProtocol = () => {
    if (!validateSignatures()) {
      setIsSignatureError(true);
    } else {
      setIsSignatureError(false);
      console.log("Powinniśmy to wysłać.");
    }
  };

  return (
    <>
      <Modal className="full-page am" isOpened={isOpen} onClose={onClose}>
        <div className="am__content">
          <div className="am-top">
            <Button
              label="Zamknij"
              stroke
              size="medium"
              svgType={SVG_TYPE.CLOSE}
              onClick={() => {
                onClose();
                setIsSignatureError(false);
              }}
            />
          </div>
          <div className="receipt-protocol">
            <div className="receipt-protocol__top">
              <div className="receipt-protocol__heading">
                Protokół odbioru montażu
              </div>
              <div className="receipt-protocol__name">{orderDetails.name}</div>
            </div>
            <div className="receipt-protocol__content">
              <div className="receipt-protocol__halfs">
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Zamawiajcy
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {orderDetails.clientName ||
                      "Brak informacji o imieniu i nazwisku klienta"}
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {orderDetails.phoneNumber || "Brak telefonu"}
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {orderDetails.email || "Brak informacji o mailu"}
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {orderDetails.address || "Brak danych adresowych"}
                  </div>
                </div>
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Wykonawca
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    Martdom Marcin Cichy
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    +48 660 905 085
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    biuro@martdom.pl
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    ul. 29 Listopada 22 <br /> 32-090 Słomniki
                  </div>
                </div>
              </div>
              <div className="receipt-protocol__box">
                <div className="receipt-protocol__box__heading">
                  Wykonane prace
                </div>
                {checkList &&
                  filterCheckedItems(checkList).map(
                    ({ measurementName, checkList }) => {
                      return (
                        <>
                          <div className="receipt-protocol__checklist__element">
                            {measurementName}
                          </div>
                          {checkList.map(({ label }) => {
                            return (
                              <div className="receipt-protocol__checklist__element">
                                <div className="receipt-protocol__checklist__icon">
                                  <SVG type={SVG_TYPE.CHECK_CIRCLE} />
                                </div>
                                {label}
                              </div>
                            );
                          })}
                        </>
                      );
                    }
                  )}
              </div>
              <div className="receipt-protocol__box">
                <div className="receipt-protocol__box__heading">
                  Uwagi od klienta
                </div>
                <div className="receipt-protocol__checklist__element">
                  Lorem ipsum dolor sit amet consectetur. Egestas senectus
                  tellus enim quis nunc fermentum diam sapien. Sed commodo eu
                  donec sit auctor orci nulla neque et. Nibh urna a eu aliquet
                  duis ipsum amet et habitant. Tempus ipsum.
                </div>
              </div>
              <div className="receipt-protocol__halfs">
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Termin wykonania montażu
                  </div>
                  <div className="receipt-protocol__checklist__element">
                    Rozpoczęcie robót: {protocolDate}
                  </div>
                  <div className="receipt-protocol__checklist__element">
                    Zakończenie robót: {protocolDate}
                  </div>
                </div>
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Data sporządzenia protokołu
                  </div>
                  <div className="receipt-protocol__checklist__element">
                    {protocolDate}
                  </div>
                </div>
              </div>
              <div className="receipt-protocol__box">
                <div className="receipt-protocol__box__heading">
                  Lista rzeczy pozostawionych u klienta
                </div>
                <ul className="receipt-protocol__list">
                  <li className="receipt-protocol__list-item">
                    Lorem ipsum dolor sit amet consectetur
                  </li>
                  <li className="receipt-protocol__list-item">
                    Lorem ipsum dolor sit amet consectetur
                  </li>
                  <li className="receipt-protocol__list-item">
                    Lorem ipsum dolor sit amet consectetur
                  </li>
                </ul>
              </div>
              <div className="receipt-protocol__rules">
                Zamawiający oświadcza, że dokładnie sprawdził zamontowaną
                stolarkę i nie stwierdza żadnych uszkodzeń mechanicznych
                produktów. Późniejsze zgłoszenia wad produktu, widocznych w
                momencie odbioru stolarki, nie będą uznawane.
              </div>
              <div className="receipt-protocol__halfs">
                <div
                  className={`receipt-protocol__box receipt-protocol__half ${
                    wasClientPresent ? "receipt-protocol__box--disabled" : ""
                  }`}
                >
                  <Signature
                    type="client"
                    onCanvasStateChange={setIsClientSignatureEmpty}
                    isActiveClear={isClientSignatureEmpty}
                    clearCanvasTrigger={clearClientSignature}
                  />
                </div>
                <div className="receipt-protocol__box receipt-protocol__half">
                  <Signature
                    type="employee"
                    onCanvasStateChange={setIsEmployeeSignatureEmpty}
                  />
                </div>
              </div>
              <div className="receipt-protocol__halfs">
                <div className="receipt-protocol__half">
                  <StaticAlert
                    show={isSignatureError}
                    label="To pole jest wymagane. Uzupełnij podpis lub zaznacz “Brak klienta na odbiorze”."
                  />
                </div>
                <div className="receipt-protocol__half"></div>
              </div>
              <div className="receipt-protocol__client">
                <input
                  name="client"
                  id="client"
                  type="checkbox"
                  className="receipt-protocol__checkbox"
                  defaultChecked={wasClientPresent}
                  onClick={handleClickCheckbox}
                />
                <label htmlFor="client">Brak klienta na odbiorze</label>
              </div>
              <div className="receipt-protocol__buttons">
                <Button label="Zapisz protokół" onClick={handleSaveProtocol} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReceiptProtocol;
