import { Button, Modal, Input, RateInput, Select } from "components";
import { Field, Formik } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import { useAddAssemblyMaterial } from "hooks/assembly-materials";
import * as Yup from "yup";

interface AddAssemblyMaterialsType {
  isOpen: boolean;
  onClose: () => void;
  suppliersLabel: { label: string; value: string }[] | undefined;
}

const AddAssemblyMaterials = ({
  isOpen,
  onClose,
  suppliersLabel,
}: AddAssemblyMaterialsType) => {
  const { mutate: addAssemblyMaterial } = useAddAssemblyMaterial({
    onSuccess: async (data) => {
      onClose();
      setTimeout(() => {
        document.getElementById("pagination-last-page")?.click();
      }, 100);
    },
  });

  const initialValues = {
    name: "",
    cost: "",
    supplierUuid: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(
        /^[^\s].*[^\\s]?$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
    cost: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .matches(
        /^[^\s].*[^\\s]?$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
    supplierUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = async (values: any) => {
    addAssemblyMaterial({ ...values });
  };
  return (
    <>
      <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Dodawanie materiału montażowego</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <form className="add-user__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Nazwa materiału"
                  name="name"
                  maxLength={40}
                  component={Input}
                />
                <Field
                  errors={errors}
                  label="Koszt materiału"
                  rightPlaceholder={"zł/szt"}
                  name="cost"
                  component={RateInput}
                  maxLength={6}
                />
                <Field
                  errors={errors}
                  label="Dostawca"
                  name="supplierUuid"
                  maxLength={20}
                  component={Select}
                  options={suppliersLabel}
                />
                <div className="modal-buttons">
                  <Button stroke onClick={() => onClose()} label="Anuluj" />
                  <Button onClick={() => handleSubmit()} label="Potwierdź" />
                </div>
              </form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default AddAssemblyMaterials;
