import { Button, Modal } from "components";
import { useDeleteUser } from "hooks/users";

interface DeleteUserType {
  user: any;
  isOpen: boolean;
  onClose: () => void;
  setPage: any;
  page: number;
}

const DeleteUser = ({
  isOpen,
  onClose,
  user,
  setPage,
  page,
}: DeleteUserType) => {
  // const { mutate: deleteUser } = useDeleteUser({
  //   onSuccess: ({ user }) => {
  //     setPage(user.page);
  //   },
  // });
  const { mutate: deleteUser } = useDeleteUser({
    onSuccess: ({ user }) => {
      setPage((prevPage: number) => {
        const newPage = user.page;
        if (newPage <= prevPage && Math.abs(prevPage - newPage) <= 1) {
          return newPage;
        }
        return prevPage;
      });
    },
  });

  const handleDeleteUser = async (uuid: string) => {
    await deleteUser({ uuid });
    onClose();
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Usuwanie użytkownika</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć użytkownika
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button
              onClick={() => handleDeleteUser(user.uuid)}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteUser;
