import { useState } from "react";
import "./photos.scss";
import { PhotosSection, PhotosModal } from "features/Photos";

interface PhotosType {
  label: string;
}

const Photos = ({ label }: PhotosType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <PhotosModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <PhotosSection label={label} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default Photos;
