import { ContextMenu, SVG, TableColumnHeaderDropDown } from "components";
import { SVG_TYPE } from "enums";
import { materialType } from "types/MaterialTypes";

const items = (
  editAssemblyMaterial: (material: materialType) => void,
  hidingAssemblyMaterial: (material: any) => void,
  historyAssemblyMaterial: (material: materialType) => void,
  isHiddenMaterial: boolean,
  isLastElement: boolean
) => {
  return (material: materialType) => {
    const actions = [
      {
        name: "Edytuj",
        action: () => editAssemblyMaterial(material),
      },
      {
        name: "Historia cen",
        action: () => historyAssemblyMaterial(material),
      },
      {
        name: isHiddenMaterial ? "Przywróć" : "Ukryj",
        action: () =>
          hidingAssemblyMaterial({ ...material, isLastElement: isLastElement }),
      },
    ];
    return actions;
  };
};

export const mobileColumns = () => [
  {
    key: "name",
    dataIndex: "name",
    width: "60%",
    render: (values: any) => (
      <div className="assembly-material__name">{`${values}`}</div>
    ),
  },
  {
    key: "cost",
    dataIndex: "cost",
    width: "40%",
    render: (value: string) => {
      return <div className="assembly-material__cost">{`${value} zł/szt`}</div>;
    },
  },
];

export const desktopColumns = (
  currentPage: number,
  perPage: number,
  editAssemblyMaterial: (material: materialType) => void,
  hidingAssemblyMaterial: (material: materialType) => void,
  historyAssemblyMaterial: (material: materialType) => void,
  isHiddenMaterial: boolean,
  suppliersLabel: any,
  setActiveSupplier: (data: { label: string; value: string }) => void,
  refetch: () => void,
  isLastElement: boolean
) => [
  {
    title: "Lp.",
    key: "index",
    width: 68,
    render: (value: any, record: any, index: number) =>
      (currentPage - 1) * perPage + index + 1,
  },
  {
    title: "Nazwa materiału",
    key: "name",
    dataIndex: "name",
    width: 519,
  },
  {
    title: (
      <TableColumnHeaderDropDown
        parameter="supplier"
        itemOnChange={(item: any) => setActiveSupplier(item)}
        removeActiveItem={() => {
          setActiveSupplier({ label: "", value: "" });
          setTimeout(() => {
            refetch();
          }, 100);
        }}
        select={suppliersLabel}
        label="Dostawca"
      />
    ),
    width: 280,
    render: (values: any) => {
      return <div>{values?.supplier?.name}</div>;
    },
  },
  {
    title: "Koszt",
    width: 350,
    render: (value: any) => {
      return (
        <div className="employee-rates-table__rate-wrapper">
          <div className="employee-rates-table__rate">
            {`${value.cost} zł/szt`}{" "}
          </div>
          <div className="employee-rates-table__rate-change">
            {`${
              value.plannedCost
                ? `Zmiana od: ${value.plannedCost.replace(/\//g, ".")}`
                : " "
            }`}
          </div>
        </div>
      );
    },
  },
  {
    title: "Akcje",
    width: 88,
    render: (item: any) => (
      <ContextMenu
        data={item}
        items={items(
          editAssemblyMaterial,
          hidingAssemblyMaterial,
          historyAssemblyMaterial,
          isHiddenMaterial,
          isLastElement
        )(item)}
      >
        <SVG type={SVG_TYPE.ELLIPSIS} />
      </ContextMenu>
    ),
  },
];
