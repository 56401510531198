import moment from "moment";

const createDate = (dateStr: string, timeStr: string): Date => {
    const [day, month, year] = dateStr
      .split("/")
      .map((num) => parseInt(num, 10));
    const [hour, minute = 0] = timeStr
      .split(":")
      .map((num) => parseInt(num, 10));
    return new Date(year, month - 1, day, hour, minute);
  };

export const convertToEvents = (orders: any, color: string = '') => {
  const sortedEvents = [...orders].sort((a, b) => {
    const dateA = moment(`${a.date} ${a.start}`, "DD/MM/YYYY HH:mm").toDate();
    const dateB = moment(`${b.date} ${b.start}`, "DD/MM/YYYY HH:mm").toDate();
    return dateA.getTime() - dateB.getTime();
  });
  return orders && sortedEvents && sortedEvents.map((order: any, index: number) => ({
    title: order.title || order.name,
    start: createDate(order.date, order.start),
    end: createDate(order.date, order.end),
    original: color ? { ...order, userColor: color } : { ...order },
    index
  }));
};