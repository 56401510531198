enum SVG_TYPE {
  ARROW_BACK = 'ARROW_BACK',
  LOGO = 'LOGO',
  LOGO_WHITE = 'LOGO_WHITE',
  EYE = 'EYE',
  EYE_OFF = 'EYE_OFF',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_TOP = 'CHEVRON_TOP',
  ALERT = 'ALERT',
  ACCEPT_GREEN = 'ACCEPT_GREEN',
  CLOSE_CIRCLE = 'CLOSE_CIRCLE',
  ELLIPSIS = 'ELLIPSIS',
  USERS = 'USERS',
  DASHBOARD = 'DASHBOARD',
  ORDERS_LIST = 'ORDERS_LIST',
  EMPLOYEE_RATES = 'EMPLOYEE_RATES',
  ASSEMBLY_MATERIALS = 'ASSEMBLY_MATERIALS',
  LOGOUT = 'LOGOUT',
  CLOSE = 'CLOSE',
  CALENDAR = 'CALENDAR',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  PLAY = "PLAY",
  STOP = "STOP",
  PLUS = "PLUS",
  CLOCK = "CLOCK",
  GO_BACK = 'GO_BACK',
  EDIT = 'EDIT',
  TEXT = 'TEXT',
  ERASER = 'ERASER',
  TRASH = 'TRASH',
  TRASH_SECOND = 'TRASH_SECOND',
  SEARCH = 'SEARCH',
  BIN = 'BIN',
  EDIT_PENCIL = 'EDIT_PENCIL',
  EXCLAMATION = 'EXCLAMATION',
  EQUAL = 'EQUAL',
  USER = 'USER',
  CHECK_CIRCLE = 'CHECK_CIRCLE',
}

export default SVG_TYPE;
