import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type LabelMaterialType = {
  label: string;
  value: string;
  cost: string;
};

export default function useGetAssemblyMaterialSelect() {
  return useQuery({
    queryKey: ["assemblyMaterialLabel"],
    queryFn: async () => {
      return axiosInstance.get(`/assembly-materials/label`).then((res) => res.data);
    },
    select: (data: { labelMaterial: LabelMaterialType[] }) => data?.labelMaterial,
    retry: false,
    staleTime: 0,
  });
}
