import { Button, Modal } from "components";
import "./employee-time-by-date.scss";
import { EntryType, WorkTimeType } from "types/StatisticTypes";

interface EmployeeTimeByDateType {
  times: WorkTimeType[];
  isOpen: boolean;
  onClose: () => void;
}

const EmployeeTimeByDate = ({
  isOpen,
  onClose,
  times,
}: EmployeeTimeByDateType) => {
  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    const formattedHours = hours > 0 ? `${hours}h` : "";
    const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
    return `${formattedHours} ${formattedMinutes}`.trim();
  };

  return (
    <>
      <Modal className="etbd" isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Podgląd zaraportowanego czasu</div>
          <ul className="etbd__list">
            <li className="etbd__list-heading">
              <span className="etbd__list--job">Zlecenie</span>
              <span className="etbd__list--task">Zadanie</span>
              <span className="etbd__list--time">Czas</span>
            </li>
            {times &&
              times.length > 0 &&
              times.map((item: WorkTimeType) => {
                return (
                  <>
                    {item.entries &&
                      item.entries.length > 0 &&
                      item.entries.map((entry: EntryType) => {
                        return (
                          <div>
                            <li className="etbd__item">
                              <span className="etbd__item--job">
                                <div className="working-time-bar__user single-timing--user">
                                  {entry.userSymbols}
                                </div>
                                {item.orderName}
                              </span>
                              <span className="etbd__item--task">
                                {item.activityName}
                              </span>
                              <span className="etbd__item--time">
                                {formatTime(entry.countedTime)}
                              </span>
                            </li>
                          </div>
                        );
                      })}
                  </>
                );
              })}
          </ul>
          <div className="modal-buttons">
            <Button onClick={() => onClose()} label="Zamknij" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default EmployeeTimeByDate;
