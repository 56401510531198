import { OrderStatus, Button } from "components";
import { ROUTE_ENUM, USER_ROLE, ORDER_ENUM } from "enums";

const isInstallationToBeReleased = (status: string) =>
  status === "installationToBeReleased" && true;

const handleButtonClick = (
  e: any,
  record: any,
  navigate: any,
  setSearchParams: any,
  page: number | string,
  userRole: string,
  activeFloatingMenuItem: any
) => {
  const isMeasurer = userRole === USER_ROLE.measurer;
  const isFitter = userRole === USER_ROLE.fitter;
  const isFitterOrMeasurer = isMeasurer || isFitter;
  e.stopPropagation();
  setSearchParams({
    page,
    uuid: record.uuid,
    tab: activeFloatingMenuItem?.value,
  });

  const isFixedInstallation =
    activeFloatingMenuItem?.value === ORDER_ENUM.FIXED_INSTALLATION;

  if (isFixedInstallation) {
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${record.uuid}&tab=${
        ORDER_ENUM.INSTALLATION_TO_BE_RELEASED
      }${`&edit=true`}`
    );
  } else {
    navigate(
      `${
        isFitterOrMeasurer
          ? ROUTE_ENUM.ACTIVE_ORDER
          : ROUTE_ENUM.ARRANGING_MEASUREMENT
      }?${
        isFitterOrMeasurer
          ? `orderUuid=${record.uuid}&page=${page}&tab=${activeFloatingMenuItem?.value}`
          : `uuid=${record.uuid}&tab=${activeFloatingMenuItem?.value}`
      }`
    );
  }
};

export const desktopColumns = (
  navigate: any,
  setSearchParams: any,
  page: any,
  userRole: string,
  activeFloatingMenuItem: any
) => {
  const isFixedInstallation =
    activeFloatingMenuItem?.value === ORDER_ENUM.FIXED_INSTALLATION;
  const isAdmin = USER_ROLE.admin === userRole;

  if (isFixedInstallation) {
    return [
      {
        title: "Nazwa zlecenia",
        dataIndex: "name",
        key: "name",
        width: 250,
      },
      {
        title: "Imię i nazwisko klienta",
        dataIndex: "clientName",
        key: "clientName",
        width: 220,
      },
      {
        title: "Przypisany pracownik",
        width: 220,
      },
      {
        title: "Termin pomiaru",
        width: 220,
      },
      {
        title: "Status",
        width: 212,
        render: (original: any) => {
          return (
            <div className="order-status__table">
              <OrderStatus status={original.status} />
            </div>
          );
        },
      },
      {
        title: "Akcje",
        width: 144,
        render: (record: any) => {
          return (
            <Button
              onClick={(e: any) =>
                handleButtonClick(
                  e,
                  record,
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem
                )
              }
              className="order-status__action-btn"
              label="Edycja"
              size="small"
            />
          );
        },
      },
    ];
  } else {
    const columns = [
      {
        title: "Nazwa zlecenia",
        dataIndex: "name",
        key: "name",
        width: 250,
      },
      {
        title: "Imię i nazwisko klienta",
        dataIndex: "clientName",
        key: "clientName",
        width: 220,
      },
      {
        title: "Telefon",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: 152,
      },
      {
        title: "Adres",
        dataIndex: "address",
        key: "address",
        width: 276,
      },
      {
        title: "Status",
        width: 212,
        render: (original: any) => (
          <div className="order-status__table">
            <OrderStatus status={original.status} />
            {isInstallationToBeReleased(original.status) && !isAdmin && (
              <div className="order-status__counters">
                {original.arrangedInstallation}/{original.allInstallation}
              </div>
            )}
          </div>
        ),
      },
    ];

    if (!isAdmin) {
      columns.push({
        title: "Akcje",
        width: 144,
        render: (record: any) => {
          const isStatusFixedMeasurements =
            record.status === ORDER_ENUM.FIXED_MEASUREMENTS;
          const isStatusFixedInstallation =
            record.status === ORDER_ENUM.FIXED_INSTALLATION;
          const isStatusFixedVerification =
            record.status === ORDER_ENUM.FIXED_VERIFICATION;
          const isEdit =
            isStatusFixedMeasurements ||
            isStatusFixedInstallation ||
            isStatusFixedVerification;
          const isMeasurer = userRole === USER_ROLE.measurer;
          const isFitter = userRole === USER_ROLE.fitter;
          const isFitterOrMeasurer = isMeasurer || isFitter;
          return (
            <Button
              onClick={(e: any) =>
                handleButtonClick(
                  e,
                  record,
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem
                )
              }
              className="order-status__action-btn"
              label={
                isFitterOrMeasurer ? "Wykonaj" : isEdit ? "Edytuj" : "Umów"
              }
              size="small"
            />
          );
        },
      });
    }

    return columns;
  }
};

export const mobileColumns = (
  navigate: any,
  setSearchParams: any,
  page: any,
  userRole: string,
  activeFloatingMenuItem: any
) => [
  {
    dataIndex: "status",
    key: "status",
    width: 20,
    render: (original: any) => <OrderStatus status={original} />,
  },
  {
    dataIndex: "name",
    key: "name",
    width: 350,
  },
  {
    width: 144,
    render: (record: any) => {
      const isStatusFixedMeasurements =
        record.status === ORDER_ENUM.FIXED_MEASUREMENTS;
      const isStatusFixedInstallation =
        record.status === ORDER_ENUM.FIXED_INSTALLATION;
      const isStatusFixedVerification =
        record.status === ORDER_ENUM.FIXED_VERIFICATION;
      const isEdit =
        isStatusFixedMeasurements ||
        isStatusFixedInstallation ||
        isStatusFixedVerification;
      const isMeasurer = userRole === USER_ROLE.measurer;
      const isFitter = userRole === USER_ROLE.fitter;
      const isFitterOrMeasurer = isMeasurer || isFitter;
      return (
        <Button
          onClick={(e: any) =>
            handleButtonClick(
              e,
              record,
              navigate,
              setSearchParams,
              page,
              userRole,
              activeFloatingMenuItem
            )
          }
          className="order-status__action-btn"
          label={isFitterOrMeasurer ? "Wykonaj" : isEdit ? "Edytuj" : "Umów"}
          size="small"
        />
      );
    },
  },
];
