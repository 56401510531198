import { VerifiedMeasurement } from "types/OrderTypes";
import { OrderType, ArchivalDateType } from "types/OrderTypes";
import { ARCHIVAL_TYPE } from "enums";

interface ArchivalDatesDetailsProps {
  orderDetails: OrderType | undefined;
  hideVerification?: boolean;
}

const ArchivalDatesDetails = ({
  orderDetails,
  hideVerification = false,
}: ArchivalDatesDetailsProps) => {
  const isMeasurementsAttempt = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.MEASUREMENTS_ATTEPMT
  );
  const isMeasurementsTaken = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.MEASUREMENTS_TAKEN
  );
  const isVerificationVisit = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.VERIFICATION_VISIT
  );

  return (
    <div className="archival-dates">
      {isMeasurementsAttempt && isMeasurementsAttempt.length > 0 && (
        <>
          <div className="modal__information-header">
            Próby wykonania pomiaru
          </div>
          {isMeasurementsAttempt.map(
            ({ date, start, employee, comment }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employee.firstName} {employee.lastName}
                        </div>
                      </div>
                    </div>
                    <div className="failed-meeting-attempt__separator" />
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Komentarz:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {comment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}

      {isMeasurementsTaken && isMeasurementsTaken.length > 0 && (
        <>
          <div className="modal__information-header">
            Data wykonania pomiaru
          </div>
          {isMeasurementsTaken.map(
            ({ date, start, employee, comment }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employee.firstName} {employee.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      {isVerificationVisit && isVerificationVisit.length > 0 && (
        <>
          <div className="modal__information-header">
            Poprzednia weryfikacja
          </div>
          {isVerificationVisit.map(
            ({ date, start, employee, comment }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}

      {orderDetails?.verificationParams &&
        orderDetails?.verificationParams?.length > 0 &&
        !hideVerification && (
          <>
            <div className="modal__information-header">Do weryfikacji</div>
            <div className="verified-list">
              {orderDetails.verificationParams.map(
                (item: VerifiedMeasurement) => {
                  return (
                    <>
                      <div className="verified-list__measurement">
                        {item.label}
                      </div>
                      {item.toFix &&
                        item.toFix.length > 0 &&
                        item.toFix.map((fix) => (
                          <div className="verified-list__toFix">
                            {typeof fix === "string"
                              ? fix
                              : `${fix.label} - ${fix.type} (${
                                  fix.state ? "true" : "false"
                                })`}
                          </div>
                        ))}
                    </>
                  );
                }
              )}
            </div>
          </>
        )}
    </div>
  );
};

export default ArchivalDatesDetails;
