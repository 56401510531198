/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import "./building-material-component.scss";
import { StaticAlert, CheckboxPart } from "components";

interface TypeOfInstalationGarageType {
  field: any;
  errors?: any;
  form: any;
}

const TypeOfInstalationGarage = ({
  field,
  form,
  errors,
}: TypeOfInstalationGarageType) => {
  const [buildingMaterialObject, setBuildingMaterialObject] = useState(
    form.values.typeOfInstallation || ""
  );

  useEffect(() => {
    setBuildingMaterialObject(form.values.typeOfInstallation);
  }, [form.values[field.name]]);

  const BuildingMaterialCheckbox = [
    {
      label: "Demontaż, montaż, obróbka",
      value: "disassemblyAassemblyProcessing",
      uuid: "disassemblyAassemblyProcessing",
    },
    {
      label: "Demontaż, montaż",
      value: "disassemblyAassembly",
      uuid: "disassemblyAassembly",
    },
    {
      label: "Demontaż bramy przez klienta (montaż w przygotowany otwór)",
      value: "disassemblyByTheCustomer",
      uuid: "disassemblyByTheCustomer",
    },
    {
      label: "Stan surowy",
      value: "rawCondition",
      uuid: "rawCondition",
    },
  ];

  const handleCheckMaterial = (item: any) => {
    if (buildingMaterialObject === item.value) {
      setBuildingMaterialObject("");
    } else {
      setBuildingMaterialObject(item.value);
    }
  };

  useEffect(() => {
    form.setFieldValue(field.name, buildingMaterialObject);
  }, [buildingMaterialObject]);

  return (
    <div className="building-material-component">
      <div className="am-section-heading">Rodzaj montażu</div>
      <div className="am-section-checkbox-list construction-site__list">
        {BuildingMaterialCheckbox.map((item: any) => {
          return (
            <CheckboxPart
              className="radiobox"
              onChange={() => handleCheckMaterial(item)}
              checked={buildingMaterialObject === item.value}
              key={item.value}
              label={item.label}
              uuid={item.value}
            />
          );
        })}
      </div>
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      <div className="am-separator" />
    </div>
  );
};

export default TypeOfInstalationGarage;
