import { Button, Input, Switch, SVG, Textarea, StaticAlert } from "components";
import { FieldArray, Field } from "formik";
import "./window-sills-with-drawing.scss";
import { SVG_TYPE } from "enums";
import { FewSketches } from "components/form";

interface WindowSillsWithDrawingType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
}

const WindowSillsWithDrawing = ({
  errors,
  form,
  field,
}: WindowSillsWithDrawingType) => {
  const handleRemoveWindowSill = (
    removeSill = false,
    arrayHelpers: any,
    index: number
  ) => {
    if (removeSill) {
      arrayHelpers.remove(index);
    }
    const updatedDrawings = [...form.values.drawing];
    updatedDrawings.splice(index, 1);
    form.setFieldValue("drawing", updatedDrawings, false);
    form.setFieldValue("file", updatedDrawings, false);
    setTimeout(() => {
      form.setFieldTouched(`windowSill`, true);
      form.setFieldTouched(`drawing`, true);
      form.setFieldTouched(`file`, true);
    }, 0);
  };

  const mathRandom = () => Math.random().toString(36).substring(7);

  return (
    <div className="hole-dimension wswd">
      <div>
        <FieldArray
          name="windowSill"
          render={(arrayHelpers) => (
            <div>
              <div className="am-section-heading wswd__heading">
                <h3>Parapety</h3>
                {form.values?.windowSill.length === 0 && (
                  <Button
                    size="medium"
                    stroke
                    label="Dodaj parapet"
                    onClick={() => {
                      arrayHelpers.push({
                        id: mathRandom(),
                        width: "",
                        length: "",
                        amount: "",
                        drawing: "",
                        comments: "",
                        connectingWithAnother: false,
                      });
                    }}
                  />
                )}
              </div>
              {form.values?.windowSill.map((sill: any, index: any) => {
                return (
                  <div key={sill.id} className="wswd__item">
                    <div className="wswd__row">
                      <div
                        className="wswd__delete"
                        onClick={() =>
                          handleRemoveWindowSill(true, arrayHelpers, index)
                        }
                      >
                        <SVG type={SVG_TYPE.BIN} />
                        Usuń ten parapet
                      </div>
                    </div>
                    <Field
                      errors={errors}
                      label="Szerokość"
                      type="number"
                      name={`windowSill.${index}.width`}
                      component={Input}
                      maxLength={10}
                      rightPlaceholder="mm"
                    />
                    <Field
                      errors={errors}
                      label="Długość"
                      type="number"
                      name={`windowSill.${index}.length`}
                      maxLength={10}
                      component={Input}
                      rightPlaceholder="mm"
                    />
                    <Field
                      errors={errors}
                      label="Ilość"
                      type="number"
                      maxLength={10}
                      name={`windowSill.${index}.amount`}
                      component={Input}
                      rightPlaceholder="szt."
                    />
                    <Field
                      checked={
                        form.values?.windowSill[index].connectingWithAnother
                      }
                      label="Łączenie z innym parapetem"
                      onChange={(e: any) =>
                        !e.target.checked &&
                        handleRemoveWindowSill(false, arrayHelpers, index)
                      }
                      name={`windowSill.${index}.connectingWithAnother`}
                      component={Switch}
                    />
                    {form.values?.windowSill[index].connectingWithAnother && (
                      <>
                        <FewSketches
                          errors={errors}
                          name={`windowSill.${index}.drawing`}
                          key={sill.id}
                          form={form}
                          index={index}
                          label="Rysunek pomieszczenia"
                        />
                        <Field
                          errors={errors}
                          className="am-margin-top"
                          label="Komentarz"
                          name={`windowSill.${index}.comments`}
                          component={Textarea}
                          maxLength={250}
                        />
                      </>
                    )}
                    {form.values?.windowSill?.length > index + 1 && (
                      <div className="am-separator" />
                    )}
                  </div>
                );
              })}
              {form.values?.windowSill.length > 0 &&
                form.values.windowSill.length <= 19 && (
                  <Button
                    stroke
                    label="Dodaj parapet"
                    size="medium"
                    className="wswd__add-button"
                    onClick={() => {
                      arrayHelpers.push({
                        width: "",
                        length: "",
                        amount: "",
                        drawing: "",
                        comments: "",
                        connectingWithAnother: false,
                      });
                    }}
                  />
                )}
              {errors && form.touched && (
                <>
                  <StaticAlert
                    className="am-alert"
                    size="small"
                    show={errors[field.name] && errors[field.name]}
                    label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
                  />
                </>
              )}
              <div className="am-separator" />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default WindowSillsWithDrawing;
