import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';


const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export default function useGetTimeManagement(orderUuid: string | null, options = {}) {
  return useQuery({
    queryKey: ['timeManagement', orderUuid],
    queryFn: async () => {
      await delay(500); 
      return axiosInstance
        .get(`/activity/time-management?orderUuid=${orderUuid}`)
        .then((res) => res.data);
    },
    select: (data: {activityMeasurementLabel:any[]}) => data.activityMeasurementLabel,
    retry: false,
    enabled: !!orderUuid,
    ...options,
  });
}