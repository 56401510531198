import { useState } from "react";
import {
  Button,
  Modal,
  Input,
  RateInput,
  CalendarInput,
  Select,
  StaticAlert,
} from "components";
import { Field, Formik } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import {
  useEditAssemblyMaterial,
  useGetAssemblyMaterialHistory,
} from "hooks/assembly-materials";
import * as Yup from "yup";

interface EditAssemblyMaterialsType {
  isOpen: boolean;
  onClose: () => void;
  material: any;
  suppliersLabel: { label: string; value: string }[] | undefined;
}

const EditAssemblyMaterials = ({
  isOpen,
  onClose,
  material,
  suppliersLabel,
}: EditAssemblyMaterialsType) => {
  const currentDate = new Date();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const tommorow = currentDate.setDate(currentDate.getDate() + 1);
  const { mutate: editAssemblyMaterial } = useEditAssemblyMaterial({
    onSuccess: () => {
      onClose();
    },
  });

  const { data } = useGetAssemblyMaterialHistory(material.uuid, isOpen);

  const materialCost =
    data &&
    data.costHistory.length > 0 &&
    data.costHistory.find((r: any) => r.toDate === "now").cost;

  const initialValues = {
    name: material.name,
    cost: materialCost,
    date: material.plannedCost,
    uuid: material.uuid,
    supplierUuid: material.supplier?.uuid,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(
        /^[^\s].*[^\\s]?$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
    cost: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .matches(
        /^[^\s].*[^\\s]?$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
    supplierUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    date: Yup.string()
      .nullable()
      .test(
        "required-if-not-materialCost",
        VALIDATION_MESSAGES.REQUIRED,
        function (value) {
          const { cost } = this.parent;
          const parsedCost = parseFloat(cost);
          const parsedMaterialCost = parseFloat(materialCost);

          if (parsedCost !== parsedMaterialCost) {
            return value !== null && value !== undefined;
          }
          return true;
        }
      ),
  });

  const handleOnSubmit = async (values: any) => {
    const isDifferentRate = material.cost !== values.cost;
    console.log(isDifferentRate, material.cost, values.cost);
    if (material.plannedCost) {
      if (isDifferentRate) {
        await editAssemblyMaterial({ ...values });
      } else {
        setIsAlertOpen(true);
      }
    } else {
      await editAssemblyMaterial({ ...values });
      setIsAlertOpen(false);
    }
  };

  return (
    <>
      <Modal
        side="right"
        isOpened={isOpen}
        onClose={() => {
          onClose();
          setIsAlertOpen(false);
        }}
      >
        <>
          <div className="modal-heading">Edycja materiału montażowego</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
          >
            {({
              handleSubmit,
              errors,
              values,
              setFieldError,
              setFieldTouched,
            }) => {
              const isDataAvailable = values.cost !== materialCost;
              return (
                <form className="add-user__form form" onSubmit={handleSubmit}>
                  <Field
                    errors={errors}
                    label="Nazwa materiału"
                    onChange={() => setIsAlertOpen(false)}
                    name="name"
                    component={Input}
                    maxLength={30}
                  />
                  <Field
                    errors={errors}
                    label="Koszt materiału"
                    name="cost"
                    rightPlaceholder={"zł/szt"}
                    component={RateInput}
                    maxLength={6}
                    onChange={() => {
                      setFieldError("date", "");
                      setFieldTouched("date", false);
                      setIsAlertOpen(false);
                    }}
                  />
                  <Field
                    errors={errors}
                    label="Dostawca"
                    menuPlacement="top"
                    name="supplierUuid"
                    component={Select}
                    options={suppliersLabel}
                  />
                  {(isDataAvailable || material.plannedCost) && (
                    <Field
                      errors={errors}
                      label="Obowiązująca od dnia"
                      name="date"
                      component={CalendarInput}
                      minDate={tommorow}
                    />
                  )}
                  <StaticAlert
                    show={isAlertOpen}
                    label="Nie można edytować stawki na tą samą wartość."
                  />
                  <div className="modal-buttons">
                    <Button
                      stroke
                      onClick={() => {
                        onClose();
                        setIsAlertOpen(false);
                      }}
                      label="Anuluj"
                    />
                    <Button onClick={() => handleSubmit()} label="Potwierdź" />
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default EditAssemblyMaterials;
