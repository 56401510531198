import "./order-status.scss";
import classNames from "classnames";
import { ORDER_ENUM } from "enums";

interface OrderStatusType {
  status?: string;
  className?: string;
  isVerified?: boolean;
}

const OrderStatus = ({ status, className = "" }: OrderStatusType) => {
  const {
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    MEASUREMENTS_DONE,
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
  } = ORDER_ENUM;

  const green =
    status === FIXED_MEASUREMENTS ||
    status === FIXED_INSTALLATION ||
    status === FIXED_VERIFICATION;
  const red =
    status === INSTALLATION_TO_BE_RELEASED ||
    status === MEASUREMENTS_TO_BE_RELEASED ||
    status === VERIFICATION_TO_BE_RELEASED;
  const gray = status === INSTALLATION_DONE || status === MEASUREMENTS_DONE;
  const orderStatusClass = classNames("order-status", className, {
    "order-status--green": green,
    "order-status--red": red,
    "order-status--gray": gray,
  });

  return (
    <div className={orderStatusClass}>
      <div className="order-status__label">
        <>
          {status === MEASUREMENTS_TO_BE_RELEASED && "Pomiar do ustalenia"}
          {status === FIXED_MEASUREMENTS && "Pomiar ustalony"}
          {status === MEASUREMENTS_DONE && "Pomiar zakończony"}
          {status === VERIFICATION_TO_BE_RELEASED && "Weryfikacja do ustalenia"}
          {status === FIXED_VERIFICATION && "Weryfikacja ustalona"}
          {status === INSTALLATION_TO_BE_RELEASED && "Montaż do ustalenia"}
          {status === FIXED_INSTALLATION && "Montaż ustalony"}
          {status === INSTALLATION_IN_PROGESS && "Montaż w trakcie"}
          {status === INSTALLATION_DONE && "Montaż zakończony"}
        </>
      </div>
    </div>
  );
};

export default OrderStatus;
