import { useState } from "react";
import { VALIDATION_MESSAGES } from "enums";
import { Button, Select, Switch, Textarea } from "components";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { ScrollToError } from "utils";
import {
  SingleSectionElement,
  RoomList,
  KitchenWindowConnectedCountertop,
  TypeOfInstalation,
} from "features/addingMeasurement";
import "./windows-replacement-generall-information.scss";
import { usePutWindowsReplacementClientInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ExtraWork } from "features/addingMeasurement/Form";

interface WindowsReplacementGeneralInformationType {
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
  measurementUuid: string | null;
}

const WindowsReplacementGeneralInformation = ({
  measurementInfo,
  measurementUuid,
  setStep,
  step,
  prevStep,
}: WindowsReplacementGeneralInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const initialMeasurementClientInfo = measurementInfo?.measurementClientInfo;
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: editGeneralInfo } = usePutWindowsReplacementClientInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "3");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
    onError: () => {
      console.error("Błąd podczas aktualizacji danych klienta.");
    },
  });

  const roomSchema = Yup.object().shape({
    uuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    measurement: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const validationSchema = Yup.object().shape({
    typeOfInstallationUuids: Yup.array()
      .of(
        Yup.mixed().test(
          "is-string-or-object",
          VALIDATION_MESSAGES.REQUIRED,
          (value) =>
            typeof value === "string" ||
            (typeof value === "object" &&
              value !== null &&
              !Array.isArray(value))
        )
      )
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "has-at-least-one-loose-string",
        VALIDATION_MESSAGES.REQUIRED,
        (array) => array.some((item) => typeof item === "string")
      ),
    typesOfOldWindows: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    spoutLevel: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    roomListUuids: Yup.array()
      .of(roomSchema)
      .min(1, "")
      .required(VALIDATION_MESSAGES.REQUIRED),
    kitchenWindowConnectedToTheCountertop: Yup.boolean()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "kitchenWindow-connected-test",
        VALIDATION_MESSAGES.REQUIRED,
        function (value) {
          const measurement =
            this.parent.kitchenWindowConnectedToTheCountertopMeasurement;
          if (value === true && !measurement) {
            return false;
          }
          return true;
        }
      ),
    kitchenWindowConnectedToTheCountertopMeasurement: Yup.string(),
  });

  const typeOfInstallationComments =
    initialMeasurementClientInfo?.typeOfInstallationUuids?.find(
      (item: any) => item.comment
    )?.comment;

  const initialValues = {
    measurementUuid: measurementUuid,
    typesOfOldWindows: initialMeasurementClientInfo?.typesOfOldWindows || "",
    roomListUuids: initialMeasurementClientInfo?.roomListUuids || [],
    oldWindowDisposal: initialMeasurementClientInfo?.oldWindowDisposal || false,
    spoutLevel: initialMeasurementClientInfo?.spoutLevel || "",
    typeOfInstallationUuids:
      initialMeasurementClientInfo?.typeOfInstallationUuids || [],
    typeOfInstallationComments: typeOfInstallationComments || "",

    kitchenWindowConnectedToTheCountertop:
      initialMeasurementClientInfo?.kitchenWindowConnectedToTheCountertop ||
      false,
    kitchenWindowConnectedToTheCountertopMeasurement:
      initialMeasurementClientInfo?.kitchenWindowConnectedToTheCountertopMeasurement ||
      "",
    oldWindowDismantlingInvestor:
      initialMeasurementClientInfo?.oldWindowDismantlingInvestor || false,
    floorFurnitureProtection:
      initialMeasurementClientInfo?.floorFurnitureProtection || "nobody",
    preparationOfOpeningsForTripleLayerInstallation:
      initialMeasurementClientInfo?.preparationOfOpeningsForTripleLayerInstallation ||
      "nobody",
    changeOfOpeningDimensions:
      initialMeasurementClientInfo?.changeOfOpeningDimensions || "nobody",
    constructionSiteCleanupInvestor:
      initialMeasurementClientInfo?.constructionSiteCleanupInvestor || false,
    additionWorkComment:
      initialMeasurementClientInfo?.additionWorkComment || "",
  };

  const handleOnSubmit = (values: any) => {
    editGeneralInfo(values);
  };

  const typesOfOldWindowsOptions = [
    {
      value: "plastic",
      label: "Plastikowe",
    },
    {
      value: "woodenSwedish",
      label: "Drewniane-szwedzkie",
    },
    {
      value: "woodenCrates",
      label: "Drewniane szkrynkowe",
    },
    {
      value: "steel",
      label: "Stalowe",
    },
  ];
  const typesOfSpoutLevelOptions = [
    {
      value: "real",
      label: "Rzeczywisty",
    },
    {
      value: "specifiedByInvestor",
      label: "Określony przez inwestora",
    },
    {
      value: "specifiedByContractor",
      label: "Określony przez wykonawcę",
    },
    {
      value: "steel",
      label: "Stalowe",
    },
  ];

  return (
    <div>
      <div className="am-heading">Informacje ogólne</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form wrgi" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                values={values}
                errors={errors}
                label="Nazwa materiału"
                name="typeOfInstallationUuids"
                component={TypeOfInstalation}
                type={measurementInfo?.typeOfMeasurement}
              />
              <SingleSectionElement
                touched={touched}
                name="typesOfOldWindows"
                errors={errors}
              >
                <Field
                  label="Typ starych okien"
                  name="typesOfOldWindows"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={typesOfOldWindowsOptions}
                  component={Select}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="oldWindowDisposal"
                errors={errors}
                className="oldWindowDisposal"
              >
                <Field
                  checked={values.oldWindowDisposal}
                  label="Utylizacja starych okien"
                  name="oldWindowDisposal"
                  type="number"
                  rightPlaceholder="mm"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="spoutLevel"
                errors={errors}
              >
                <Field
                  label="Poziom wylewki"
                  name="spoutLevel"
                  type="number"
                  options={typesOfSpoutLevelOptions}
                  component={Select}
                  white
                />
              </SingleSectionElement>

              <Field
                errors={errors}
                values={values}
                name="roomListUuids"
                component={RoomList}
              />
              <Field
                isChecked={values.kitchenWindowConnectedToTheCountertop}
                errors={errors}
                name="kitchenWindowConnectedToTheCountertop"
                component={KitchenWindowConnectedCountertop}
              />
              <Field values={values} component={ExtraWork} />
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="additionWorkComment"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button type="submit" label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WindowsReplacementGeneralInformation;
